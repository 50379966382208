import React, { useState } from "react"
import Select, { components } from "react-select"

const CollapsibleGroup = ({ label, options, ...props }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = e => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      <div
        onClick={e => handleToggleCollapse(e)}
        style={{ cursor: "pointer" }}
        className="ps-2 p-1"
      >
        {isCollapsed ? (
          <i className="bx bxs-plus-square"></i>
        ) : (
          <i className="bx bxs-minus-square"></i>
        )}
        {label}
      </div>

      {!isCollapsed && <components.Option {...props} {...options} />}
    </>
  )
}

const CustomOption = props => (
  <div>
    {/* Custom styling or content for options */}
    <components.Option {...props} />
  </div>
)

const MultiGroupSelection = props => {
  return (
    <Select
      components={{ Option: CustomOption, Group: CollapsibleGroup }}
      name={props.name}
      onChange={e => {
        // props.setFieldValue(
        //   props.name,
        //   e.map(item => item.value)
        // )
        // console.log(e.map(item => item.value))
        props.setFilteredValues({ ...props.filteredValues, [props.name]: e })
      }}
      options={props.options}
      isMulti={props.isMulti}
      placeholder={`${props.name} ...`}
      value={props.filteredValues[props.name]}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "transparent" : "transparent",
          borderColor: state.isSelected ? "transparent" : "transparent",
          borderWidth: "0px",
        }),
      }}
    />
  )
}

export default MultiGroupSelection

// const options* = [
//   {
//     label: 'Group 1',
//     options: [
//       { value: 'option1', label: 'Option 1' },
//       { value: 'option2', label: 'Option 2' },
//     ],
//   },
//   {
//     label: 'Group 2',
//     options: [
//       { value: 'option3', label: 'Option 3' },
//       { value: 'option4', label: 'Option 4' },
//     ],
//   },
// ];

// name = 'input Name'
// options = {options*}
// isMulti = true
// setFieldValue={setFieldValue} => from formik

{
  /* 
    <MultiDropSelection 
    name='input name'
    options={options*}
    isMulti={true}
    setFieldValue={setFieldValue}
    filteredValues={filteredValues}
    setFilteredValues={setFilteredValues}
    /> 
*/
}
