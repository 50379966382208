import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"

const AddtionalVisitFilter = ({ filteredValues, setFilteredValues }) => {
  // console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [users, setUsers] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }
  // console.log(
  //   "startDate",
  //   startDate && new Date(startDate).toISOString().split("T")[0]
  // )
  // console.log(
  //   "endDate",
  //   endDate && new Date(endDate).toISOString().split("T")[0]
  // )
  // console.log("dateRange", dateRange)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          history.push("/logout")
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Row>
        <Col>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    quotationNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    quotationNo: allItems,
                  })
                }
                type="number"
                name="quotationNo"
                placeholder="Quotation No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        <Col>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        <Col>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={users}
                name="user"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.users}
                onChange={e => {
                  setFilteredValues({ ...filteredValues, users: e })
                }}
                classNamePrefix="select"
                placeholder="Quoted By ..."
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Quotation Date ..."
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AddtionalVisitFilter
