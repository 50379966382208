import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import FilterPSadad from "./FilterPSadad"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import PaginationComponent from "common/PaginationComponent"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { usePrevious } from "common/CustomHooks"

function Sadad() {
  const history = useHistory()

  const [sadad, setSadad] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ********** Pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // **************** Filter Section ************************
  const initialFilteredValues = {
    quotationNo: [],
    referenceNo: [],
    sadadNo: [],
    merchant_reference: [],
    startDate: "",
    endDate: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  //******************************** View Quotation Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()
  const [selectedInstallment, setSelectedInstallment] = useState()

  const [viewQuotationModal, setViewQuotationModal] = useState(false)
  const viewQuotationToggle = () => {
    setViewQuotationModal(!viewQuotationModal)
  }
  const prevFilteredValues = usePrevious(filteredValues)
  const exportfunction = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: filteredValues.referenceNo.join(","),
      SadadNumber: filteredValues.sadadNo.join(","),
      Merchant_reference: filteredValues.merchant_reference.join(","),
      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }
    axios({
      method: 'post',
      url: 'https://test.rdapp.net/api/ACC/ExportSadad.php',
      data: formData,
      responseType: 'blob', // Important to handle the file download
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sadad_Report.xlsx'); // Provide the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    })
    .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      //   QuotationID: filteredValues.quotationNo.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      SadadNumber: filteredValues.sadadNo.join(","),
      //   Users: filteredValues.users.map(item => item.value).join(","),
      //   Payment: filteredValues.payment,
      Merchant_reference: filteredValues.merchant_reference.join(","),
      //   Merchant_Reference....1
      //   SadadNumber
      //   StartDate.......1
      //   EndDate.....1
      //   QuotationID.....1
      //    ReferenceNo......1

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }

    axios
      .post("https://test.rdapp.net/api/ACC/SadadList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =========>", res)
        setTotalPages(res.data.TotalPages)
        let array = Object.values(res.data)
        array.splice(-4)
        // console.log("array", array)
        setSadad(array)
      })
      .catch(err => console.log(err))
  }, [editing, page, filteredValues])

  console.log("sadad", sadad)
  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R13.P !== "1" &&
    userPermissions.R13.P !== "2" &&
    userPermissions.R13.P !== "3" &&
    userPermissions.R13.P !== "4" &&
    userPermissions.R13.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Sadad" breadcrumbItem="Sadad" />

            {/* ********************** Filter Section ***************************** */}
            <FilterPSadad
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />

            {/* ********************** Table Section ***************************** */}
            <Row>
              <Button
                className="bg-color-primary ms-auto col-1 me-3"
                onClick={exportfunction}
              >
                export
              </Button>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table header **************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Sadad No</th>
                          <th scope="col">Reference No</th>
                          <th scope="col">Merchant Reference</th>
                          <th scope="col">Sadad Transaction</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Transaction Date</th>
                          <th scope="col">Status</th>
                          {/* <th scope="col">Action</th> */}
                        </tr>
                      </thead>

                      {/***********table body *****************/}
                      <tbody>
                        {sadad.map((item, key) => (
                          <tr key={key} style={{ position: "relative" }}>
                            <td>
                              <input
                                // value={item.Code}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>
                            <td>{item.SadadNumber}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{item.Merchant_reference}</td>
                            <td>{item.Sadad_transaction_id}</td>
                            <td>{item.Amount}</td>
                            <td>{item.Transaction_date?.split(" ")[0]}</td>
                            <td>
                              <span
                                className={
                                  item.Status === "Accepted"
                                    ? "badge bg-success"
                                    : item.Status === "Partially"
                                    ? "badge bg-warning"
                                    : item.Status === "No"
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.Status === "Accepted"
                                  ? "Accepted"
                                  : item.Status === "Partially"
                                  ? "Partially Paid"
                                  : "Not Paid"}
                              </span>
                            </td>

                            {/* <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewQuotationToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  {(userPermissions.R13.P === "3" ||
                                    userPermissions.R13.P === "4") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        updateVisitsToggle()
                                      }}
                                    >
                                      Update Visits No
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            {/* ******************** Pagination *************************** */}
            {sadad.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Sadad
