import React, { useEffect, useState } from "react"
import { Label, Input, Col, FormGroup, label } from "reactstrap"

const QuestionHint = props => {
  const [Toggle, setToggle] = useState(true)
  const addToggleToggle = () => {
    setToggle(!Toggle)
  }
  return (
    <FormGroup className="d-flex  mb-0 mt-1" style={{ paddiing: "3px" }}>
      {props.label != false && (
        <Col sm={2}>
          <Label className=" mx-2  mt-1" for={props.name}>
            {props.name} :
          </Label>
        </Col>
      )}
      <FormGroup className="d-flex align-items-center">
      {/* <div className="col-5 me-2"> */}
        <Input
          className="form-control p-1"
          id={props.name}
          name={props.name}
          placeholder={`Write ${props.name} text here!`}
          value={props.props.values[props.name]}
          type="Text"
          disabled={Toggle}
          onChange={props.props.handleChange}
          onBlur={props.props.handleBlur}
        />
      {/* </div> */}
 
        <Label for={props.name} className=" col-5 ms-2">
          <Input
            onClick={() => {
              addToggleToggle()
            }}
            type="checkbox"
            id={props.name}
            className="me-1"
          />
          {props.props.values[props.name]
            ?  props.name
            :  props.name}
        </Label>
 
      </FormGroup>
    </FormGroup>
  )
}

export default QuestionHint
