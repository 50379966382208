import React, { useEffect, useState } from "react"
import { Button, Form, FormGroup, Col } from "reactstrap"
import AddARemove from "../Add several Tickets/AddARemove"
import { vacateChildNodes } from "common/FunctionsHelper"

const OtherChoice = props => {
  const [disabled, setDisabled] = useState(true)
  const [answerIds, setAnswerIds] = useState([1])
  let initalAnswers = []

  if (props.LengthOfotherCheckbox) {
    for (let i = 1; i < props.LengthOfotherCheckbox; i++) {
      initalAnswers.push(i)
    }
  }

 
  useEffect(() => {
    props.LengthOfotherCheckbox && setAnswerIds(initalAnswers)
    props.props.values[`${props.name}-${1}`] && setDisabled(false)
  }, [])

 
  return (
    <>
      <div className="mt-2">
        <input
          type="Checkbox"
          id="others"
          onClick={e => {
            setDisabled(!e.target.checked)

            if (e.target.checked !== true) {
              vacateChildNodes(e, "otherCheckbox" + props.QID)
              props.props.values[`${props.name}`] = ""
              answerIds.forEach(id => {
                props.props.values[`${props.name}-${id}`] = ""
              })
            }
          }}
          defaultChecked={props.props.values[`${props.name}-${1}`]}
        />
        <label htmlFor="others" className="ms-1">
          {props.label}
        </label>
      </div>
      {/* other checkbox text  */}
      <div id={"otherCheckbox" + props.QID}>
        {answerIds.map((id, key) => (
          <FormGroup key={key} className="d-flex">
            <input
              disabled={disabled}
              onChange={props.props.handleChange}
              type="text"
              id="other"
              name={`${props.name}-${id}`}
              style={{ height: "24px" }}
              defaultValue={props.props.values[`${props.name}-${id}`]}
              className="me-5"
            />

            <AddARemove
              answerIds={answerIds}
              setAnswerIds={setAnswerIds}
              id={id}
              name={[`${props.name}-${id}`]}
              props={props.props}
            />
          </FormGroup>
        ))}
      </div>
    </>
  )
}

export default OtherChoice
