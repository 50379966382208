import React, { useState } from "react"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

function PrivacyATerms(props) {
  // console.log("props", props)
  const history = useHistory()

  const [rejectModal, setRejectModal] = useState(false)
  const toggleReject = () => {
    setRejectModal(!rejectModal)
  }

  // const project = props.location.state?.project
  // const from = props.location.state?.from
  const to = props.location.state?.to
  const from = props.location.state?.from
  const data = props.location.state?.data
  const redirect = () => {
    from === "RD0" && history.push(`/`)
    from === "RD7" && history.push(`/rd7-reports`)
    from === "RD6" && history.push(`/rd6-reports`)
  }

  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  const username = localStorage.getItem("useName")

  const [agree, setAgree] = useState(false)
  const onsubmit = () => {
    agree === false ? toggleReject() : history.push(to, { data: data })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <h3>Impartiality Declaration Form</h3>
        <p className="ms-4 mt-4">
          I <b className="mx-2">{username}</b>, a technical engineer working at
          CPV ARABIA, hereby declare my commitment to maintaining impartiality
          and integrity in all aspects of my work as a technical engineer
          providing technical inspection services for project number{" "}
          <b className="mx-2">{data.ReferenceNo}</b> As an essential part of our
          quality management system, this declaration underscores our dedication
          to conducting technical inspection services with fairness,
          objectivity, and neutrality.
        </p>
        <ol type="a">
          <h5>Independence and Objectivity:</h5>
          <li className="ms-4">
            I will carry out technical inspection services without any undue
            influence or pressure from external parties, including the client or
            other stakeholders.
          </li>
          <li className="ms-4">
            I will not engage in any activities or relationships that could
            compromise my ability to make unbiased judgments and decisions.
          </li>
          <li className="ms-4">
            I will disclose any personal, financial, or professional interests
            that may present a conflict of interest or the appearance of a
            conflict.
          </li>
        </ol>
        <ol type="a">
          <h5>Fairness and Impartiality:</h5>
          <li className="ms-4">
            I will treat all clients and projects equally, regardless of their
            affiliations, size, or significance.
          </li>
          <li className="ms-4">
            I will base my inspection assessments solely on technical criteria
            and objective evidence, ensuring that all findings are accurate and
            unbiased.
          </li>
          <li className="ms-4">
            I will not Favor any specific party or entity during the inspection
            process and always maintain an objective stance
          </li>
        </ol>
        <ol type="a">
          <h5> Transparency and Communication:</h5>
          <li className="ms-4">
            I will communicate openly and honestly with all clients involved in
            the inspection process, ensuring clear and accurate information
            exchange.
          </li>
          <li className="ms-4">
            I will promptly report any potential conflicts of interest or
            concerns about impartiality to the appropriate parties within CPV
            Arabia.
          </li>
        </ol>
        <ol type="a">
          <h5> Compliance and Ethical Conduct:</h5>
          <li className="ms-4">
            I will comply with all applicable laws, regulations, and standards
            governing technical inspection services.
          </li>
          <li className="ms-4">
            I will conduct inspections with integrity and honesty, refraining
            from any behavior that could compromise the reputation and
            credibility of CPV Arabia.
          </li>
        </ol>
        <ol type="a">
          <h5> Confidentiality:</h5>
          <li className="ms-4">
            I will maintain strict confidentiality regarding all information
            obtained during the inspection process, ensuring that sensitive data
            is protected and not disclosed to unauthorized individuals.
          </li>
        </ol>
        <p className="ms-4">
          By signing this impartiality declaration form, I affirm my commitment
          to upholding the principles of impartiality, fairness, and integrity
          in all my endeavors as a technical engineer at CPV Arabia, I
          understand the significance of my role in providing reliable and
          unbiased technical inspection services to our clients, and I am
          dedicated to maintaining the trust and confidence placed in our
          organization.
        </p>
        <Label className="d-flex ms-5 mt-5">
          <Input
            type="checkbox"
            defaultValue={false}
            onClick={() => setAgree(!agree)}
            className="me-2  border-primary"
          />
          <Col sm={3}>
            I <u className="text-primary"> agreed </u>to this Impartiality
            Declaration Form
          </Col>
        </Label>
        <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
          <Button
            type="submit"
            className="bg-primary"
            // disabled={
            //   userPermissions.R3.P !== "2" &&
            //   userPermissions.R3.P !== "3" &&
            //   userPermissions.R3.P !== "4"
            // }
            onClick={onsubmit}
          >
            Submit
          </Button>
        </FormGroup>
      </div>

      {agree === false && (
        <Modal isOpen={rejectModal} toggle={toggleReject}>
          <ModalBody>
            <div className="modal-content">
              <ModalBody className="px-4 py-5 text-center">
                <button
                  type="button"
                  className="btn-close position-absolute end-0 top-0 m-3"
                  onClick={() => {
                    toggleReject()
                  }}
                ></button>
                <div className="avatar-sm mb-4 mx-auto">
                  <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                    <i className="mdi mdi-alert-outline font-size-20"></i>
                  </div>
                </div>
                <p className="text-muted font-size-16 mb-4">
                  You should <str className="text-primary"> Agree </str>
                  "Impartiality Declaration" first <br /> if you do not want
                  press "Reject"
                </p>

                <div className="hstack gap-2 justify-content-center mb-0">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      redirect()
                    }}
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => {
                      toggleReject()
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Close
                  </button>
                </div>
              </ModalBody>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default PrivacyATerms
