import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"

import CostsForm from "./CostsForm"
import UsersForm from "./UsersForm"
import DetailsForm from "./DetailsForm"

const EditProjectData = ({
  editToggle,
  project,
  editing,
  setEditing,
  setAuth,
}) => {
  const [modal, setModal] = useState(true)
  const toggle = () => {
    editToggle()
  }

  const onClose = () => {
    editToggle()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Update Project Data</ModalHeader>
        <ModalBody>
          <div>
            <Tabs id="justify-tab-example" className="mb-3" justify fill>
              <Tab eventKey="costs" title="Project Costs">
                <CostsForm
                  project={project}
                  onClose={onClose}
                  editing={editing}
                  setEditing={setEditing}
                  setAuth={setAuth}
                />
              </Tab>
              <Tab eventKey="project-users" title="Project Users">
                <UsersForm
                  project={project}
                  onClose={onClose}
                  editing={editing}
                  setEditing={setEditing}
                  setAuth={setAuth}
                />
              </Tab>
              <Tab eventKey="project" title="Project Details">
                <DetailsForm
                  project={project}
                  onClose={onClose}
                  editing={editing}
                  setEditing={setEditing}
                  setAuth={setAuth}
                />
              </Tab>
            </Tabs>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditProjectData
