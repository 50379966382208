import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Label, Input, Col, FormGroup } from "reactstrap"

const Icons = props => {
  const addAnswer = () => {
    let lastId = props.answerIds[props.answerIds.length - 1]
    let temp = lastId + 1
    props.setAnswerIds([...props.answerIds, temp])
  }

  const removeAnswer = itemId => {
    const index = props.answerIds.indexOf(itemId)
    let tempAnswerIds = [...props.answerIds]
    tempAnswerIds.splice(index, 1)
    props.setAnswerIds(tempAnswerIds)
  }
  const [disableDefault, setDisableDefault] = useState(false)
  useEffect(() => {
    if (props.props.values[`default${props.id}`] == true) {
      setDisableDefault(true)
    }
  }, [])

  return (
    <Col sm={4} className="d-flex mt-2  justify-content-around">
      {(props.inputType != "boolean") & (props.inputType != "text") ? (
        <FormGroup className="col-2 justify-content-between">
          <Link
            to="#"
            onClick={() => {
              // console.log(props.props.values)
              let lastId = props.answerIds[props.answerIds.length - 1]
              if (props.inputType != "Dropdown") {
                if (lastId < 10) {
                  addAnswer()
                }
              } else {
                addAnswer()
              }
              props.props.values[`Answer${lastId + 1}`] = ""
              props.props.values[`AnswerHint${lastId + 1}`] = ""
              props.props.values[`default${lastId + 1}`] = false
              // console.log(props.props.values)
              console.log("props.inputType", props.inputType)
            }}
            className="p-0"
          >
            <i className="mdi mdi-plus font-size-14" id="edittooltip" />
          </Link>

          <Link
            to="#"
            onClick={() => {
              if (props.answerIds.length > 1) {
                removeAnswer(props.id)
                delete props.props.values["Answer" + props.id]
                delete props.props.values["AnswerHint" + props.id]
                delete props.props.values["default" + props.id]
              }
            }}
            className="p-1 py-0"
          >
            <i className="mdi mdi-minus font-size-8" id="edittooltip" />
          </Link>
        </FormGroup>
      ) : (
        ""
      )}
      {props.default != false && (
        <FormGroup className="d-flex col-10 justify-content-between" check>
          {/* {(props.inputType == "dropdown") & (props.id == 1) ? (
            <FormGroup>
              <Input
                onChange={props.props.handleChange}
                onBlur={props.props.handleBlur}
                className="form-control"
                name={`Single`}
                id={`dropType`}
                type="select"
                // value={props.props.values[`dropType`] || false}
                defaultValue="1"
              >
                <option value={"1"} defaultChecked>
                  {"Single"}
                </option>
                <option value={"0"}>{"Multiple"}</option>
                <option value={"tickRD6"}>{"Tick (RD6)"}</option>
              </Input>
            </FormGroup>
          ) : (
            ""
          )} */}

          <FormGroup disabled={disableDefault}>
            <Label check>
              <Input
                onChange={props.props.handleChange}
                onBlur={props.props.handleBlur}
                className="form-control"
                name={`default${props.id}`}
                id={`default${props.id}`}
                type="checkbox"
                value={props.props.values[`default${props.id}`] || false}
                defaultChecked={props.props.values["default" + props.id]}
              />
              default
            </Label>
          </FormGroup>
        </FormGroup>
      )}
    </Col>
  )
}

export default Icons
