import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from "reactstrap"

import { useHistory } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"

import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import AddNewVisit from "./AddNewVisit"
import DeleteVisit from "./DeleteVisit"
import EditVisit from "./EditVisit"

const SiteVisits = props => {
  const ProjectID = props.match.params.ProjectID
  const history = useHistory()

  const [visits, setVisits] = useState([])
  // console.log("visits---------->", visits)
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********Add New Visit Toggle *********************
  const [newVisitModal, setNewVisitModal] = useState(false)
  const newVisitToggle = () => {
    setNewVisitModal(!newVisitModal)
  }

  // ********** Edit Visit Toggle *********************
  const [selectedItem, setSelectedItem] = useState()

  const [editVisitModal, setEditVisitModal] = useState(false)
  const editVisitToggle = () => {
    setEditVisitModal(!editVisitModal)
  }

  // ********** Delete Visit Toggle *********************
  const [deleteModal, setDeleteModal] = useState(false)
  const deleteVisitToggle = () => {
    setDeleteModal(!deleteModal)
  }

  // ********************** Fetching Data with filters ***************************
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }

    axios
      .post("https://test.rdapp.net/api/RD6/SiteVisit.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log("res", res)
          let array = Object.values(res.data)
          array.splice(-1)
          setVisits(array)
        }
      })
      .catch(err => console.log(err))
  }, [ProjectID, editing])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="RD6" breadcrumbItem="Site Visits" />

          {/* ********************** Add New Quotaion Section ***************************** */}
          <div className="d-flex justify-content-end mb-2">
            <button className="btn btn-primary" onClick={newVisitToggle}>
              Add New Visit
            </button>
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "20px" }}>
                          #
                        </th>
                        <th scope="col">Inspection Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    {/***********table body *****************/}
                    <tbody>
                      {visits.map((item, key) => (
                        <tr key={key} style={{ position: "relative" }}>
                          <td>
                            <input
                              value={item.Code}
                              type="checkbox"
                              onClick={e => {
                                // console.log("---------->", e.target.value)
                                // selectedTicketsHandler(e.target.value)
                              }}
                              className="form-check-input  p-1 border-primary"
                            />
                          </td>
                          <td>{item.InspectorName}</td>

                          <td>{item.Type}</td>

                          <td>{item.Date}</td>

                          <td>
                            <UncontrolledDropdown className="btn-group bg-primary">
                              <button
                                onClick={() => {
                                  setSelectedItem(item)
                                  editVisitToggle()
                                }}
                                className="btn btn-primary btn-sm dropdown-toggle"
                                disabled={item.Manual !== true}
                              >
                                Edit
                              </button>
                              <DropdownToggle
                                tag="a"
                                to="#"
                                className="card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  onClick={() => {
                                    setSelectedItem(item)
                                    deleteVisitToggle()
                                  }}
                                  disabled={item.Manual !== true}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <FormGroup className="d-flex mt-4 ms-auto col-3 justify-content-end">
              <Button
                className="bg-danger px-4 mx-4"
                onClick={() => {
                  history.push(`/rd-6/${ProjectID}`)
                }}
              >
                Next
              </Button>
            </FormGroup>
          </Row>

          {/* ******************** Add New Visit ***************************** */}
          {newVisitModal && (
            <AddNewVisit
              newVisitToggle={newVisitToggle}
              ProjectID={ProjectID}
              editing={editing}
              setEditing={setEditing}
              setAuth={setAuth}
            />
          )}

          {/* ******************** Edit Visit ***************************** */}
          {editVisitModal && (
            <EditVisit
              editVisitToggle={editVisitToggle}
              selectedItem={selectedItem}
              editing={editing}
              setEditing={setEditing}
              setAuth={setAuth}
            />
          )}

          {/* ******************** Delete Visit ***************************** */}
          {deleteModal && (
            <DeleteVisit
              deleteVisitToggle={deleteVisitToggle}
              selectedItem={selectedItem}
              editing={editing}
              setEditing={setEditing}
              setAuth={setAuth}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SiteVisits
