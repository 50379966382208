import React, { useState } from "react"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PackupAndSubmit from "./PackupAndSubmit"

const CSVProject = () => {
  //meta title
  document.title = "CSV Project | DomApp - React Admin & Dashboard Template"

  const [allProjectsOptions, setAllProjectsOptions] = useState({
    key: "",
    file: null,
    error: "",
  })
  const allProjectsChangeHandler = e => {
    if (e.target.files[0]) {
      setAllProjectsOptions({
        ...allProjectsOptions,
        file: e.target.files[0],
        error: "",
      })
    }
  }
  const allProjectsSubmitHandler = () => {
    if (allProjectsOptions.file) {
      console.log("values --------->", allProjectsOptions.file)
      let randomString = Math.random().toString(36)
      setAllProjectsOptions({
        ...allProjectsOptions,
        file: null,
        key: randomString,
        error: "",
      })
    } else {
      console.log("validation error")
      setAllProjectsOptions({
        ...allProjectsOptions,
        error: "* required",
      })
    }
    packupToggle()
  }

  //*************************************** */

  const [paidProjectsOptions, setPaidProjectsOptions] = useState({
    key: "",
    file: null,
    error: "",
  })

  const paidProjectsChangeHandler = e => {
    if (e.target.files[0]) {
      setPaidProjectsOptions({
        ...paidProjectsOptions,
        file: e.target.files[0],
        error: "",
      })
    }
  }
  const paidProjectsSubmitHandler = () => {
    if (paidProjectsOptions.file) {
      console.log("values --------->", paidProjectsOptions.file)
      let randomString = Math.random().toString(36)
      setPaidProjectsOptions({
        ...paidProjectsOptions,
        file: null,
        key: randomString,
        error: "",
      })
    } else {
      console.log("validation error")
      setPaidProjectsOptions({
        ...paidProjectsOptions,
        error: "* required",
      })
    }
  }

  // console.log("allProjectsOptions", allProjectsOptions)
  // console.log("paidProjectsOptions", paidProjectsOptions)

  // ***************** Packup and submit **********************
  const [packupModal, setPackupModal] = useState(false)

  const packupToggle = () => {
    setPackupModal(!packupModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="CSV Project" />

          <Card className="mb-5" color="warning" inverse>
            <CardHeader tag="h5">warning!!</CardHeader>
            <CardBody>
              <CardText>
                Important: Please make sure that you have saved a backup first.
                <br /> This action can NOT be undo!
              </CardText>
            </CardBody>
          </Card>

          <Card
            className="my-2"
            style={{
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <CardHeader
              style={{
                backgroundColor: "#636678",
                color: "#fff",
              }}
            >
              Upload CSV Projects
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <Card body>
                    <div className="mb-3">
                      <CardTitle tag="h5">
                        <Label htmlFor="allProjects">All Projects</Label>
                      </CardTitle>
                      <Input
                        name="allProjects"
                        type="file"
                        // accept=".csv"
                        onChange={e => allProjectsChangeHandler(e)}
                        key={allProjectsOptions.key || ""}
                        className="form-control"
                        id="allProjects"
                      />
                      <div className="d-flex justify-content-between">
                        <p>* Only .CSV file format.</p>
                        {allProjectsOptions.error && (
                          <p className="error">{allProjectsOptions.error}</p>
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={allProjectsSubmitHandler}
                    >
                      Upload All Projects
                    </button>
                  </Card>
                </Col>
                <Col md="6">
                  <Card body>
                    <div className="mb-3">
                      <CardTitle tag="h5">
                        <Label htmlFor="paidProjects">Paid Projects</Label>
                      </CardTitle>
                      <Input
                        name="paidProjects"
                        type="file"
                        // accept=".csv"
                        onChange={e => paidProjectsChangeHandler(e)}
                        key={paidProjectsOptions.key || ""}
                        className="form-control"
                        id="paidProjects"
                      />

                      <div className="d-flex justify-content-between">
                        <p>* Only .CSV file format.</p>
                        {paidProjectsOptions.error && (
                          <p className="error">{paidProjectsOptions.error}</p>
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={paidProjectsSubmitHandler}
                    >
                      Upload Paid Projects
                    </button>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        {packupModal && <PackupAndSubmit packupToggle={packupToggle} />}
      </div>
    </React.Fragment>
  )
}

export default CSVProject
