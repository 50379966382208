import React, { useState } from "react"
import MultiSelectInspection from "./MultiSelectInspection"
import CheckboxADropdown from "./CheckboxADropdown"
import { Col } from "reactstrap"

function AddingNewTicket(props) {
  const [degreevalue, setDegreeValue] = useState()
  return (
    <div className={props.className}>
      <Col sm={4}>
        <MultiSelectInspection
          isMulti={true}
          props={props.props}
          data={props.degreeList}
          name={"degree1"}
          title={"Degree"}
          className={"me-2 "}
          setValue={setDegreeValue}
          id={""}
        />
        {props.props.errors["degree" + 1] ? (
          <div className="error mb-2" >
            {props.props.errors["degree" + 1]}
          </div>
        ) : null}
      </Col>
      <Col sm={4}>
        <MultiSelectInspection
          isMulti={true}
          props={props.props}
          data={props.transferList}
          name={"transferTo1"}
          title={"Transfer-To"}
          className={"me-2 "}
          setValue={setDegreeValue}
          id={""}
        />
        {props.props.errors["transferTo" + 1] ? (
          <div className="error mb-2" >
            {props.props.errors["transferTo" + 1]}
          </div>
        ) : null}
      </Col>
      <Col sm={4}>
        <CheckboxADropdown
          props={props.props}
          name={"Imagerequired-1"}
          title={"Image-required"}
          id={"1"}
        />
      </Col>
    </div>
  )
}

export default AddingNewTicket
