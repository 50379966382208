import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import Select from "react-select"
import { useHistory } from "react-router-dom"

const DeleteDepartment = ({
  deleteDepartmentToggle,
  department,
  editing,
  setEditing,
  setAuth,
}) => {
  //   console.log("department", department)
  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    deleteDepartmentToggle()
  }
  const onClose = () => {
    deleteDepartmentToggle()
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: "Delete",
      OCID: department.OCID,
    }

    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/OrgChartAction.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Delete Department
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>

      <ModalBody className="px-4 py-5 text-center">
        <Form onSubmit={submitHandler}>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to permanently delete this department ?
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-danger">
              Delete Now
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default DeleteDepartment
