import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"

const FilterProjectCosts = ({ filteredValues, setFilteredValues, setAuth }) => {
  // console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }
  // console.log(
  //   "startDate",
  //   startDate && new Date(startDate).toISOString().split("T")[0]
  // )
  // console.log(
  //   "endDate",
  //   endDate && new Date(endDate).toISOString().split("T")[0]
  // )
  // console.log("dateRange", dateRange)

  const [regionList, setRegionList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post(
        "https://test.rdapp.net/api/CostEstimation/RegionList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setRegionList(
          array.map(item => ({ value: item.RCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://test.rdapp.net/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setCategoryList(
          array.map(item => ({ value: item.BCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // ************************ Cost Range Error ******************************
  const errors = {
    floorsAreaFrom: "",
    floorsAreaTo: "",

    estProjectCostFrom: "",
    estProjectCostTo: "",

    IDICostFrom: "",
    IDICostTo: "",

    estCostRatioFrom: "",
    estCostRatioTo: "",
  }

  const checkingErrors = (from, to) => {
    // negative value
    if (filteredValues[from] < 0)
      errors[from] = "must be greater than or equal to 0"
    if (filteredValues[to] < 0)
      errors[to] = "must be greater than or equal to 0"

    // only one value is available
    if (filteredValues[from] && !filteredValues[to]) errors[to] = "required"
    if (filteredValues[to] && !filteredValues[from]) errors[from] = "required"

    // the to value is smaller
    if (
      filteredValues[from] &&
      filteredValues[to] &&
      filteredValues[from] * 1 > filteredValues[to] * 1
    ) {
      errors[from] = "must be smaller"
      errors[to] = "must be greater"
    }
  }

  checkingErrors("floorsAreaFrom", "floorsAreaTo")
  checkingErrors("estProjectCostFrom", "estProjectCostTo")
  checkingErrors("IDICostFrom", "IDICostTo")
  checkingErrors("estCostRatioFrom", "estCostRatioTo")

  return (
    <>
      <Row className="mb-3">
        {/* ******************** Category *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={categoryList}
                name="category"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.users}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, category: e })
                }}
                classNamePrefix="select"
                placeholder="Category ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Reference No *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** Project Added Date *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable={true}
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Project Added Date ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Region *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={regionList}
                name="region"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.users}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, region: e })
                }}
                classNamePrefix="select"
                placeholder="Region ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Total Floors Area *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="floorsAreaFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        floorsAreaFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Floors Area From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.floorsAreaFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.floorsAreaFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="floorsAreaTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        floorsAreaTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Floors Area To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.floorsAreaTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.floorsAreaTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* ******************** Estimated Project Cost *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="estProjectCostFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        estProjectCostFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Est Project Cost From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.estProjectCostFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.estProjectCostFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="estProjectCostTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        estProjectCostTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Est Project Cost To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.estProjectCostTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.estProjectCostTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* ******************** IDI Cost *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="IDICostFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        IDICostFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="IDI Cost From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.IDICostFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.IDICostFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="IDICostTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        IDICostTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="IDI Cost To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.IDICostTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.IDICostTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* ******************** Estimated Cost Ratio *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="estCostRatioFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        estCostRatioFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Est Cost Ratio From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.estCostRatioFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.estCostRatioFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="estCostRatioTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        estCostRatioTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Est Cost Ratio To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.estCostRatioTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.estCostRatioTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default FilterProjectCosts
