import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const AddNewRD1Report = ({
  newReportToggle,
  editing,
  setEditing,
  viewToggle,
  setSelectedItem,
  selectedItem,
}) => {

let [projectID,setProjectID]=useState()

  const [modal, setModal] = useState(true)
  const toggle = () => {
    newReportToggle()
  }

  const onClose = () => {
    newReportToggle()
  }

  const history = useHistory()

  const [referenceNo, setReferenceNo] = useState("")
  const [referenceNoError, setReferenceNoError] = useState(
    "valid reference no. required!"
  )
  const [pStageID, setPStageID] = useState()
  // console.log("pStageID", pStageID)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Type: "RD1",
      ReferenceNo: referenceNo,
    }
    axios
      .post(
        "https://test.rdapp.net/api/Reports/Search_Report.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }


        if (res.data.error === true) {
          setReferenceNoError("valid reference no. required!")
        }

        if (res.data.error === false) {
          setProjectID(res.data.ProjectID)
          let array = Object.values(res.data)
          setReferenceNoError("")
          res.data.PStageID ? 
          (setPStageID(res.data.PStageID),
          array.splice(-2),
   
          setSelectedItem(array[0]))
          :
         ( setSelectedItem(res.data))
        }
      })
      .catch(err => console.log(err))
  }, [referenceNo])
console.log("selecdsdfd",selectedItem);


  return (
    <div>
      <Modal className="modal-md" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          New RD1 Report
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Refrence No</Label>
                <Input
                  type="number"
                  name="ReferenceNo"
                  value={referenceNo}
                  onChange={e => {
                    setReferenceNo(e.target.value)
                  }}
                />

                <p className="error px-1 w-100">{referenceNoError}</p>
              </FormGroup>
            </Col>

            <Formik
              initialValues={{}}
              // enableReinitialize
              validateOnBlur
              validateOnChange
              //   validate={values => {
              //     let errors = {}
              //     console.log("values ========>", values)

              //     console.log("errors", errors)
              //     return errors
              //   }}
              //   onSubmit={submitHandler}
            >
              {props =>
                !referenceNoError && (
                  <Form onSubmit={props.handleSubmit}>
                    <FormGroup className="d-flex ms-auto mt-5 justify-content-between">
                      <Button
                        onClick={() => {
                          onClose()
                        }}
                        className="bg-primary"
                      >
                        close
                      </Button>

                      {pStageID && (
                        <Button
                          className="bg-primary"
                          onClick={() => {
                            newReportToggle()
                            viewToggle()
                          }}
                        >
                          View
                        </Button>
                      )}

                      <Button
                        type="submit"
                        className="bg-primary"
                        onClick={() =>
                          history.push(`/rd1-reasons/${projectID}`)
                        }
                      >
                        {pStageID ? "Create Report Copy" : "Create New Report"}
                      </Button>
                    </FormGroup>
                  </Form>
                )
              }
            </Formik>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddNewRD1Report
