
const digest = '134b518ff70f4774f0f133bf29dbf64e1a4c9f0ab138794fcf8280549479874f';
const css = `._inputItem_18w4i_1 {
	padding: 5px;
	background: #ccc;
	margin-right: 5px;
}
._deleteButton_18w4i_11 {
	cursor: pointer;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"inputItem":"_inputItem_18w4i_1","deleteButton":"_deleteButton_18w4i_11"};
export { css, digest };
  