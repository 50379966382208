import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import { useHistory } from "react-router-dom"

const RD5ManagerApproval = ({

  RD5ManagerApprovalToggle,
  ticketControlled,
  editing,
  setEditing,
  setAuth,sendRD5Toggle
}) => {
  // console.log("ticketControlled", ticketControlled)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    RD5ManagerApprovalToggle()
  }
  const onClose = () => {
    RD5ManagerApprovalToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const [degreeList, setDegreeList] = useState([])
  // console.log("degreeList", degreeList)
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Category: "Design",
    }

    axios
      .post(
        "https://test.rdapp.net/api/inspection/DegreeList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setDegreeList(
          array
            .map(item => ({ value: item.DegreeID, label: item.Name }))
            .filter(item => item.value * 1 > 1)
        )
      })
      .catch(err => console.log(err))
  }, [])

  // *********************** Validate Handler ******************************** //
  const validateHandler = values => {
    let errors = {}

    if (!values.approve) errors.approve = "required"

    if (values.approve === "yes" && !values.description)
      errors.description = "required"

    console.log("values", values)
    console.log("errors", errors)

    return errors
  }

  // *********************** Submit Handler ******************************** //
  const submitHandler = values => {

    if (values.approve === "yes") {
      console.log("values ==========>", values)
      RD5ManagerApprovalToggle()
      sendRD5Toggle()
    }else{
    const formData = new FormData()
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("UpdaterID", localStorage.getItem("id"))
    formData.append("TicketID", ticketControlled.TicketID)
    formData.append("ApprovalType", "Manager")

    if (values.approve === "yes") {
      formData.append("Approval", true)
      formData.append("TicketDesc", values.description)
    } else if (values.approve === "no") {
      formData.append("Approval", false)
      formData.append("DegreeID", values.degree)
    }

    for (var pair of formData.entries()) {
      console.log(pair)
    }

    axios
      .post("https://test.rdapp.net/api/UpdateRD5Ticket", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(error => {
        console.log(error)
      })
    onClose()}
  }
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        RD5 Manager Approval
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 pt-4 pb-2 ">
          <Formik
            enableReinitialize={true}
            initialValues={{
              description: ticketControlled.Description || "",
            }}
            validate={validateHandler}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <FormGroup>
                  <Label htmlFor="approve">Approve RD5</Label>

                  <Input
                    type="select"
                    name="approve"
                    id="approve"
                    onChange={props.handleChange}
                  >
                    <option value="">Approve ... ?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                  {props.errors.approve ? (
                    <p className="error mx-2 d-flex justify-content-end">
                      {props.errors.approve}
                    </p>
                  ) : null}
                </FormGroup>

                {props.values.approve === "yes" && (
                  <FormGroup>
                    <Label htmlFor="description">RD5 Description</Label>
                    <Input
                      type="text"
                      name="description"
                      id="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                    />
                    {props.errors.description ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.description}
                      </p>
                    ) : null}
                  </FormGroup>
                )}

                {props.values.approve === "no" && (
                  <FormGroup>
                    <Label htmlFor="description">Degree ID</Label>
                    <Input
                      type="select"
                      name="degree"
                      id="degree"
                      onChange={props.handleChange}
                    >
                      <option value="">Select Degree ...</option>
                      {degreeList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                )}

                <FormGroup className="d-flex ms-auto mt-5 col-5 justify-content-around">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="bg-primary"
                    disabled={userPermissions.R3.G === "0"}
                  >
                   {props.values.approve==="yes"?"Send RD5" : "Submit"}
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default RD5ManagerApproval
