import React from "react"
import Select from "react-select"
import { Col, Input, Row, Label } from "reactstrap"

function UnitsList({ props, groupedOptions }) {
  let groups = []
  groupedOptions.forEach(option => groups.push({label: option.label,value:option.label}))

  return (
    <Row className="ms-5">
      <Col style={{ maxWidth: "150px" }} className="mt-2 d-flex">
        <Label>
          Can Add More :
          <Input
            type="checkbox"
            className="ms-2"
            name={`CanAddMoreUnits`}
            value={props.values["CanAddMoreUnits"]}
            onChange={props.handleChange}
          />
        </Label>
      </Col>
      <Col sm={3}>
        <Label className="d-flex align-items-center">
          <Col>Max Number :</Col>
          <Col sm={4}>
            <Input
              className="text-center "
              type="number"
              id="MaxNumberUnits"
              name={`MaxNumberUnits`}
              value={props.values["MaxNumberUnits"]}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              onWheel={e => e.target.blur()}
              disabled={!props.values["CanAddMoreUnits"]}
            />
          </Col>
        </Label>
      </Col>
      <Col sm={3} className="d-flex">
        <Label className="mt-2 me-2">Units :</Label>
        <Select
          name={"otherUnits"}
          onChange={e => {
            props.setFieldValue(
              "otherUnits",
              e ? e.map(item => item.value) : []
            )
          }}
          options={groups}
          isMulti
          isDisabled={!props.values["CanAddMoreUnits"]}
        />
      </Col>
    </Row>
  )
}

export default UnitsList
