import * as Yup from "yup"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import Select from "react-select"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const AddUser = ({ addUserToggle, editing, setEditing, setAuth }) => {
  const history = useHistory()

  const [modal, setModal] = useState(true)
  const toggle = () => {
    addUserToggle()
  }

  const onClose = () => {
    addUserToggle()
  }

  const AddUserSchema = Yup.object().shape({
    FirstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("this field is required"),
    LastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("this field is required"),
    Mobile: Yup.string().required("this field is required"),
    Email: Yup.string()
      .email("Invalid email")
      .required("this field is required"),
    Password: Yup.string().required("this field is required"),
    Signature: Yup.string().required("this field is required"),
    Role: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    Department: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
  })

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Mobile: "",
      Email: "",
      Password: "",
      Signature: "",
    },
    validationSchema: AddUserSchema,
    onSubmit: values => {
      // console.log("submitted values", Object.entries(values))

      const formData = new FormData()

      formData.append("PerToken", localStorage.getItem("token"))
      formData.append("PerUserID", localStorage.getItem("id"))
      formData.append("PerRoleID", localStorage.getItem("userRoleID"))
      formData.append("UserID", localStorage.getItem("id"))
      formData.append("FirstName", values.FirstName)
      formData.append("LastName", values.LastName)
      formData.append("Mobile", values.Mobile)
      formData.append("Email", values.Email)
      formData.append("Password", values.Password)
      formData.append("RoleID", values.Role.value)
      formData.append("ChartID", values.Department.value)
      formData.append("Signature", values.Signature)

      // for (var pair of formData.entries()) {
      //   console.log(pair[0], pair[1])
      // }

      axios
        .post("https://test.rdapp.net/api/AddUser", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          setIsSuccessul(true)
          setEditing(!editing)
        })
        .catch(error => {
          console.log(error)
        })

      onClose()
    },
  })

  const [isSuccessul, setIsSuccessul] = useState(false)
  const [currentImg, setCurrentImg] = useState(null)

  useEffect(() => {
    // const imgUrl = URL.createObjectURL(Signature);
    if (Signature) {
      setCurrentImg(URL.createObjectURL(Signature))
    } else {
      setCurrentImg(null)
    }
  }, [Signature])

  const [rolesOptions, setRolesOptions] = useState([])
  useEffect(() => {
    axios
      .post("https://test.rdapp.net/api/Roles/View", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        ShowAll: true,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res========>", res.data)
        let array = Object.entries(res.data)
        array.splice(-3)
        console.log("array", array)

        setRolesOptions(
          array.map((item, i) => ({
            value: item[1].RoleID,
            label: item[1].RoleName,
          }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  const [departmentOptions, setdepartmentOptions] = useState([])

  useEffect(() => {
    axios
      .post("https://test.rdapp.net/api/OrgChartList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res========>", res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)

        setdepartmentOptions(
          array.map((item, i) => ({
            value: item.OCID,
            label: item.Name,
          }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          New User
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/****************Left colum********************/}
              <div className="col-6">
                <div className="mt-4 mt-lg-0">
                  <div className="form-group mb-4">
                    <label htmlFor="firstName">first Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      id="FirstName"
                      value={values.FirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="form-text text-danger">
                      {touched.FirstName && errors?.FirstName}
                    </p>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="email">Email</label>
                    <Input
                      onBlur={handleBlur}
                      className="form-control"
                      type="text"
                      id="Email"
                      value={values.Email}
                      onChange={handleChange}
                    />
                    <p className="form-text text-danger">
                      {touched.Email && errors?.Email}
                    </p>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="password">Password</label>
                    <Input
                      className="form-control"
                      type="password"
                      id="Password"
                      value={values.Password}
                      onChange={handleChange}
                    />
                    <p className="form-text text-danger">
                      {touched.Password && errors?.Password}
                    </p>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="user-role">Department</label>

                    <Select
                      options={departmentOptions}
                      id="user-department"
                      name="Department"
                      className="react-select-container"
                      value={values["Department"]}
                      onChange={e => {
                        console.log(e)
                        setFieldValue("Department", e)
                      }}
                      classNamePrefix="select"
                      placeholder="select user role ..."
                    />
                    <p className="form-text text-danger">
                      {errors.Department && errors?.Department.value}
                    </p>
                  </div>

                  <div className="form-group mt-5">
                    <h6 className="d-block" htmlFor="Signature">
                      User Signature
                    </h6>
                    <div className="d-flex ">
                      <div style={{ width: "500px" }}>
                        {/* handle file upload */}
                        <div className="d-flex">
                          <input
                            onChange={e => {
                              setFieldValue("Signature", e.target.files[0])
                            }}
                            type="file"
                            id="Signature"
                            // name="signature"
                            accept="image/png, image/jpeg"
                          />
                          <div
                            className="d-flex"
                            style={{ position: "relative" }}
                          >
                            {currentImg && (
                              <img src={currentImg} width={100} height={100} />
                            )}
                          </div>
                        </div>

                        <p className="form-text text-danger">
                          {touched.Signature && errors?.Signature}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/****************Right colum********************/}
              <div className="col-6">
                <div className="mt-4 mt-lg-0">
                  <div className="form-group mb-4">
                    <label htmlFor="lastName">Last Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      id="LastName"
                      value={values.LastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="form-text text-danger">
                      {touched.LastName && errors?.LastName}
                    </p>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="mobile">Mobile</label>
                    <Input
                      className="form-control"
                      type="tel"
                      id="Mobile"
                      value={values.Mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <p className="form-text text-danger">
                      {touched.Mobile && errors?.Mobile}
                    </p>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="user-role">Role</label>

                    <Select
                      options={rolesOptions}
                      id="user-role"
                      name="Role"
                      className="react-select-container"
                      value={values["Role"]}
                      onChange={e => {
                        console.log(e)
                        setFieldValue("Role", e)
                      }}
                      classNamePrefix="select"
                      placeholder="select user role ..."
                    />
                    <p className="form-text text-danger">
                      {errors.Role && errors?.Role.value}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                close
              </Button>
              <Button
                className="bg-primary"
                type="submit"
                disabled={
                  userPermissions.R9.P !== "2" &&
                  userPermissions.R9.P !== "3" &&
                  userPermissions.R9.P !== "4"
                }
              >
                Add
              </Button>
            </FormGroup>
          </form>

          {isSuccessul ? (
            <div
              style={{ marginTop: "5px" }}
              className="alert alert-success"
              role="alert"
            >
              User created successfully
            </div>
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddUser
