import axios from "axios"
import React, { useState, useEffect } from "react"
import { Container, Col, Row, Button, FormGroup } from "reactstrap"
import FormUpload from "./FormUpload"

function ImagePicker(props) {
  // add
  const [otherImages, setOtherImages] = useState([])
  const [dropzone, setDropzone] = useState(false)
  const toggleDropzone = () => {
    setDropzone(true)
  }
  // end of add
  const onClose = () => {
    props.toggleViewImages()
    setDeleteImages([...allImages])
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ...deletedImagesobject,
      StageID: "11",
    }
    axios
      .post("https://test.rdapp.net/api/DeleteFile", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          props.setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const [deletedImages, setDeleteImages] = useState([])
  const [allImages, setAllImages] = useState([...props.pdfImages])

  useEffect(() => {
    setAllImages([...otherImages, ...allImages])
  }, [otherImages])

  const handleClick = async source => {
    const check = allImages.includes(source)
    if (check) {
      setDeleteImages([...deletedImages, source])
      const id = allImages.indexOf(source)
      let newArr = allImages
      newArr.splice(id, 1)
      setAllImages([...newArr])
    } else {
      allImages.push(source)
      setAllImages([...allImages])
    }
  }
  let deletedImagesobject = { FileName: deletedImages, Target: props.target }
  const onsubmit = () => {
    props.props.setFieldValue(props.value, allImages)
    onClose()
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ...deletedImagesobject,
      StageID: "11",
    }
    axios
      .post("https://test.rdapp.net/api/DeleteFile", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          props.setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <Container>
      <Row className="mt-3 d-flex justify-content-around">
        {props.pdfImages.map((data, index) => (
          <Col ms={5} key={index} className="d-flex justify-content-around">
            <div
              className={`img-card mx-2 ${
                allImages.includes(data)
                  ? "border border-2 border-primary mt-2 shadow-lg p-1 mb-1 bg-body-blue rounded"
                  : null
              } `}
              onClick={() => handleClick(data)}
            >
              <img
                style={{ width: "200px", height: "200px" }}
               alt="Thumbnail"
                src={data}
              />
              {allImages.includes(data) ? (
                <div className="img-checked">
                  <i
                    className="bx bxs-check-circle bx-sm "
                    style={{ color: "blue" }}
                  ></i>
                </div>
              ) : null}
            </div>
          </Col>
        ))}
      </Row>
      <FormGroup className="d-flex ms-auto col-6 mt-5 justify-content-around">
        <Button
          onClick={() => {
            onClose()
          }}
          className="bg-primary me-2"
        >
          close
        </Button>

        <Button
          type="button"
          onClick={() => {
            onsubmit()
          }}
          className=" me-2 bg-primary"
        >
          Submit
        </Button>

        <Button onClick={toggleDropzone}>add</Button>
      </FormGroup>
      {dropzone && (
        <FormUpload
          otherImages={otherImages}
          setOtherImages={setOtherImages}
          setAuth={props.setAuth}
        />
      )}
    </Container>
  )
}

export default ImagePicker
