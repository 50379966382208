import React from "react"
import { Label, Input, Col, FormGroup, Row } from "reactstrap"
import CheckboxATextGroup from "./CheckboxATextGroup"
import RadioOrCheckboxGroup from "../../Inspection/InspectionBuilder/RadioOrCheckboxGroup"

function QuestionTitleAOtherInputs({props,inputType}) {
  return (
    <>
      <FormGroup className="d-flex row mt-3 align-items-center">
        <Label for="Question" sm={2}>
          Question Title :
        </Label>
        <Col className="d-flex" sm={9}>
          <Input
            id="Question"
            name="QuestionTitle"
            className="me-3 align-items-center"
            placeholder="Enter your question ?"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            onClick={() => {
              if (inputType == "boolean") {
                props.values["Answer1"] = "Yes"
                props.values["Answer2"] = "No"
              }
            }}
            value={props.values.QuestionTitle || ""}
            type="textarea"
          />
          {/* active & Optional */}
          <Row className="ms-1 align-items-center">
            <Col sm={6}>
              <RadioOrCheckboxGroup
                props={props}
                type={"checkbox"}
                name={"questionActive"}
                title={"Active"}
                id={"questionActive"}
                style={"col-2"}
                value={props.values["questionActive"]}
                defaultChecked={props.values["questionActive"]}
              />
            </Col>
            <Col sm={6}>
              <RadioOrCheckboxGroup
                props={props}
                type={"checkbox"}
                name={"questionOptional"}
                title={"Optional"}
                id={"questionOptional"}
                style={"col-2"}
                value={props.values["questionOptional"]}
                defaultChecked={props.values["questionOptional"]}
              />
            </Col>
          </Row>
        </Col>

        {props.touched.QuestionTitle && props.errors.QuestionTitle ? (
          <div className="error" style={{ marginInlineStart: "130px" }}>
            {props.errors.QuestionTitle}
          </div>
        ) : null}
      </FormGroup>

      {/* Question Hint ! */}
      <CheckboxATextGroup props={props} name={"Hint"} />
      {/* Question Help ! */}
      <CheckboxATextGroup props={props} name={"Help"} />
      {/* Missing Data */}
      <CheckboxATextGroup props={props} name={"MissingData"} />
    </>
  )
}

export default QuestionTitleAOtherInputs
