import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { Formik } from "formik"
import axios from "axios"
import { Link } from "react-router-dom"

import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ProjectHeader from "pages/RD-0/ProjectHeader"
import { object } from "prop-types"

const Rd1Reasons = props => {
  // // const project = props.location.state?.project
  // // console.log("project", project)

  const [lastReasons, setLastReasons] = useState([])

  let history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)



  const id = localStorage.getItem("id")

  const [reasons, setReasons] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: "13",
      ProjectID: props.match.params.ProjectID
    }

    axios
      .post("https://test.rdapp.net/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setReasons(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/Reports/LastSections.php", {
        ...formData,
        ProjectID: props.match.params.ProjectID,
        Type: "RD1",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // setReasons(array)
        res.data.length >= 1 && setLastReasons(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
 


  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content mt-5 mx-5">
        <ProjectHeader projectID={props.match.params.ProjectID} />
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false)
            let answer = Object.values(values).flat()
            lastReasons.length > 1 &&
              lastReasons.forEach(
                reason => !answer.includes(reason) && answer.push(reason)
              )

              reasons.forEach((reasoncheck)=>reasoncheck.SectionID==="134"&&answer.push("134")||reasoncheck.SectionID==="119"&&answer.push("119"))
              reasons.includes("134")&&answer.push("134")

            history.push(`/rd-1/${props.match.params.ProjectID}`, {
              selectedSections: answer,
            })
          }}
          validate={values => {
            const errors = {}
            // console.log("values",Object.entries(values).length <1);
            let answer = Object.values(values).flat()
            lastReasons &&
              lastReasons.forEach(
                reason => !answer.includes(reason) && answer.push(reason)
              )
            if (answer.length < 1) {
              errors.submited = "choose one reason at least"
            }
            return errors
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <div className="d-flex mt-5">
                <h5 className="mb-4 me-5">select reason for RD1 Report :</h5>
              </div>
              <div className="ms-5">
                {reasons.map((reason, key) => (
                  <FormGroup key={key}>
                    <label htmlFor={reason.SectionName}>
                      <input
                        className="me-1"
                        onChange={props.handleChange}
                        type="checkbox"
                        id={reason.SectionName}
                        name={reason.SectionName}
                        value={reason.SectionID}
                        defaultChecked={
                          lastReasons && (lastReasons.includes(reason.SectionID)) ||
                          reason.SectionID === "119" ||
                          reason.SectionID === "134"
                        }
                        disabled={
                          reason.SectionID === "119" ||
                          reason.SectionID === "134"
                        }
                      />
                      {reason.SectionName}
                    </label>
                  </FormGroup>
                ))}
              </div>

              {props.errors.submited ? (
                <p className="d-flex error mx-2 ms-5 ">
                  {props.errors.submited}
                </p>
              ) : null}
              <FormGroup className=" ms-auto col-3 mt-5 ">
                <Button
                  type="submit"
                  className="bg-primary ms-5"
                  disabled={
                    userPermissions.R3.P !== "2" &&
                    userPermissions.R3.P !== "3" &&
                    userPermissions.R3.P !== "4"
                  }
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default Rd1Reasons
