import { convertPermission } from "permissionUtils"
import React, { useState } from "react"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
const DeleteProject = ({ toggledPopup, rowData, setAuth }) => {
  const [Dmodal, setDmodal] = useState(true)
  const Dtoggle = () => {
    setDmodal(!Dmodal)
    toggledPopup()
  }

  const hideRow = () => {
    rowData.hide = true
    Dtoggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    /**************delete pop up***************/
    <Modal isOpen={Dmodal} toggle={Dtoggle}>
      <ModalHeader>
        Delete Project
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={Dtoggle}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <Form onSubmit={hideRow}>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to Delete this section?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={Dtoggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={userPermissions.R7.P !== "4"}
              >
                Delete Now
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DeleteProject
