import React from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"

function FromTo({ title, handleChange, handleBlur, errors, values }) {
  return (
    <>
      <div className="d-flex col-12 ">
        <Label for="Answer" sm={2}>
          {title} :
        </Label>
        <div className="col-6 d-flex mt-1">
          <Label for="from" className="me-1 d-flex col-5  align-items-center">
            <Col sm={5}>from</Col>
            <Input
              id={`from` + title}
              name={`from` + title}
              type={"number"}
              onWheel={e => e.target.blur()}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control p-1 ms-2"
              defaultValue={values["from" + title]}
            />
          </Label>
          <Label for="to" className="ms-2 d-flex col-4 align-items-center">
            <Col sm={3}>to</Col>
            <Input
              id="to"
              name={`to` + title}
              value={values["to" + title]}
              onChange={handleChange}
              onBlur={handleBlur}
              className="p-1 form-control "
              type={"number"}
              defaultValue={values["to" + title]}
              onWheel={e => e.target.blur()}
            />
          </Label>
          {(title === "Area" || title === "Height") && (
            <label className="ms-2 mt-1">(m)</label>
          )}
        </div>
        {errors[title] ? (
          <div className="error ms-2 mt-1 ">{errors[title]}</div>
        ) : null}
      </div>
    </>
  )
}

export default FromTo
