import React, { useEffect, useState } from "react"
import { Label, Input, Col, FormGroup } from "reactstrap"

const EditAnswerHint = ({ index, id, props }) => {
  const [anHint, setanHint] = useState(true)
  const AddAnHintToggle = item => {
    const el = document.getElementById(`answerHint${item}`)
    el.disabled = !el.disabled
  }

  return (
    // {/************************* Answer-Hint ***************************/}
    <FormGroup className="d-flex row mb-0 mt-1 " style={{ paddiing: "3px" }}>
      <Col sm={2}>
        <Label className=" mx-2  mt-1" for={`answerHint${id}`}>
          AnswerHint {index + 1}:
        </Label>
      </Col>
      <Col sm={3}>
        <Input
          id={`answerHint${id}`}
          name={`AnswerHint${id}`}
          value={props.values["AnswerHint" + id]}
          onChange={props.handleChange}
          placeholder="Answer Hint!"
          className="p-1 form-control"
          type="Text"
          disabled={anHint}
        />
      </Col>
      <Col>
        <Input
          onClick={() => {
            AddAnHintToggle(id)
          }}
          type="checkbox"
          id={`getAData${id}`}
          //   defaultChecked={props.values["AnswerHint" + id]}
          //   defaultChecked={props.values["AnswerHint" + id]}
        />
        <Label for={`getAData${id}`} className="ms-1">
          Edit Answer Hint
        </Label>
      </Col>
    </FormGroup>
  )
}

export default EditAnswerHint
