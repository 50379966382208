import React from "react"
import { Link } from "react-router-dom"
import { Label, Input, Col, FormGroup } from "reactstrap"

const IconsEditQuestion = props => {
  const addAnswer = () => {
    let lastId = props.answerIds[props.answerIds.length - 1]
    let temp = lastId + 1
    props.setAnswerIds([...props.answerIds, temp])
  }

  const removeAnswer = itemId => {
    const index = props.answerIds.indexOf(itemId)
    let tempAnswerIds = [...props.answerIds]
    // make sure the item is not the first one
    tempAnswerIds.splice(index, 1)
    props.setAnswerIds(tempAnswerIds)
  }

  return (
    <Col sm={4} className="d-flex mt-2  justify-content-around">
      {(props.inputType != "Boolean") & (props.inputType != "Text") ? (
        <FormGroup className="col-2 justify-content-between">
          <Link
            to="#"
            onClick={() => {
              let lastId = props.answerIds[props.answerIds.length - 1]
              props.props.values[`Answer${lastId + 1}`] = ""
              props.props.values[`AnswerHint${lastId + 1}`] = ""
              props.props.values[`default${lastId + 1}`] = false
              addAnswer()
            }}
            className="p-0"
          >
            <i className="mdi mdi-plus font-size-14" id="edittooltip" />
          </Link>

          <Link
            to="#"
            onClick={() => {
              if (props.answerIds.length > 1) {
                removeAnswer(props.id)
                delete props.props.values["Answer" + props.id]
                delete props.props.values["AnswerHint" + props.id]
                delete props.props.values["from" + props.id]
                delete props.props.values["to" + props.id]
                delete props.props.values["OperatorAnswer" + props.id]
                delete props.props.values["OperatorOption" + props.id]
                delete props.props.values["default" + props.id]
              }
            }}
            className="p-1 py-0"
          >
            <i className="mdi mdi-minus font-size-8" id="edittooltip" />
          </Link>
        </FormGroup>
      ) : (
        ""
      )}
    </Col>
  )
}

export default IconsEditQuestion
