import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useHistory, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/default.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import UpdatePasswordModal from "./UpdatePasswordModal"
import UpdateInfoModal from "./UpdateInfoModal"
import axios from "axios"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

const UserProfile = () => {
  //meta title
  document.title = "Profile | DomApp - React Admin & Dashboard Template"

  const history = useHistory()

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const userID = localStorage.getItem("id")
  const [userData, setUserData] = useState({})
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")

  // console.log("userData", userData)

  const dispatch = useDispatch()

  const [infoModal, setInfoModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)

  const infoToggle = () => {
    setInfoModal(!infoModal)
  }
  const passwordToggle = () => {
    setPasswordModal(!passwordModal)
  }

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      UserID: userID,
    }
    axios
      .post(`https://test.rdapp.net/api/Users/View`, formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", Object.entries(res.data)[0][1])
        let user = Object.entries(res.data)[0][1]
        setUserData(user)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="DomApp" breadcrumbItem="Profile" />

          <Row>
            {error ? <Alert color="danger">{error}</Alert> : null}
            {success ? <Alert color="success">{success}</Alert> : null}
          </Row>

          <Row>
            <Col sm={10} md={8} style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  <div className="d-flex ">
                    <div className="ms-3 me-5">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>
                          {userData.FirstName} {userData.LastName}
                        </h5>
                        <p className="mb-1">{userData.Email}</p>
                        <p className="mb-0">Id no: {userData.UserID}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="mt-5"
                    style={{ width: "60%", margin: "auto" }}
                  >
                    <Row>
                      {/* <Col> */}
                      <Button
                        style={{ marginBottom: "10px" }}
                        color="danger"
                        onClick={() => infoToggle()}
                      >
                        Update User Info
                      </Button>
                      {/* </Col>
                      <Col> */}
                      <Button color="danger" onClick={() => passwordToggle()}>
                        Update User Password
                      </Button>
                      {/* </Col> */}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {passwordModal && (
        <UpdatePasswordModal
          passwordToggle={passwordToggle}
          setSuccess={setSuccess}
          setError={setError}
          setAuth={setAuth}
        />
      )}
      {infoModal && (
        <UpdateInfoModal
          infoToggle={infoToggle}
          userData={userData}
          setSuccess={setSuccess}
          setError={setError}
          setAuth={setAuth}
        />
      )}
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
