import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

const TicketDataReceived = ({
  ticketDataToggle,
  selectedTicket,
  selectedFile,
}) => {
  // console.log("selectedTicket========>", selectedTicket)
  //   console.log("selectedFile========>", selectedFile)

  const [modal, setModal] = useState(true)
  const toggle = () => {
    ticketDataToggle()
  }

  const onClose = () => {
    ticketDataToggle()
  }

  return (
    <Modal className="modal-md" isOpen={modal} toggle={toggle}>
      <ModalHeader>
        File and Data Received
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <div>
          <h5>Data Received:</h5>
          <p
            style={{
              border: "1px solid lightgray",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            {selectedFile.Data || (
              <span className="error">No Data Received</span>
            )}
          </p>
        </div>

        <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
          {selectedFile.File ? (
            <a
              href={selectedFile.File}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              View File Received
            </a>
          ) : (
            <p className="error">No File Received</p>
          )}

          <Button className="bg-primary" onClick={onClose}>
            Close
          </Button>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}

export default TicketDataReceived
