import axios from "axios"
export const validationSchema = async (
  values,
  errors,
  setError,
  degreeAnswerTypeArray,
  answerType,
  fourCommentArAEnGroup,
  firstAnswer,
  answerIds,
  secondAnswer
) => {
  if (!values.QuestionTitle) {
    errors.QuestionTitle = "Question Title is required"
  }
  if (!values.Type) {
    errors.Type = "Question Type is required"
  }
  if (
    (answerType !== "Text") &
    (answerType !== "TextArea") &
    (answerType !== "Ticket") &
    !degreeAnswerTypeArray.includes(answerType)
  ) {
    if (!values.Answer1 || values.Answer1 == null) {
      errors.Answer = "Question should have one answer at least"
    }
  }

  answerIds.forEach(id => {
    if (degreeAnswerTypeArray.includes(answerType)) {
      if (values["Answer" + id]) {
        //degree error
        !values["degree" + id] &&
          (errors["degree" + id] = "you should choose degree")
        //comments
        if (answerType == "InsChecklist") {
          if (fourCommentArAEnGroup.includes(values["degree" + id])) {
            if (!values[values["degree" + id] + "Comment(en)" + id]) {
              errors[values["degree" + id] + "Comment(en)" + id] =
                "Please enter comment(en) "
            }
            if (!values[values["degree" + id] + "Comment(Ar)" + id]) {
              errors[values["degree" + id] + "Comment(Ar)" + id] =
                "Please enter comment(Ar) "
            }
            if (!values[values["degree" + id] + "Corrective(en)" + id]) {
              errors[values["degree" + id] + "Corrective(en)" + id] =
                "Please enter corrective(en) "
            }
            if (!values[values["degree" + id] + "Corrective(Ar)" + id]) {
              errors[values["degree" + id] + "Corrective(Ar)" + id] =
                "Please enter corrective(Ar) "
            }
          } else {
            if (values["degree" + id] != "12") {
              if (!values[values["degree" + id] + "Note" + id]) {
                errors[values["degree" + id] + "Note" + id] =
                  "Please enter Note "
              }
            }
          }
        } else if (answerType == "Observation" || answerType == "UserSelect") {
          values["degree" + id].forEach(item => {
            if (fourCommentArAEnGroup.includes(item)) {
              if (!values[item + "Comment(en)" + id]) {
                errors[item + "Comment(en)" + id] = "Please enter comment(en) "
              }
              if (!values[item + "Comment(Ar)" + id]) {
                errors[item + "Comment(Ar)" + id] = "Please enter comment(Ar) "
              }
              if (!values[item + "Corrective(en)" + id]) {
                errors[item + "Corrective(en)" + id] =
                  "Please enter corrective(en) "
              }
              if (!values[item + "Corrective(Ar)" + id]) {
                errors[item + "Corrective(Ar)" + id] =
                  "Please enter corrective(Ar) "
              }
            } else {
              if (item != "12") {
                if (!values[item + "Note" + id]) {
                  errors[item + "Note" + id] = "Please enter Note "
                }
              }
            }
          })
        }
        if (values["imagerequired" + id]) {
          //image required error
          if (!values["Imagerequired-" + id]) {
            errors["Imagerequired" + id] = "choose number of images"
          }
        }
      }
      // else delete errors["degree" + id]

      //answers error
      if (
        answerType === "InsChecklist" ||
        answerType === "UserSelect" ||
        answerType === "Observation"
      ) {
        if (values["Answer" + id] && !firstAnswer)
          firstAnswer = values["Answer" + id]
        if (values["Answer" + id] && values["Answer" + id] != firstAnswer) {
          secondAnswer = values["Answer" + id]
        }

        !secondAnswer || !firstAnswer
          ? (errors.answers = "Question should have Two answer at least")
          : delete errors.answers
      } else if (answerType === "Ticket") {
        !values["degree" + id] &&
          (errors["degree" + id] = "you should choose degree")

        //image required error
        if (values["imagerequired" + id]) {
          if (!values["Imagerequired-" + id]) {
            errors["Imagerequired" + id] = "choose number of images"
          }
        }
      }
      if (answerType === "Observation" || answerType === "Ticket") {
        !values["transferTo" + id] &&
          (errors["transferTo" + id] = "you should choose TransferTo")
      }
    }
  })

  setError(errors)
  console.log("errors", errors)
  console.log("values", values)

  return errors
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const onSubmitHandler = async (
  values,
  error,
  degreeAnswerTypeArray,
  sectionId,
  answerIds,
  onClose,
  editedQuestion,
  setAuth,
  history
) => {
  let formData = {}
  //  (console.log("editedQuestion", editedQuestion),

  if (Object.keys(error).length === 0) {
    formData = {
      Type: values.Type,
      QuestionTitle: values.QuestionTitle,
      QuestionHint: values.Hint,
      QuestionHelp: values.Help,
      Answers: [],
      SectionID: sectionId,
      Active: values.questionActive,
      RD6: values.questionRD6,
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    if (degreeAnswerTypeArray.includes(values.Type)) {
      let degrees = []
      let transfers = []
      let degree = {}
      if (values.Type === "InsChecklist") {
        answerIds.forEach(id => {
          // console.log("values[Answer + id]",values["Answer" + id]);
          // if (!values["Answer" + id]) {
          //   console.log("sssssssss");
          //   return
          // }
          let checklistValue = ""
          Array.isArray(values["Answer" + id])
            ? (checklistValue = values["Answer" + id][0])
            : (checklistValue = values["Answer" + id])

          degree = { Value: values["degree" + id], Comments: {} }
          degree.Comments["CommentEN"] =
            values[values["degree" + id] + "Comment(en)" + id]
          degree.Comments["CommentAR"] =
            values[values["degree" + id] + "Comment(Ar)" + id]
          degree.Comments["CorrectiveEN"] =
            values[values["degree" + id] + "Corrective(en)" + id]
          degree.Comments["CorrectiveAR"] =
            values[values["degree" + id] + "Corrective(Ar)" + id]
          degree.Comments["Note"] = values[values["degree" + id] + "Note" + id]
          degree.QODID = values["QODID" + id]
          degrees = degree

          formData.Answers.push({
            Value: checklistValue,
            Degree: degrees,
            ImagesRequired: values["Imagerequired" + "-" + id],
            QOID: values["QOID" + id],
          })
        })
      } else if (values.Type === "UserSelect") {
        answerIds.forEach(id => {
          console.log("values[Answer + id]", values["Answer" + id])

          degrees = []
          if (!values["Answer" + id]) {
            return
          }
          let checklistValue = ""
          Array.isArray(values["Answer" + id])
            ? (checklistValue = values["Answer" + id][0])
            : (checklistValue = values["Answer" + id])
          values["degree" + id].forEach(item => {
            degree = { Value: item, Comments: {} }
            degree.Comments["CommentEN"] = values[item + "Comment(en)" + id]
            degree.Comments["CommentAR"] = values[item + "Comment(Ar)" + id]
            degree.Comments["CorrectiveEN"] =
              values[item + "Corrective(en)" + id]
            degree.Comments["CorrectiveAR"] =
              values[item + "Corrective(Ar)" + id]
            degree.Comments["Note"] = values[item + "Note" + id]
            editedQuestion && (degree["QODID"] = values[item + "QODID" + id])
            degrees.push(degree)
          })

          formData.Answers.push({
            Value: checklistValue,
            Degree: degrees,
            ImagesRequired: values["Imagerequired" + "-" + id],
            QOID: values["QOID" + id],
          })
        })
      } else if (values.Type === "Observation") {
        answerIds.forEach(id => {
          degrees = []
          if (!values["Answer" + id]) {
            return
          }

          values["degree" + id].forEach(item => {
            transfers = []
            degree = { Value: item, Comments: {} }
            degree.Comments["CommentEN"] = values[item + "Comment(en)" + id]
            degree.Comments["CommentAR"] = values[item + "Comment(Ar)" + id]
            degree.Comments["CorrectiveEN"] =
              values[item + "Corrective(en)" + id]
            degree.Comments["CorrectiveAR"] =
              values[item + "Corrective(Ar)" + id]
            degree.Comments["Note"] = values[item + "Note" + id]
            degree["QODID"] = values[item + "QODID" + id]
              ? values[item + "QODID" + id]
              : "0"
            degrees.push(degree)
          })

          values["transferTo" + id].map((transferitem, key) => {
            let transfer = { Value: transferitem }
            // transfer.Value = values["transferTo" + id][key]
            editedQuestion &&
              (transfer.QODID = values[transferitem + "transferQODID" + id]
                ? values[transferitem + "transferQODID" + id]
                : "0")
            transfers.push(transfer)
          })
          formData.Answers.push({
            Value: values["Answer" + id],
            Degree: degrees,
            Transfer: transfers,
            ImagesRequired: values["Imagerequired" + "-" + id],
            Active: values["activeAnswer" + id],
            QOID: values["QOID" + id] ? values["QOID" + id] : "0",
          })
        })
      } else {
        values["degree1"].map((item, key) => {
          if (values.Type === "Ticket") {
            let degreeEdite = {}
            degreeEdite.Value = values["degree1"] && values["degree1"][key]
            editedQuestion &&
              (degreeEdite.QODID = values["QODID1"] && values["QODID1"][key])
            degrees.push(degreeEdite)
          } else {
            ;(degree = { Value: item, Comments: {} }), degrees.push(degree)
          }
        })
        // values["transferTo1"].forEach(transfer => {
        //   transfers.push(transfer)
        // })
        values["transferTo1"].map((transferitem, key) => {
          let transfer = {}
          transfer.Value = values["transferTo1"] && values["transferTo1"][key]
          editedQuestion &&
            (transfer.QODID = values[transferitem + "transferQODID1"]
              ? values[transferitem + "transferQODID1"]
              : "0")
          transfers.push(transfer)
        })

        formData.Answers.push({
          Degree: degrees,
          Transfer: transfers,
          ImagesRequired: values["Imagerequired" + "-" + "1"],
          QOID: values["QOID" + "1"],
        })
      }
    } else {
      answerIds.forEach(id => {
        formData.Answers.push({
          Value: values[`Answer${id}`],
          Default: values[`default${id}`],
          QOID: values["QOID" + id] ? values["QOID" + id] : "0",
        })
      })
    }
   
    editedQuestion && (formData["QID"] = values.QID)
    // console.log("editedQuestion", editedQuestion)
    editedQuestion
      ?
      //  ((console.log("editedQuestion", editedQuestion),
      //   console.log("formData", formData)),
      //   axios
      //     .post(
      //       "https://test.rdapp.net/api/inspection/EditInspectionQuestion.php",
      //       formData,
      //       { Token: localStorage.getItem("token") }
      //     )
      //     .then(res => {
      //       if (
      //         res.data.error === true &&
      //         res.data.message === "Access denied!"
      //       ) {
      //         setAuth(true)
      //         setTimeout(() => {
      //           history.push("/logout")
      //           setTimeout(() => {
      //             history.push("/login")
      //           }, 1000)
      //         }, 4000)
      //       }

      //       console.log(res)
      //       onClose()
      //       window.location.reload(false)
      //     })
      //     .catch(error => {
      //       console.log(error)
      //     }))
          ""
      : axios
          .post(
            "https://test.rdapp.net/api/inspection/AddInspectionQuestion.php",
            formData,
            { Token: localStorage.getItem("token") }
          )
          .then(res => {
            if (
              res.data.error === true &&
              res.data.message === "Access denied!"
            ) {
              setAuth(true)
              setTimeout(() => {
                history.push("/logout")
                setTimeout(() => {
                  history.push("/login")
                }, 1000)
              }, 4000)
            }

            console.log(res)
            onClose()
            window.location.reload(false)
          })
          .catch(error => {
            console.log(error)
          })
  }
}
