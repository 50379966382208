import React, { useEffect, useState } from "react"
import { Col, Container, Row, Table, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { Tabs } from "react-bootstrap"
import { Tab } from "bootstrap"
import StructureType from "./StructureType"
import RegionCosts from "./RegionCosts"
import ProjectType from "./ProjectType"

const ProjectCostEstimation = () => {
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (userPermissions.R12.G !== "1")
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Settings" breadcrumbItem="Cost Estimation" />

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <div>
                      <Tabs className="mb-3" justify fill>
                        <Tab eventKey="region" title="Region Costs">
                          <div className="d-flex justify-content-center">
                            <RegionCosts setAuth={setAuth} />
                          </div>
                        </Tab>
                        <Tab eventKey="structure" title="Structure Type">
                          <div className="d-flex justify-content-center">
                            <StructureType setAuth={setAuth} />
                          </div>
                        </Tab>
                        <Tab eventKey="project" title="Project Type">
                          <div className="d-flex justify-content-center">
                            <ProjectType setAuth={setAuth} />
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ProjectCostEstimation
