import axios from "axios"
import React, { useEffect, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

const FilterUsers = ({ filteredValues, setFilteredValues }) => {
  const history = useHistory()

  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .post("https://test.rdapp.net/api/Roles/View", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        ShowAll: true,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res========>", res.data)
        let array = Object.entries(res.data)
        array.splice(-4)
        console.log("array", array)
        array.map((item, i) => {
          roles.push({ value: item[1].RoleID, label: item[1].RoleName })
        })
      })
      .catch(err => console.log(err))
  }, [])

  // console.log("filteredValues", filteredValues)

  const statusOptions = [
    { value: "On", label: "Active" },
    { value: "Off", label: "Dismissed" },
  ]

  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <Row>
      <Col md={4}>
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-2 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={users}
              name="username"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.UserName}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, UserName: e })
              }}
              classNamePrefix="select"
              placeholder="User Name ..."
            />
          </div>
        </div>
      </Col>

      <Col md={4}>
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-2 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={roles}
              name="role"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.Role}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, Role: e })
              }}
              classNamePrefix="select"
              placeholder="User Role ..."
            />
          </div>
        </div>
      </Col>

      <Col md={4}>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12 ">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="select"
                name="status"
                onChange={e => {
                  // console.log(e.target.value)
                  setFilteredValues({
                    ...filteredValues,
                    Status: e.target.value,
                  })
                }}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
              >
                <option selected value="">
                  Status ...
                </option>
                {statusOptions.map((status, i) => (
                  <option key={i} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Input>
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FilterUsers
