import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Button,
  FormGroup,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

const MissingData = ({
  toggleMissingData,
  MissingReport,
  setAuth,
  missingDataModal,
}) => {
  const history = useHistory()

  const [missingReports, setMissingReports] = useState([])
  const [modal, setmodal] = useState(true)
  const [data, setData] = useState(MissingReport)
  let [accurate, setAccurate] = useState([])
  const toggle = () => {
    setmodal(!modal)
  }

  const close = () => {
    toggle()
  }

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/GetProjectRequirements.php", {
        ...formData,
        ProjectID: MissingReport.ProjectID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setMissingReports(array)
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .post("https://test.rdapp.net/api/ProjectLocationsView", {
        ...formData,
        ProjectID: MissingReport.ProjectID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        setAccurate(data[0][1].AccurateLocation)
      })
      .catch(error => console.log(error))
  }, [])

  const [solvedReports, setSolvedReports] = useState([])
  const handleReportChange = e => {
    if (solvedReports.includes(e.target.value)) {
      setSolvedReports(solvedReports.filter(report => report != e.target.value))
    } else {
      setSolvedReports([...solvedReports, e.target.value])
    }
  }

  return (
    <Modal
      isOpen={missingDataModal}
      toggle={toggleMissingData}
      style={{ width: "1000px" }}
    >
      <ModalHeader>
        {data.type == "Residential" ? (
          <i className="fa fa-home me-2"></i>
        ) : (
          <i className="fa fa-sharp fa-hotel  me-2"></i>
        )}
        <Link to="#" className="badge bg-light font-size-14 mb-2">
          <span> Reference No : </span>
          <span>{MissingReport.ReferenceNo}</span>
        </Link>
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <Form
            onSubmit={e => {
              handleSubmite(e)
            }}
            // className={solve && "opacity-50"}
            // disabled={solve}
          >
            <div className="mt-3">
              {missingReports.map(report => (
                <FormGroup key={report.RID} className="mb-2 ms-4">
                  <label htmlFor={report.ReportName}>
                    -{" "}
                    {report.ReportName
                      ? report.ReportName
                      : report.OrgReportName}
                  </label>
                  {(report.ReportNameAr || report.OrgReportNameAR) && (
                    <label className="d-block me-5"  dir="rtl" htmlFor={report.ReportNameAr}>
                      -{" "}
                      {report.ReportNameAr
                        ? report.ReportNameAr
                        : report.OrgReportNameAR}
                    </label>
                  )}
                </FormGroup>
              ))}
            </div>

            <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-around">
              <Button
                onClick={() => {
                  close()
                  toggleMissingData()
                }}
              >
                close
              </Button>
            </FormGroup>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default MissingData
