import { Formik } from "formik"
import * as yup from "yup"
import React from "react"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const UsersForm = ({ project, onClose, editing, setEditing, setAuth }) => {
  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const usersValidationSchema = yup.object().shape({
    ownerName: yup.string().required("required"),
    constructorName: yup.string().required("required"),
    designerOfficeName: yup.string().required("required"),
    supervisorOfficeName: yup.string().required("required"),
    hiddenConstructorName: yup.string().required("required"),
  })

  const submitHandler = (values, onSubmitProps) => {
    onSubmitProps.resetForm()
    // console.log("values", values)
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: project.ProjectID,
      Action: "Users",
      OwnerID: project.OwnerID,
      OwnerName: values.ownerName,
      ConstructorID: project.ConstructorID,
      ConstructorName: values.constructorName,
      DesignerOfficeID: project.DesignerOfficeID,
      DesignerOfficeName: values.designerOfficeName,
      SupervisorOfficeID: project.SupervisorOfficeID,
      SupervisorOfficeName: values.supervisorOfficeName,
      HiddenConstructorID: project.HiddenConstructorID,
      HiddenConstructorName: values.hiddenConstructorName,
    }

    axios
      .post("https://test.rdapp.net/api/EditProject", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        // updating project header data by useEffect dependencies
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    // console.log("formData========>", formData)
    onClose()

    // updating project header data by useEffect dependencies
    setEditing(!editing)
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ownerName: project.OwnerName || "",
          constructorName: project.ConstructorName || "",
          designerOfficeName: project.DesignerOfficeName || "",
          supervisorOfficeName: project.SupervisorOfficeName || "",
          hiddenConstructorName: project.HiddenConstructorName || "",
        }}
        validationSchema={usersValidationSchema}
        onSubmit={submitHandler}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            {/*****************  Owner info ***********************/}
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="owner-name">Owner Name</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="owner-name"
                  name="ownerName"
                  value={props.values["ownerName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Owner Name ..."
                />
              </Col>
              {props.touched.ownerName && props.errors.ownerName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.ownerName}
                </span>
              ) : null}
            </Row>

            {/*****************  Constructor info ***********************/}
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="constructor-name">Constructor Name</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="constructor-name"
                  name="constructorName"
                  value={props.values["constructorName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Constructor Name ..."
                />
              </Col>
              {props.touched.constructorName && props.errors.constructorName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.constructorName}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="designerOffice-name">
                  Designer Office Name
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  id="designerOffice-name"
                  name="designerOfficeName"
                  value={props.values["designerOfficeName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Designer Office Name ..."
                />
              </Col>
              {props.touched.designerOfficeName &&
              props.errors.designerOfficeName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.designerOfficeName}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="supervisorOffice-name">
                  Supervisor Office Name
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  id="supervisorOffice-name"
                  name="supervisorOfficeName"
                  value={props.values["supervisorOfficeName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Supervisor Office Name ..."
                />
              </Col>
              {props.touched.supervisorOfficeName &&
              props.errors.supervisorOfficeName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.supervisorOfficeName}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="hiddenConstructor-name">
                  Hidden Constructor Name
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  id="hiddenConstructor-name"
                  name="hiddenConstructorName"
                  value={props.values["hiddenConstructorName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Hidden Constructor Name ..."
                />
              </Col>
              {props.touched.hiddenConstructorName &&
              props.errors.hiddenConstructorName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.hiddenConstructorName}
                </span>
              ) : null}
            </Row>

            <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-primary"
                disabled={
                  userPermissions.R7.P !== "3" && userPermissions.R7.P !== "4"
                }
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UsersForm
