

export const convertObjectToArray=(apiObject,splice)=>{
    let array=[]
let initialArray = Object.entries(apiObject)
splice&& initialArray.splice(-1)
initialArray.forEach(item=>{
    array.push(item[1])
})
return array
}