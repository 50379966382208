import React, { useEffect, useState } from "react"
import { Label, Input, Col, FormGroup, Row } from "reactstrap"
import AnswerHint from "./AnswerHint"
import Icons from "./Icons"
import FileAnswer from "./FileAnswer"
import DropdownMultiFunctions from "./DropdownMultiFunctions"
import TextList from "./TextList"
import Observation from "../../Inspection/InspectionBuilder/Observation"
import UnitsList from "./UnitsList"
import UnitsList2 from "./UnitsList2"
import CheckboxATextGroup from "./CheckboxATextGroup"

function AnswerCreation({
  props,
  inputType,
  answerIds,
  isInput,
  setInput,
  setOperator,
  Operator,
  editingDegreeList,
  transferList,
  setAnswerIds,
  groupedOptions
}) {
  return (
    <>
      {/* Other Answer */}
      {(inputType == "radio" ||
        inputType == "checkbox" ||
        inputType == "dropdown") && (
        <CheckboxATextGroup props={props} name={"Other"} />
      )}

      {/** Answers Creation **/}
      {/* {inputType == "checkbox" && (
        <Row className="justify-content-center me-5">
          <Col sm={3}>
            <Label className="d-flex align-items-center">
              <Col>Max Chosen :</Col>
              <Col sm={4}>
                <Input
                  className="text-center"
                  type="number"
                  id="MaxChosen"
                  name={`MaxChosen`}
                  value={props.values["MaxChosen"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  onWheel={e => e.target.blur()}
                />
              </Col>
            </Label>
          </Col>
        </Row>
      )} */}
      {inputType == "unitsList" && (
        <Col>
          <UnitsList props={props} groupedOptions={groupedOptions} />
        </Col>
      )}

      {answerIds.map((id, index) => (
        <div key={index}>
          <div
            id={"div" + id}
            className="d-flex row"
            style={{ marginBottom: "0" }}
          >
            <Label for="Answer" sm={2}>
              Answer{index + 1} :
            </Label>
            <Col
              sm={
                inputType == "text" ||
                inputType == "observation" ||
                inputType == "unitsList"
                  ? 9
                  : 6
              }
              className="d-flex "
            >
              {/******* icon of radio or check ********/}
              {isInput &&
                (inputType == "radio" || inputType == "checkbox" ? (
                  <Input type={inputType} className="me-2" />
                ) : (
                  () => {
                    setInput(false)
                  }
                ))}
              {/**************** if Number or Date *********************/}
              {inputType == "number" || inputType == "date" ? (
                <div>
                  {id == 1 && (
                    <Input
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="select"
                      name="operator"
                      onClick={e => {
                        setOperator(e.target.value)
                      }}
                      className="mb-2 me-5 p-1 form-control"
                    >
                      <option></option>
                      <option value={"Operator"}>{"Operator"}</option>
                      <option value={"Between"}>{"Between"}</option>
                    </Input>
                  )}
                  <div className="d-flex align-items-center">
                    {Operator == "Operator" ? (
                      <FormGroup className="d-flex">
                        <Input
                          value={"User-Input"}
                          readOnly
                          className="text-bg-danger"
                        ></Input>
                        <Input
                          id="OperatorOption"
                          name={`Operator${id}`}
                          type="select"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          sm={10}
                          className="mx-3 p-1 text-center text-color-primary form-control"
                        >
                          <option selected disabled>
                            Choose operator
                          </option>
                          <option value={"EQ"}>{"="}</option>
                          <option value={"NQ"}>{"!="}</option>
                          <option value={"GT"}>{">"}</option>
                          <option value={"LT"}>{"<"}</option>
                          <option value={"LQ"}>{"<="}</option>
                          <option value={"GQ"}>{">="}</option>
                        </Input>
                        {props.touched[`Operator${id}`] &&
                        props.errors[`Operator${id}`] ? (
                          <div className="error">
                            {props.errors[`Operator${id}`]}
                          </div>
                        ) : null}
                        <Input
                          id="answer"
                          name={`Answer${id}`}
                          value={props.values["Answer" + id]}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          placeholder="Enter your Answer"
                          type={inputType}
                          className="p-1"
                        />
                        {props.touched[`Answer${id}`] &&
                        props.errors[`Answer${id}`] ? (
                          <div className="error">
                            {props.errors[`Answer${id}`]}
                          </div>
                        ) : null}
                      </FormGroup>
                    ) : Operator == "Between" ? (
                      <FormGroup className="col-12 d-flex">
                        <Label for="from" className="mx-3 d-flex col-6">
                          <Col sm={3}>from :</Col>
                          <Input
                            id="from"
                            name={`from${id}`}
                            value={props.values["from" + id]}
                            placeholder="Enter your Answer"
                            type={inputType}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            className="form-control p-1 ms-2 "
                          />

                          {props.touched[`from${id}`] &&
                          props.errors[`from${id}`] ? (
                            <div className="error ">
                              {props.errors[`from${id}`]}
                            </div>
                          ) : null}
                        </Label>
                        <Label for="to" className="mx-3 d-flex col-6">
                          <Col sm={3}>to :</Col>
                          <Input
                            id="to"
                            name={`to${id}`}
                            value={props.values["to" + id]}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Enter your Answer"
                            className="p-1 form-control ms-2"
                            type={inputType}
                          />
                        </Label>
                        {props.touched[`to${id}`] && props.errors[`to${id}`] ? (
                          <div className="error">{props.errors[`to${id}`]}</div>
                        ) : null}
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : //*********** if file ||dropdown||radio||checkbox ******************//

              // inputType == "dropdown" ||
              inputType == "checkbox" || inputType == "radio" ? (
                <Input
                  id="Answer"
                  name={`Answer${id}`}
                  value={props.values["Answer" + id]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className="form-control"
                  placeholder="Enter your Answer"
                  type="Text"
                />
              ) : inputType == "observation" ? (
                <Observation
                  props={props}
                  answerId={id}
                  degreeList={editingDegreeList}
                  transferList={transferList}
                  active={false}
                />
              ) : inputType == "unitsList" ? (
                <UnitsList2
                  props={props}
                  answerId={id}
                  groupedOptions={groupedOptions}
                />
              ) : inputType == "dropdown" ? (
                <DropdownMultiFunctions props={props} id={id} />
              ) : inputType == "file" ? (
                <FileAnswer props={props} id={id} />
              ) : //*********** if boolean **************//
              inputType == "boolean" ? (
                id == 1 ? (
                  <FormGroup className="d-flex">
                    <div className="col-12">
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control"
                        id={`Answer${id}`}
                        name={`Answer${id}`}
                        value={"Yes"}
                        type="Text"
                        readOnly
                      />
                    </div>
                  </FormGroup>
                ) : (
                  <FormGroup className="d-flex">
                    <div className="col-12">
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control"
                        id={`Answer${id}`}
                        name={`Answer${id}`}
                        value={"No"}
                        type="Text"
                        readOnly
                      />
                    </div>
                  </FormGroup>
                )
              ) : //***************** if text *********************//
              inputType == "text" ? (
                <div className="d-flex col-9">
                  {getData && (
                    <Col sm={6}>
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder="Enter your Answer"
                        className=" d-flex col-1 form-control"
                        type="textarea"
                      />
                    </Col>
                  )}
                  <Col sm={!getData ? 6 : 3}>
                    <Input
                      id="BigData"
                      name="Answer1"
                      type="select"
                      disabled={getData}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      className="form-control mx-3"
                    >
                      <option>Big Data</option>
                      <option value={"ProjectName "}>{"ProjectName "}</option>
                      <option value={"Site-Name"}>{"Site Name"}</option>
                      <option value={"Id"}>{"Id"}</option>
                    </Input>
                  </Col>
                  <Col sm={3} className="ms-5 mt-2">
                    <Input
                      onClick={() => {
                        getDataToggle()
                      }}
                      type="checkbox"
                      id="getData"
                    />
                    <Label for="getData" className="ms-1">
                      Get Data
                    </Label>
                  </Col>
                </div>
              ) : inputType == "textlist" ? (
                <div className="Col-9">
                  <TextList props={props} id={id} />
                </div>
              ) : null}
            </Col>
            {/** Icons of answer **/}
            {(inputType !== "file") &
            (inputType !== "text") &
            (inputType !== "textlist") &
            (inputType !== "boolean") &
            (inputType !== "text") ? (
              <Col sm={1}>
                <Icons
                  props={props}
                  setAnswerIds={setAnswerIds}
                  answerIds={answerIds}
                  inputType={inputType}
                  id={id}
                  defaultInput={inputType === "observation" && false}
                />
              </Col>
            ) : (
              ""
            )}

            {/************************* Answer-Hint ***************************/}
            <AnswerHint index={index} id={id} props={props}></AnswerHint>
          </div>
        </div>
      ))}
      {/* Answer Validation */}
      {props.touched.Answer1 && props.errors.Answer1 ? (
        <div className="error" style={{ marginInlineStart: "130px" }}>
          {props.errors.Answer1}
        </div>
      ) : null}
    </>
  )
}

export default AnswerCreation
