import React from "react"
import { Link } from "react-router-dom"
import { Label, Input, Col, FormGroup } from "reactstrap"

function PlusAMinesIcon(props) {
  const addAnswer = () => {
    let lastId = props.answerIds[props.answerIds.length - 1]
    let temp = lastId + 1
    props.setAnswerIds([...props.answerIds, temp])
  }

  const removeAnswer = itemId => {
    const index = props.answerIds.indexOf(itemId)
    let tempAnswerIds = [...props.answerIds]
    tempAnswerIds.splice(index, 1)
    props.setAnswerIds(tempAnswerIds)
  }

  return (
    <FormGroup className={props.className}>
      <Link
        to="#"
        onClick={() => {
          let lastId = props.answerIds[props.answerIds.length - 1]
          if (lastId < 21) {
            addAnswer()
          }
          props.props.values[`Answer${lastId + 1}`] = ""
          props.props.values[`AnswerHint${lastId + 1}`] = ""
          props.props.values[`default${lastId + 1}`] = false
        }}
        className="p-0"
      >
        <i className="mdi mdi-plus font-size-14" id="edittooltip" />
      </Link>

      <Link
        to="#"
        onClick={() => {
          if (props.answerIds.length > 2) {
            removeAnswer(props.id)
            delete props.props.values["Answer" + props.id]
            delete props.props.values["AnswerHint" + props.id]
            delete props.props.values["default" + props.id]
          }
        }}
        className="p-1 py-0"
      >
        <i className="mdi mdi-minus font-size-8" id="edittooltip" />
      </Link>
    </FormGroup>
  )
}

export default PlusAMinesIcon
