import React, { useEffect, useRef, useState } from "react"
import { Input, FormGroup, Label } from "reactstrap"
import RadioOrCheckboxGroup from "./RadioOrCheckboxGroup"
import CheckboxADropdown from "./CheckboxADropdown"
import MultiSelectInspection from "./MultiSelectInspection"
import Comments from "./Comments"

function DegreeDropdown(props) {
  const [degreevalue, setDegreeValue] = useState({})
  const [degreevalueMulti, setDegreeValueMulti] = useState([])
  const [otherToggle, setOtherToggle] = useState(true)
  const [disableIcons, setDisableIcons] = useState(true)
  const addOtherToggle = () => {
    setOtherToggle(!otherToggle)
  }
  const disableToggle = () => {
    setDisableIcons(!disableIcons)
  }
  // useEffect(() => {
  //   if (disableIcons == true) {
  //     setDegreeValue({})
  //     setDegreeValueMulti([])
  //   }
  // }, [disableIcons])
  // props.props.values["degree" + props.answerId] &&
  //   useEffect(() => {
  //     setDegreeValue({ value: props.props.values["degree" + props.answerId] })
  //   }, [])

  useEffect(() => {
    let multivalues = []
    props.userSelect
      ? props.props.values["degree" + props.answerId] &&
        (setDegreeValueMulti(props.props.values["degree" + props.answerId]),
        setDisableIcons(false),
        props.props.values["other"] && setOtherToggle(false))
      : props.props.values["degree" + props.answerId] &&
        (setDegreeValue({
          value: props.props.values["degree" + props.answerId],
        }),
        setDisableIcons(false),
        props.props.values["other"] && setOtherToggle(false))
  }, [])
  let OtherAnswer = useRef()
  let autoCommentGroup = ["10", "11", "12"]
  return (
    <div>
      <div className="d-flex col-12 justify-content-around">
        <Label for={"answer" + props.answerId} sm={1}>
          Answer {props.answerId} :
        </Label>
        {props.answerName === "Other" ? (
          <FormGroup className="d-flex col-2">
            <Input
              type="checkbox"
              className="me-1 mt-1 col-2 form-control"
              onClick={() => {
                addOtherToggle()
                disableToggle()
                otherToggle === false && (props.props.values["other"] = "")
              }}
              defaultChecked={props.props.values["other"]}
            />
            <div className="d-flex col-6">
              <Input
                ref={OtherAnswer}
                type="text"
                placeholder="Other"
                style={{ height: "30px" }}
                className="form-control"
                onChange={props.props.handleChange}
                onBlur={props.props.handleBlur}
                disabled={otherToggle}
                name={"Answer" + props.answerId}
                defaultValue={props.props.values["other"]}
              />
            </div>
          </FormGroup>
        ) : (
          <RadioOrCheckboxGroup
            disableToggle={disableToggle}
            props={props.props}
            type={"checkbox"}
            name={"Answer" + props.answerId}
            title={props.answerName}
            value={props.answerName == "N/A" ? "NA" : props.answerName}
            id={"Answer" + props.answerId}
            style={"col-2"}
            defaultChecked={
              props.answerName == "N/A"
                ? "NA" === props.props.values["Answer" + props.answerId]
                : props.answerName ==
                  props.props.values["Answer" + props.answerId]
            }
          />
        )}
        <FormGroup className={props.style}>
          <MultiSelectInspection
            isMulti={props.userSelect && true}
            props={props.props}
            id={props.id}
            data={props.degreeList}
            name={"degree"}
            title={"Degree"}
            setValue={props.userSelect ? setDegreeValueMulti : setDegreeValue}
            className={"me-2 col-10"}
            disabled={disableIcons}
          />

          {props.props.errors["degree" + props.id] ? (
            <div className="error mb-2">
              {props.props.errors["degree" + props.id]}
            </div>
          ) : null}
        </FormGroup>

        <CheckboxADropdown
          props={props.props}
          name={"Imagerequired" + "-" + props.answerId}
          title={"Image-required"}
          disabled={disableIcons}
          id={props.id}
        />
      </div>
      <div>
        {props.userSelect
          ? degreevalueMulti &&
            degreevalueMulti.map((degreeName, key) => (
              <Comments
                key={key}
                commentsNumber={
                  degreeName.value
                    ? autoCommentGroup.includes(degreeName.value)
                      ? 1
                      : 4
                    : autoCommentGroup.includes(degreeName)
                    ? 1
                    : 4
                }
                userSelect={props.userSelect}
                classNameDiv={"d-flex col-10 mb-2"}
                className=" ms-5 d-flex col-4 me-2 mb-1  form-control"
                props={props.props}
                name={"comments"}
                placeholder={degreeName.value ? degreeName.value : degreeName}
                id={props.answerId}
              />
            ))
          : degreevalue &&
            degreevalue.value && (
              <Comments
                commentsNumber={
                  autoCommentGroup.includes(degreevalue.value) ? 1 : 4
                }
                userSelect={props.userSelect}
                classNameDiv="d-flex col-5 mb-2 ms-5"
                className="ms-5 d-flex col-6 me-3 mb-1  form-control"
                props={props.props}
                name={"comments"}
                placeholder={degreevalue.value}
                id={props.answerId}
              />
            )}
      </div>
    </div>
  )
}

export default DegreeDropdown
