import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  FormGroup,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import { useHistory } from "react-router-dom"
import QuestionLevel from "./QuestionLevel"
import QuestionTitleAOtherInputs from "./QuestionTitleAOtherInputs"
import QuestionType from "./QuestionType"
import AnswerCreation from "./AnswerCreation"

const CreateQuestion = ({ questiontoggle, props, setAuth, toggleChange }) => {
  let sectionId = props.match.params.SectionID
  const history = useHistory()
  let fourCommentArAEnGroup = ["7", "8", "9"]

  // Units
  const [groupedOptions, setGroupOptions] = useState([])
  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/Units/Unit_List.php", formData)
      .then(res => {
        setGroupOptions(res.data)
      })
      .catch(err => console.log(err))
  }, [])

  // Degree A TransferTo
  const [degreeList, setDegreeValue] = useState([])
  const [transferList, setTransferValue] = useState([])
  let RDIssuer = { ResourceID: "200", Name: "RD-Issuer" }
  useEffect(() => {
    axios
      .post("https://test.rdapp.net/api/inspection/DegreeList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Category: "Design",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        let data = Object.entries(resData).filter(item => {
          return Number(item[0])
        })
        // console.log(data)
        setDegreeValue(data)
      })
    axios
      .post(
        "https://test.rdapp.net/api/inspection/TransferToList.php",

        {
          PerToken: localStorage.getItem("token"),
          PerUserID: localStorage.getItem("id"),
          PerRoleID: localStorage.getItem("userRoleID"),
        }
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        resData[200] = RDIssuer
        let data = Object.entries(resData).filter(item => {
          return Number(item[0])
        })
        setTransferValue(data)
      })
  }, [])

  let editingDegreeList = degreeList.filter(item => {
    return (item.DegreeID != 8) & (item.DegreeID != 12)
  })

  const [isInput, setInput] = useState(true) //for radio and check icons
  const [Operator, setOperator] = useState("") //for operators

  const [getData, setGetData] = useState(true) //for text
  const getDataToggle = () => {
    setGetData(!getData)
  }

  //*** form control **//
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    setmodal(!modal)
  }
  const onClose = () => {
    questiontoggle()
  }

  // **add & remove Answers */
  const [answerIds, setAnswerIds] = useState([1]) //for creation answers ID

  //* Input type to control */
  let [inputType, setInputtype] = useState("")
  const clickForm = e => {
    e.target.value == "boolean" ? setAnswerIds([1, 2]) : setAnswerIds([1])
    setInputtype(e.target.value)
  }
  const [level, setLevel] = useState("Parent")

  //* if Child **//
  const [ischild, setischild] = useState(false) //for show two inputs(parentQuestion & linkedAnswers) of child

  //** Errors **//
  const [error, setError] = useState({})

  const [childQestionsID, setChildQuestionsID] = useState([1])

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>Create Question</ModalHeader>
      <ModalBody className="d-flex row">
        <Formik
          enableReinitialize={true}
          initialValues={{
            Level: level,
            ParentQuestion: "",
            LinkedAnswer: "",
            Type: "",
            QuestionTitle: "",
            QuestionHint: "",
            Answer1: "",
            Answer2: "",
            AnswerHint1: "",
            from1: "",
            to1: "",
            Operator: "",
            dropType: "",
            Help: "",
            Single: "1",
            degree1: [],
            units1: [],
          }}
          validate={values => {
            let errors = {}

            if (!values.QuestionTitle) {
              errors.QuestionTitle = "Question Title is required"
            }
            // child Validation
            if (ischild) {
              if (!values["ParentAID"]) {
                errors.ParentAID = "Option Title is required"
              }
            }

            // Minimum Answer validation
            if (
              inputType != "number" &&
              inputType != "text" &&
              inputType != "addTicketManual" &&
              inputType != "date" &&
              inputType !== "textlist"
            ) {
              if (!values.Answer1 || values.Answer1 == null) {
                errors.Answer1 = "Question should have one answer at least"
              }
            }
            // Text List
            if (inputType === "textlist" && !values["NumOfPoints"]) {
              errors["NumOfPoints"] = "Num of Points should has value"
            }

            // Number & Date validation
            answerIds.forEach(id => {
              if (values[`operator`] == "Operator") {
                if (!values[`Operator${id}`]) {
                  errors[`Operator${id}`] = "Operator required"
                }
                if (!values[`Answer${id}`]) {
                  errors[`Answer${id}`] = "number required"
                }
              }
              if (values[`operator`] == "Between") {
                if (!values[`from${id}`]) {
                  errors[`from${id}`] = " required"
                }
                if (!values[`to${id}`]) {
                  errors[`to${id}`] = " required"
                }
              }
              // observation
              if (inputType == "observation") {
                values["degree" + id].forEach(item => {
                  if (fourCommentArAEnGroup.includes(item)) {
                    if (!values[item + "Comment(en)" + id]) {
                      errors[item + "Comment(en)" + id] =
                        "Please enter comment(en) "
                    }
                    if (!values[item + "Comment(Ar)" + id]) {
                      errors[item + "Comment(Ar)" + id] =
                        "Please enter comment(Ar) "
                    }
                    if (!values[item + "Corrective(en)" + id]) {
                      errors[item + "Corrective(en)" + id] =
                        "Please enter corrective(en) "
                    }
                    if (!values[item + "Corrective(Ar)" + id]) {
                      errors[item + "Corrective(Ar)" + id] =
                        "Please enter corrective(Ar) "
                    }
                  }
                })
                !values["transferTo" + id] &&
                  (errors["transferTo" + id] = "you should choose TransferTo")
              }
              if (inputType == "unitsList") {
                if (!values[`Answer${id}`] && !values["units" + id]) {
                  errors["units" + id] = " you should choose unit"
                }
              }
            })

            // Type validation
            if (!values.Type) {
              errors.Type = "You should choose type"
            }

            setError(errors)
            console.log("errors", errors)
            console.log("values", values)
            return errors
          }}
          // submit
          onSubmit={(values, actions) => {
            actions.setSubmitting(false)
            let formData = {}
            if (Object.keys(error).length === 0) {
              formData = {
                PerToken: localStorage.getItem("token"),
                PerUserID: localStorage.getItem("id"),
                PerRoleID: localStorage.getItem("userRoleID"),
                Level: values.Level,
                ParentQuestion: values.ParentQuestion,
                ParentAID: values.ParentAID,
                Type: values.Type,
                QuestionTitle: values.QuestionTitle,
                QuestionHint: values.Hint,
                QuestionHelp: values.Help,
                Answers: [],
                ConditionalAnswers: [],
                ConditionalQuestions: [],
                QuestionHelp: values.Help,
                SectionID: sectionId,
                MissingData: values.MissingData,
                Other: values.Other,
                Single: values.Single,
              }

              // observation
              if (values.Type === "Observation") {
                let transfers
                let degrees
                let degree
                answerIds.forEach(id => {
                  degrees = []
                  if (!values["Answer" + id]) {
                    return
                  }

                  values["degree" + id].forEach(item => {
                    transfers = []
                    degree = { Value: item, Comments: {} }
                    degree.Comments["CommentEN"] =
                      values[item + "Comment(en)" + id]
                    degree.Comments["CommentAR"] =
                      values[item + "Comment(Ar)" + id]
                    degree.Comments["CorrectiveEN"] =
                      values[item + "Corrective(en)" + id]
                    degree.Comments["CorrectiveAR"] =
                      values[item + "Corrective(Ar)" + id]
                    degree.Comments["Note"] = values[item + "Note" + id]
                    degree["QODID"] = values[item + "QODID" + id]
                      ? values[item + "QODID" + id]
                      : "0"
                    degrees.push(degree)
                  })

                  values["transferTo" + id].map((transferitem, key) => {
                    let transfer = { Value: transferitem }
                    // transfer.Value = values["transferTo" + id][key]
                    editedQuestion &&
                      (transfer.QODID = values[
                        transferitem + "transferQODID" + id
                      ]
                        ? values[transferitem + "transferQODID" + id]
                        : "0")
                    transfers.push(transfer)
                  })
                  formData.Answers.push({
                    Value: values["Answer" + id],
                    Degree: degrees,
                    Transfer: transfers,
                    ImagesRequired: values["Imagerequired" + "-" + id],
                    Active: values["activeAnswer" + id],
                    QOID: values["QOID" + id] ? values["QOID" + id] : "0",
                  })
                })
              }
              // Number & Date
              if (inputType === "number" || inputType === "date") {
                if (Operator === "Between") {
                  answerIds.forEach(id => {
                    let answer = `F${values[`from${id}`]}|T${values[`to${id}`]}`
                    formData.Answers.push({
                      Value: answer,
                      Hint: values[`AnswerHint${id}`],
                      Default: values[`default${id}`],
                    })
                  })
                } else if (inputType == "MultibleSelection") {
                  answerIds.forEach(id => {
                    let answer = [
                      {
                        label: values["Answer" + id],
                        options: [{ value: values["Option" + id] }],
                      },
                    ]
                    formData.Answers.push({
                      Value: answer,
                      Hint: values[`AnswerHint${id}`],
                      Default: values[`default${id}`],
                    })
                  })
                } else {
                  answerIds.forEach(id => {
                    let answer = ` ${values[`Operator${id}`]}${
                      values[`Answer${id}`]
                    } `
                    formData.Answers.push({
                      Value: answer,
                      Hint: values[`AnswerHint${id}`],
                      Default: values[`default${id}`],
                    })
                  })
                }
              }
              // Text List
              else if (inputType == "textlist") {
                formData.Answers.push({
                  MaxPoints: values["MaxNumber"],
                  NumOfPoints: values["NumOfPoints"],
                  NumOfPointsOptional: values["textlistOptional"],
                  CanAddMore: values["CanAddMore"],
                })
              } else if (inputType == "unitsList") {
                formData.Answers.push({
                  CanAddMoreUnits: values["CanAddMoreUnits"],
                  MaxNumberUnits: values["MaxNumberUnits"],
                  otherUnits: values["otherUnits"],
                })
                answerIds.forEach(id => {
                  formData.Answers.push({
                    Value: values["Answer" + id],
                    units: values["units" + id],
                    unitsMissing: values[`unitsMissing`+id],
                  })
                })
              } else {
                answerIds.forEach(id => {
                  formData.Answers.push({
                    Value: values["Answer" + id],
                    Hint: values[`AnswerHint${id}`],
                    Default: values[`default${id}`],
                  })
                })
              }

              if (inputType == "checkbox") {
                formData.Answers.push({ MaxChosen: values["MaxChosen"] })
              }

              let OperantsArray = []
              let OperatorsArray = []

              childQestionsID.forEach((id, index) => {
                formData.ConditionalAnswers.push({
                  QID: values["ParentQuestion" + id],
                  PAIDS: values["ParentAID" + id],
                  AnswerOperation: values["AnswerOperation" + id],
                })

                OperantsArray.push(values["ParentQuestion" + id])
                id != "1" &&
                  OperatorsArray.push(values["QuestionOperation" + id])
              })
              formData.ConditionalQuestions = {
                Operants: OperantsArray,
                Operators: OperatorsArray,
              }
              console.log("FormData", formData)
              axios
                .post(
                  "https://test.rdapp.net/api/AddRD0Question",
                  formData
                )
                .then(res => {
                  if (
                    res.data.error === true &&
                    res.data.message === "Access denied!"
                  ) {
                    setAuth(true)
                    setTimeout(() => {
                      history.push("/logout")
                      setTimeout(() => {
                        history.push("/login")
                      }, 1000)
                    }, 4000)
                  }
                  toggleChange()

                  // window.location.reload(false)
                })
                .catch(error => {
                  console.log(error)
                })
              onClose()
            }
          }}
        >
          {props => (
            <Form className="ms-1" onSubmit={props.handleSubmit}>
              {/* question Level */}
              <QuestionLevel
                ischild={ischild}
                props={props}
                setischild={setischild}
                childQestionsID={childQestionsID}
                setChildQuestionsID={setChildQuestionsID}
                setLevel={setLevel}
                sectionId={sectionId}
                setAuth={setAuth}
              />

              {/* Question Title & Other Inputs*/}
              <QuestionTitleAOtherInputs props={props} inputType={inputType} />

              {/* Type of Answer */}
              <QuestionType
                props={props}
                setAnswerIds={setAnswerIds}
                clickForm={clickForm}
              />

              {/* Answer question  */}
              <AnswerCreation
                props={props}
                inputType={inputType}
                answerIds={answerIds}
                isInput={isInput}
                setInput={setInput}
                setOperator={setOperator}
                Operator={Operator}
                editingDegreeList={editingDegreeList}
                transferList={transferList}
                setAnswerIds={setAnswerIds}
                groupedOptions={groupedOptions}
              />

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <Button
                  onClick={() => {
                    onClose()
                  }}
                  className="bg-primary"
                >
                  close
                </Button>
                <Button type="submit" className="bg-primary">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default CreateQuestion
