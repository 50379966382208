import { Hidden } from "@material-ui/core"
import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import ImageFullOptions from "common/ImageFullOptions"
import TicketComments from "common/TicketComments"
import UpdatesTicket from "./UpdatesTicket"
import ProjectInfo from "./ProjectInfo"
import InspectionStages from "./InspectionStages"

function ViewTicketDetails({
  ticketControlled,
  setAuth,
  transferToOptions,
  degreeList,
}) {
  // image
  const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }
  // Project Info
  const [projectInfo, setProjectInfo] = useState(false)
  const toggleProjectInfo = () => {
    setProjectInfo(!projectInfo)
  }

  // Inspection Stages
  const [inspectionStage, setInspectionStage] = useState(false)
  const toggleInspectionStage = () => {
    setInspectionStage(!inspectionStage)
  }
  return (
    <>
      <div>
        <div className="d-flex">
          <h5>Description:</h5>
          <Col className="ms-auto mb-2" sm={6}>
            <Button onClick={toggleProjectInfo} className="me-2 bg-primary">
              Project Info
            </Button>
            <Button onClick={toggleInspectionStage} className="bg-primary">
              Inspection Stages
            </Button>
          </Col>
        </div>
        <div className="d-flex">
          <p
            style={{
              width: "70%",
              border: "1px solid lightgray",
              borderRadius: "5px",
              padding: "5px",
              overflowX: "scroll",
              marginLeft: "100px",
            }}
          >
            {ticketControlled.Description || "Not Available"}
          </p>
        </div>
      </div>
      <Row className="mt-3">
        <Col sm={4}>
          <Input
            type="select"
            name="degree"
            id="degree"
            disabled={ticketControlled.DegreeID || ticketControlled.TransferTo}
            value={ticketControlled.DegreeID && ticketControlled.DegreeID}
          >
            <option value="">Select Degree ...</option>

            {degreeList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </Col>

        <Col sm={4}>
          <Input
            type="select"
            name="transferTo"
            id="transferTo"
            disabled={
              ticketControlled.DegreeID || ticketControlled.TransferTo
              // ||
              // !updatePermission(ticketControlled)
            }
            value={ticketControlled.TransferTo && ticketControlled.TransferTo}
          >
            <option value="">Transfer To...</option>
            {transferToOptions.map((item, i) => (
              <option key={i} value={item.ResourceID}>
                {item.Name}
              </option>
            ))}
            <option value="200">RD0 Issuer</option>
            <option value="201">Inspection</option>
          </Input>
        </Col>
        {ticketControlled.Attached.length > 0 && (
          <Col
            sm={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Button onClick={() => toggleImageFullOptions()}>View Files</Button>
          </Col>
        )}
      </Row>

      <TicketComments
        disabled={"disabled"}
        degree={ticketControlled.DegreeID}
        CommentEN={ticketControlled.CommentEN}
        CommentAR={ticketControlled.CommentAR}
        CorrectiveEN={ticketControlled.CorrectiveEN}
        CorrectiveAR={ticketControlled.CorrectiveAR}
        value={ticketControlled.Attached}
      />

      {/* image preview */}
      {viewImageFullOptions && (
        <ImageFullOptions
          viewImageFullOptions={viewImageFullOptions}
          toggleImageFullOptions={toggleImageFullOptions}
          name={`Attached`}
          target={"Image"}
          setAuth={setAuth}
          value={ticketControlled.Attached}
          StageID={10}
          type={"view"}
        />
      )}

      {/* Project Info */}
      <ProjectInfo
        projectInfo={projectInfo}
        toggleProjectInfo={toggleProjectInfo}
        data={ticketControlled.Project_info}
      />
      {/* Inspection Stages */}
      <InspectionStages
        inspectionStage={inspectionStage}
        toggleInspectionStage={toggleInspectionStage}
        data={ticketControlled.Ins_Stages}
      />
    </>
  )
}

export default ViewTicketDetails
