import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const UpdateVisits = ({
  updateVisitsToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    updateVisitsToggle()
  }
  const onClose = () => {
    updateVisitsToggle()
  }

  const [visitsNo, setVisitsNo] = useState(selectedItem.VCount)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QuotationID: selectedItem.PQuotationID,
      VCount: visitsNo,
    }
    if (visitsNo && visitsNo >= 0) {
      axios
        .post("https://test.rdapp.net/api/UpdateVCount.php", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("res", res)
          setEditing(!editing)
        })
        .catch(error => {
          console.log(error)
        })
      onClose()
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Update Visits No
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-2 text-center">
          <Form onSubmit={submitHandler}>
            <Row className="my-3">
              <Col
                sm={3}
                className="d-flex justify-content-center  align-items-center"
              >
                <Label>Visits No</Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  value={visitsNo}
                  onChange={e => {
                    setVisitsNo(e.target.value)
                  }}
                />
              </Col>
              <Col className="d-flex align-items-center">
                {!visitsNo || visitsNo < 0 ? (
                  <p className="error px-1 w-100 my-auto">
                    {visitsNo < 0 ? "invalid" : "required"}
                  </p>
                ) : null}
              </Col>
            </Row>

            <FormGroup className="d-flex ms-auto col-4 mt-4 justify-content-between">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                close
              </Button>
              <Button
                type="submit"
                className="bg-primary"
                disabled={
                  userPermissions.R13.P !== "3" && userPermissions.R13.P !== "4"
                }
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default UpdateVisits
