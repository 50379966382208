import React from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import * as yup from "yup"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const SendQuotation = ({
  open,
  clientData,
  setOpen,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  const history = useHistory()

  // Form control
  const toggle = () => setOpen(!open)
  const onClose = () => setOpen(false)

  // Permissions
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // Validation Schema
  const sendingSchema = yup.object().shape({
    conEmailCheck: yup.boolean(),
    conEmail: yup.string().when("conEmailCheck", {
      is: true,
      then: yup.string().email("Invalid email").required("Required"),
    }),

    othEmailCheck: yup.boolean(),
    othEmail: yup.string().when("othEmailCheck", {
      is: true,
      then: yup.string().email("Invalid email"),
    }),

    conPhoneCheck: yup.boolean(),
    conPhone: yup.string().when("conPhoneCheck", {
      is: true,
      then: yup.string().required("Required"),
    }),

    ownPhoneCheck: yup.boolean(),
    ownPhone: yup.string().when("ownPhoneCheck", {
      is: true,
      then: yup.string().required("Required"),
    }),

    othPhoneCheck: yup.boolean(),
    othPhone: yup.string().when("othPhoneCheck", {
      is: true,
      then: yup
        .string()
        .matches(/^\d+$/, "Must be only numbers")
        .required("Required"),
    }),
  })

  // Submit handler
  const submitHandler = values => {
    console.log("values=======>", values)

    // Create an object to hold the data
    const authi = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    const dataSend = {
      RD6AddID: clientData.ADDID,
      MailArray: [],
      PhoneArray: [],
    }

    // Conditionally add emails and phones to arrays
    if (values.conEmailCheck) dataSend.MailArray.push(values.conEmail)
    if (values.othEmailCheck) dataSend.MailArray.push(values.othEmail)
    if (values.ownEmailCheck) dataSend.MailArray.push(values.ownEmail)

    if (values.conPhoneCheck) dataSend.PhoneArray.push(values.conPhone)
    if (values.ownPhoneCheck) dataSend.PhoneArray.push(values.ownPhone)
    if (values.othPhoneCheck) dataSend.PhoneArray.push(values.othPhone)

    // Log the data to be sent
    dataSend.MailArray.join(",")
    dataSend.PhoneArray.join(",")

   
    

    // // Make the API request
    axios
      .post("https://test.rdapp.net/api/RD6/AddendumAction.php", {
        ...authi,
        MailArray: dataSend.MailArray.join(","),
        PhoneArray: dataSend.PhoneArray.join(","),
        Action: "Send",
        Ref: clientData.ReferenceNo,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        console.log("Response:", res)
        // Optionally handle other responses or state updates here
      })
      .catch(error => {
        console.log("Error:", error)
      })

    onClose()
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clientData.link).catch(err => {
      console.error("Failed to copy text: ", err)
    })
  }

  return (
    <Modal isOpen={open} toggle={toggle} className="modal-md">
      <ModalHeader>
        Send quotation to client{" "}
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              conEmail: clientData.conEmail || "",
              conEmailCheck: false,
              conPhone: clientData.conPhone || "",
              conPhoneCheck: false,
              ownEmail: clientData.ownEmail || "",
              ownEmailCheck: false,
              ownPhone: clientData.ownPhone || "",
              ownPhoneCheck: false,
              othEmail: "",
              othEmailCheck: false,
              othPhone: "",
              othPhoneCheck: false,
            }}
            validationSchema={sendingSchema}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                {/* Contractor Email */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      name="conEmailCheck"
                      id="conEmailCheck"
                      // checked={props.values.conEmailCheck}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="conEmailCheck" className="my-auto mx-2">
                      Contractor Email
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="email"
                      name="conEmail"
                      id="conEmail"
                      placeholder="Contractor email"
                      value={props.values.conEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled
                    />
                  </Col>
                </Row>
                {props.touched.conEmail && props.errors.conEmail ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.conEmail}
                  </p>
                ) : null}

                {/* Contractor Phone */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      name="conPhoneCheck"
                      id="conPhoneCheck"
                      // checked={props.values.conPhoneCheck}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="conPhoneCheck" className="my-auto mx-2">
                      Contractor Phone
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="tel"
                      name="conPhone"
                      id="conPhone"
                      placeholder="Contractor phone"
                      value={props.values.conPhone}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled
                    />
                  </Col>
                </Row>
                {props.touched.conPhone && props.errors.conPhone ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.conPhone}
                  </p>
                ) : null}

                {/* Owner Email */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      // checked={props.values.ownEmailCheck}
                      name="ownEmailCheck"
                      id="ownEmailCheck"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="ownEmailCheck" className="my-auto mx-2">
                      Owner Email
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="email"
                      name="ownEmail"
                      id="ownEmail"
                      placeholder="Owner email"
                      value={props.values.ownEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled
                    />
                  </Col>
                </Row>
                {props.touched.ownEmail && props.errors.ownEmail ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.ownEmail}
                  </p>
                ) : null}

                {/* Owner Phone */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      // checked={props.values.ownPhoneCheck}
                      name="ownPhoneCheck"
                      id="ownPhoneCheck"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="ownPhoneCheck" className="my-auto mx-2">
                      Owner Phone
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="tel"
                      name="ownPhone"
                      id="ownPhone"
                      placeholder="Owner phone"
                      value={props.values.ownPhone}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled
                    />
                  </Col>
                </Row>
                {props.touched.ownPhone && props.errors.ownPhone ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.ownPhone}
                  </p>
                ) : null}

                {/* Other Email */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      // checked={props.values.othEmailCheck}
                      name="othEmailCheck"
                      id="othEmailCheck"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="othEmailCheck" className="my-auto mx-2">
                      Other Email
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="email"
                      name="othEmail"
                      id="othEmail"
                      placeholder="Other email"
                      value={props.values.othEmail}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={!props.values.othEmailCheck}
                    />
                  </Col>
                </Row>
                {props.touched.othEmail && props.errors.othEmail ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.othEmail}
                  </p>
                ) : null}

                {/* Other Phone */}
                <Row className="mt-3">
                  <Col
                    sm={5}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Input
                      type="checkbox"
                      className="my-auto"
                      // checked={props.values.othPhoneCheck}
                      name="othPhoneCheck"
                      id="othPhoneCheck"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <Label htmlFor="othPhoneCheck" className="my-auto mx-2">
                      Other Phone
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      type="tel"
                      name="othPhone"
                      id="othPhone"
                      placeholder="Other phone"
                      value={props.values.othPhone}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={!props.values.othPhoneCheck}
                    />
                  </Col>
                </Row>
                {props.touched.othPhone && props.errors.othPhone ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.othPhone}
                  </p>
                ) : null}

                <FormGroup className="d-flex ms-auto mt-5 justify-content-between">
                  <Button onClick={copyToClipboard} className="btn btn-primary">
                    Copy RD6 Addendum
                  </Button>
                  <div className="col-4 d-flex ms-auto justify-content-around">
                    <Button className="bg-primary" onClick={onClose}>
                      Close
                    </Button>
                    <Button
                      className="bg-primary"
                      type="submit"
                      disabled={
                        userPermissions.R14.P !== "3" &&
                        userPermissions.R14.P !== "4"
                      }
                    >
                      Send
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default SendQuotation
