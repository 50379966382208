import React, { useEffect, useState } from "react"
import { Label, Input, Col, FormGroup } from "reactstrap"

const QuestionHint = props => {
  const [Toggle, setToggle] = useState(true)
  const addToggleToggle = () => {
    setToggle(!Toggle)
  }
  return (
    <FormGroup className="d-flex row mb-0 mt-1" style={{ paddiing: "3px" }}>
      <Col sm={2}>
        <Label className=" mx-2  mt-1" for={props.name}>
          {props.name} :
        </Label>
      </Col>
      <Col sm={4}>
        <Input
          className="form-control p-1"
          id={props.name}
          name={props.name}
          placeholder={`Write ${props.name} text here!`}
          value={props.props.values[props.name]}
          type="Text"
          disabled={Toggle}
          onChange={props.props.handleChange}
          onBlur={props.props.handleBlur}
        />
      </Col>
      <Col>
        <Label for={props.name} className="ms-1">
          <Input
            onClick={() => {
              addToggleToggle()
            }}
            type="checkbox"
            id={props.name}
            className="me-1"
          />
          {props.props.values[props.name]
            ? "Edit " + props.name
            : "Add " + props.name}
        </Label>
      </Col>
    </FormGroup>
  )
}

export default QuestionHint
