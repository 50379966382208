import React from "react"
import { Input } from "reactstrap"

function FileAnswer(props) {
  // console.log("ssssssss", props.values["Answer" + props.id])
  return (
    <div>
      <Input
        id="file"
        name={`Answer${props.id}`}
        value={props.props.values["Answer" + props.id]}
        type="select"
        onChange={props.props.handleChange}
        onBlur={props.props.handleBlur}
        sm={10}
        className="mx-3 mb-1 text-center text-color-primary form-control"
      >
        <option value={null} selected>
          Choose File Type
        </option>
        <option value={"word"}>{"Word"}</option>
        <option value={"excel"}>{"Excel"}</option>
        <option value={"pdf"}>{"PDF"}</option>
        <option value={"single"}>{"Single-Img"}</option>
        <option value={"multi"}>{"Multi-Img"}</option>
      </Input>
    </div>
  )
}

export default FileAnswer
