import React from "react"
import Select from "react-select"
import { FormGroup } from "reactstrap"

function ApprovedMultiselect({
  options,
  title,
  values,
  setFieldValue,
  errors,
  name,
  defaultValue,
}) {

  console.log();
  const getValue = (Options, initialValue) => {
    if (Options) {
      return Options.filter(
        option => initialValue && initialValue.indexOf(option.value) >= 0
      )
    } else {
      return props.isMulti ? [] : ""
    }
  }
  return (
    <FormGroup className="d-flex col-12 align-items-center">
      <label htmlFor="user-role" className="col-3 me-1">
        {title} :
      </label>
      <Select
        options={options}
        id={name}
        isMulti={true}
        name={name}
        className="basic-multi-select"
        value={getValue(options, values[name])}
        onChange={e => {
          setFieldValue(name, e ? e.map(item => item.value) : [])
        }}
        classNamePrefix="select"
        placeholder="select user role ..."
      />
      {errors[name] ? (
        <div className="error ms-2 mb-1 ">{errors[name]}</div>
      ) : null}
    </FormGroup>
  )
}

export default ApprovedMultiselect
