import axios from "axios"
import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const Warning = ({
    warning,
    toggleWarning,
    massege
}) => {

  return (
    /**************delete pop up***************/
    <Modal isOpen={warning} toggle={toggleWarning}>
      <ModalBody>
        <div className="modal-content">
            <ModalHeader>Warning !!<button
              onClick={toggleWarning}
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button></ModalHeader>
          <ModalBody className="px-4 py-5 text-center">
            
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-danger bg-opacity-10  rounded-3"><i className="mdi mdi-alert font-size-20"></i></div>
            </div>
            <p className="text-muted font-size-16 mb-4">{massege}</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                onClick={() => {
                    toggleWarning()
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
           
            </div>
          </ModalBody>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Warning
