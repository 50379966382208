export function EditQuestionhelper(questionscollect, questions) {
  let degreeAnswerTypeArray = [
    "InsChecklist",
    "Observation",
    "Ticket",
    "UserSelect",
  ]
  let fourAnswer = ["Yes", "No", "NA"]
  // console.log("questionsaaaaaaaa", questionscollect)
  function settingKey(answer) {
    switch (answer) {
      case "Yes":
        return "1"
      case "No":
        return "2"
      case "NA":
        return "3"
      default:
        return "4"
    }
  }

  function multiSelectDegree(OptionData,returnValue) {
    let degreevalues = []
    let degreeQODID = []

    OptionData.forEach(OptionData =>
    
     ( degreevalues.push(OptionData.DegreeID),
      degreeQODID.push(OptionData.QODID))
    )
    
    return   returnValue == "QODID"?degreeQODID:degreevalues
  }
  function commentMultiselectDegree(
    answer,
    questionscollect,
    mainkey,
    type,
    key
  ) {
    Object.values(answer.OptionData).forEach(OptionData => {
      let id
      type === "UserSelect" ? (id = settingKey(answer.Answer)) : (id = key + 1)
      return (
        (questionscollect[mainkey][OptionData.DegreeID + "Comment(en)" + id] =
          OptionData.CommentEN),
        (questionscollect[mainkey][OptionData.DegreeID + "QODID" + id] =
          OptionData.QODID),
        (questionscollect[mainkey][OptionData.DegreeID + "Comment(Ar)" + id] =
          OptionData.CommentAR),
        (questionscollect[mainkey][
          OptionData.DegreeID + "Corrective(en)" + id
        ] = OptionData.CorrectiveEN),
        (questionscollect[mainkey][
          OptionData.DegreeID + "Corrective(Ar)" + id
        ] = OptionData.CorrectiveAR),
        (questionscollect[mainkey][OptionData.DegreeID + "Note" + id] =
          OptionData.QData)
      )
    })
  }
  function TransferTo(transferTO, returnValue,key,mainkey,questionscollect) {
    let transfervalues = []
    let transferQODID = []

   transferTO.forEach(
      transfer => (
        returnValue !== "transfer"? (questionscollect[mainkey][transfer.TransferTo + "transferQODID" + (key+1)] =
        transfer.QODID):
        transfervalues.push(transfer.TransferTo)
        // transferQODID.push(transfer.QODID)
      )
    )
    return returnValue == "transfer" && transfervalues 
  }


  const convertObjectToArray = apiObject => {
    let array = []
    let initialArray = Object.entries(apiObject)
    initialArray.forEach(item => {
      array.push(item[1])
    })
    return array
  }
  questions.map((question, mainkey) => {
    //main loop (questions loop )
    // console.log("typeof question")

    if (typeof question === "object") {
      // answer loop
      question.Options.map((answer, key) => {
        //check degree`s questions
        if (!degreeAnswerTypeArray.includes(questionscollect[mainkey].Type)) {
          questionscollect[mainkey]["Answer" + (key + 1)] = answer.Answer
          questionscollect[mainkey]["QOID" + (key + 1)] = answer.QOID
          questionscollect[mainkey]["activeAnswer" + (key + 1)] =
            answer.Active === "1" ? true : false
          questionscollect[mainkey]["default" + (key + 1)] =
            answer.DefaultValue === "1" ? true : false
        } else {
          // if Inspection-Checklist
          if (questionscollect[mainkey].Type === "InsChecklist") {
            questionscollect[mainkey]["QOID" + settingKey(answer.Answer)] = answer.QOID
            questionscollect[mainkey]["activeAnswer" + settingKey(answer.Answer)] =
              answer.Active === "1" ? true : false
            // console.log(" settingKey(answer.Answer)", settingKey(answer.Answer))
            fourAnswer.includes(answer.Answer)
              ? (questionscollect[mainkey][
                  "Answer" + settingKey(answer.Answer)
                ] = answer.Answer)
              : (
                (questionscollect[mainkey]["other"] = answer.Answer)
                // (questionscollect[mainkey]["Answer" + "4"] = answer.Answer)
                )

            questionscollect[mainkey]["degree" + settingKey(answer.Answer)] =
              answer.OptionData[0].DegreeID
            questionscollect[mainkey]["QODID" + settingKey(answer.Answer)] =
              answer.OptionData[0].QODID

            questionscollect[mainkey][
              answer.OptionData[0].DegreeID +
                "Comment(en)" +
                settingKey(answer.Answer)
            ] = answer.OptionData[0].CommentEN

            questionscollect[mainkey][
              answer.OptionData[0].DegreeID +
                "Comment(Ar)" +
                settingKey(answer.Answer)
            ] = answer.OptionData[0].CommentAR

            questionscollect[mainkey][
              answer.OptionData[0].DegreeID +
                "Corrective(en)" +
                settingKey(answer.Answer)
            ] = answer.OptionData[0].CorrectiveEN

            questionscollect[mainkey][
              answer.OptionData[0].DegreeID +
                "Corrective(Ar)" +
                settingKey(answer.Answer)
            ] = answer.OptionData[0].CorrectiveAR

            questionscollect[mainkey][
              answer.OptionData[0].DegreeID +
                "Note" +
                settingKey(answer.Answer)
            ] = answer.OptionData[0].QData
            questionscollect[mainkey]["Imagerequired-" + settingKey(answer.Answer)] =
              answer.ImagesCounter
            // if User-Select
          } else if (questionscollect[mainkey].Type === "UserSelect") {
            questionscollect[mainkey]["QOID" + settingKey(answer.Answer)] = answer.QOID
            questionscollect[mainkey]["activeAnswer" + settingKey(answer.Answer)] =
              answer.Active === "1" ? true : false
            fourAnswer.includes(answer.Answer)
              ? (questionscollect[mainkey][
                  "Answer" + settingKey(answer.Answer)
                ] = answer.Answer)
              : (questionscollect[mainkey]["other"] = answer.Answer)
            //for multiSelectDegree
            questionscollect[mainkey]["degree" + settingKey(answer.Answer)] =
              multiSelectDegree(Object.values(answer.OptionData))
            questionscollect[mainkey]["Imagerequired-" + settingKey(answer.Answer)] =
              answer.ImagesCounter

            commentMultiselectDegree(
              answer,
              questionscollect,
              mainkey,
              questionscollect[mainkey].Type
            )
          } else if (questionscollect[mainkey].Type === "Observation") {
            questionscollect[mainkey]["QOID" + (key + 1)] = answer.QOID
            questionscollect[mainkey]["activeAnswer" + (key + 1)] =
              answer.Active === "1" ? true : false
            questionscollect[mainkey]["Answer" + (key + 1)] = answer.Answer
            questionscollect[mainkey]["degree" + (key + 1)] =
              multiSelectDegree(Object.values(answer.OptionData))
            questionscollect[mainkey]["Imagerequired-" + (key + 1)] =
              answer.ImagesCounter
            commentMultiselectDegree(
              answer,
              questionscollect,
              mainkey,
              questionscollect[mainkey].Type,
              key
            )
            // TransferTo(
            //   answer,
            //   questionscollect,
            //   mainkey,
            //   questionscollect[mainkey].Type,
            //   key
            // )
            questionscollect[mainkey]["transferTo" + (key + 1)] = TransferTo(
              Array.isArray(answer.TransferTo)? answer.TransferTo:Object.values( answer.TransferTo),
              "transfer"
            )
            // questionscollect[mainkey]["transferQODID" + (key + 1)] = TransferTo(
            //   Array.isArray(answer.TransferTo[0])? answer.TransferTo:Object.values( answer.TransferTo),
            //   "QODID"
            // )
            TransferTo(
              Object.values(answer.TransferTo),
              "QODID",key,mainkey ,questionscollect
              )
            // questionscollect[mainkey]["TransferQODID" + (key + 1)] = [
            //   answer.TransferTo[key + 1].QODID,
            // ]
          } else if (questionscollect[mainkey].Type === "Ticket") {
            console.log(" answer.OptionData.DegreeID", answer.OptionData);
            questionscollect[mainkey]["QOID" + (key + 1)] = answer.QOID
            questionscollect[mainkey]["activeAnswer" + (key + 1)] =
              answer.Active === "1" ? true : false
            questionscollect[mainkey]["degree" + (key + 1)] =
            multiSelectDegree( Array.isArray(answer.OptionData)?answer.OptionData:Object.values(answer.OptionData))
            questionscollect[mainkey]["QODID" + (key + 1)] =
            multiSelectDegree(Array.isArray(answer.OptionData)?answer.OptionData:Object.values(answer.OptionData),"QODID")
            questionscollect[mainkey]["Imagerequired" + "-" + "1"] =
              answer.ImagesCounter

              questionscollect[mainkey]["transferTo" + (key + 1)] = TransferTo(
               Object.values(answer.TransferTo) ,
                "transfer"
              )
              // questionscollect[mainkey]["transferQODID" + (key + 1)] = TransferTo(
              //   Object.values(answer.TransferTo),
              //   "QODID"
              // )
              TransferTo(
                Object.values(answer.TransferTo),
                "QODID",key,mainkey ,questionscollect
                )

            // questionscollect[mainkey]["transferTo" + (key + 1)] = [
            //   answer.TransferTo[key + 1].TransferTo,
            // ]
            // questionscollect[mainkey]["transferToQODID" + (key + 1)] = [
            //   answer.TransferTo[key + 1].QODID,
            // ]
          }
        }
      })
    }
  })
}
