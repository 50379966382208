import axios from "axios"

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Table } from "reactstrap"

const FinancialTable = ({ ProjectID, setAuth }) => {
  const history = useHistory()

  const [financialList, setFinancialList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }
    axios
      .post("https://test.rdapp.net/api/PPaymentpreview.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log("res=================", res.data)
          let array = Object.values(res.data)
          array.splice(-1)
          // console.log("array =================", array)
          setFinancialList(array)
        }
      })
      .catch(err => console.log("error------>", err))
  }, [])

  return (
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
        <thead>
          <tr>
            <th scope="col" style={{ width: "20px" }}>
              #
            </th>
            <th scope="col">Type</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Payment Date</th>
          </tr>
        </thead>

        {/***********table body *****************/}
        <tbody>
          {financialList.map((item, i) => (
            <tr key={i}>
              <td>
                <input
                  // value={item.Code}
                  type="checkbox"
                  onClick={e => {
                    // console.log("---------->", e.target.value)
                    // selectedTicketsHandler(e.target.value)
                  }}
                  className="form-check-input  p-1 border-primary"
                />
              </td>
              <td>{item.Type === "AV" ? "Additional Visit" : item.Type}</td>
              <td>
                <span
                  className={
                    item.Paid === "1"
                      ? "badge bg-success"
                      : item.Paid === "0"
                      ? "badge bg-danger"
                      : ""
                  }
                >
                  {item.Paid === "1"
                    ? "Paid"
                    : item.Paid === "0"
                    ? "Not Paid"
                    : ""}
                </span>
              </td>
              <td>{item.PaymentDate || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default FinancialTable
