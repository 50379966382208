import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup, Row } from "reactstrap"
import ParentAChild from "./ParentAChild"

function QuestionLevel({
  props,
  setischild,
  ischild,
  childQestionsID,
  setChildQuestionsID,
  setLevel,
  sectionId,
  setAuth
}) {


  return (
    <>
      <FormGroup className="d-flex">
        <h6 className="col-3">Level :</h6>

        <FormGroup className="col-3" check>
          <Input
            type="radio"
            name="Level"
            className="Form-control"
            id="level"
            value="Parent"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            onClick={() => {
              setischild(false)
              setLevel("Parent")
            }}
            checked={!ischild}
          />
          <Label for="Parent">Parent</Label>
        </FormGroup>

        <FormGroup className="col-3">
          <Input
            type="radio"
            name="Level"
            className="Form-control"
            id="child"
            value="Child"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            onClick={() => {
              setischild(true)
              setLevel("Child")
            }}
            checked={ischild}
          />

          <Label for="child" className="ms-1">
            Child
          </Label>
        </FormGroup>
        {props.touched.Level && props.errors.Level ? (
          <div className="error me-5">{props.errors.Level}</div>
        ) : null}
      </FormGroup>

      {/* IF Child */}
      {childQestionsID.map((childID, index) => (
        <Row key={index}>
          <ParentAChild
            ischild={ischild}
            props={props}
            sectionid={sectionId}
            setAuth={setAuth}
            setChildQuestionsID={setChildQuestionsID}
            childQestionsID={childQestionsID}
            inputType={""}
            id={childID}
            defaultvalue={"false"}
          />
          <hr />
        </Row>
      ))}
    </>
  )
}

export default QuestionLevel
