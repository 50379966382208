import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Input,
  DropdownToggle,
  Modal,
  ModalBody,
  Button,
  FormGroup,
} from "reactstrap"
import ProjectOverview from "./project-overview"
import DeleteProject from "./DeleteProject"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import PaginationComponent from "common/PaginationComponent"
import RelatedProjects from "./RelatedProjects"

// import "./projectDetails.scss"
import FilterProjects from "./FilterProjects"
import EditProjectData from "./EditProjectData/EditProjectData"
import {
  faHome,
  faHotel,
  faBuilding,
  faStore,
  faSkating,
  faSchool,
  faMosque,
  faRunning,
  faHospital,
  faHospitalAlt,
  faBroadcastTower,
} from "@fortawesome/free-solid-svg-icons"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ConfirmingAction from "common/ConfirmingAction"
import MissingData from "./MissingData"
import { usePrevious } from "common/CustomHooks"

const ProjectsList = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)
  //meta title
  document.title = "Project List | DomApp - React Admin & Dashboard Template"

  const history = useHistory()

  const userid = localStorage.getItem("id")
  const role = localStorage.getItem("role")

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  //--------------------pop up-------------------//
  const [popup, setPopup] = useState(false)
  const togglePopup = () => {
    setPopup(!popup)
  }
  const [rowData, setRowData] = useState({})
  //--------------- SPd -------------//
  const [confmodal, setConfmodal] = useState(false)
  const conftoggle = () => {
    setConfmodal(!confmodal)
  }
  const [confirm, setConfirm] = useState(false)
  const OverView = item => {
    if (
      (item.Paid == 0 && item.ProjectType == "FT") ||
      item["RD0Status"] == "OutOfRD0"
    ) {
      conftoggle()
      togglePopup()
      setRowData(item)
    } else {
      togglePopup()
      setRowData(item)
    }
  }
  const [projectListData, setProjectListData] = useState([])

  const [projectID, setProjectID] = useState()
  const [SPorMStype, setTypeSPorMS] = useState()

  // **********SP Action**********
  const [SPModel, setSPModel] = useState(false)
  const SPtoggle = () => {
    setSPModel(!SPModel)
  }
  // **********SP Action**********
  const [MSModel, setMSModel] = useState(false)
  const MStoggle = () => {
    setMSModel(!MSModel)
  }

  const SP_MSFunction = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Type: SPorMStype,
      ProjectID: projectID,
    }

    axios
      .post("https://test.rdapp.net/api/Classification.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
      })
      .catch(error => {
        console.log(error)
      })
    SPorMStype === "SP" ? SPtoggle() : MStoggle()
  }
  // missing Data
  const [missingDataModal, setMissingDataModal] = useState(false)
  const toggleMissingData = () => {
    setMissingDataModal(!missingDataModal)
  }

  //---------Delete------------------//
  const [dpopup, setdPopup] = useState(false)

  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const DeleteFun = item => {
    toggledPopup()
    setRowData(item)
  }

  const changePaidStatus = item => {
    let body = {
      TargetTable: "Projects",
      Paid: 1,
      ProjectID: item.ProjectID,
      UserID: userid,
    }
    axios
      .post("https://test.rdapp.net/api/UpdateHistory.php", body)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        window.location.reload(false)
      })
      .catch(err => console.log(err))
  }

  const [checkedRows, setCheckedRows] = useState([])
  const checkbox = item => {
    setCheckedRows([item, ...checkedRows])
  }

  //******************* Filter Section ********************//
  const [categories, setCategories] = useState([])
  const [stages, setStages] = useState([])

  const initialFilteredValues = {
    Type: "",
    ReferenceNo: "",
    Date: "",
    Category: "",
    Payment: "",
    RD0Status: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  //******************* Edit project details Section ********************//
  const [project, setProject] = useState()
  const [editing, setEditing] = useState(false)

  const [editModal, setEditModal] = useState(false)
  // console.log("editModal ==========>", editModal)

  const editToggle = () => {
    setEditModal(!editModal)
  }

  // ************************************************************ //


const prevFilteredValues = usePrevious(filteredValues);

  useEffect(() => {  

    const BCID =
      categories.filter(
        category => category.Name === filteredValues.Category
      )[0]?.BCID || ""

    const lastStageID =
      stages.filter(stage => stage.StageName === filteredValues.Status)[0]
        ?.StageID || ""

    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Ref: filteredValues.ReferenceNo,
      SP: filteredValues.SP,
      MS: filteredValues.MS,
      CID: BCID,
      Paid: filteredValues.Payment,
      Type: filteredValues.Type,
      StartDate: filteredValues.Date,
      Page: page,
      RD0Status: filteredValues.RD0Status,
    }

    axios
      .post("https://test.rdapp.net/api/ProjectsView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        setTotalPages(res.data.TotalPages)
        array.splice(-4)
        setProjectListData(array)
        // console.log("array", array)
      })
      .catch(error => {
        console.log(error)
      })
    // }
  }, [page, filteredValues, editing])

  const tableHeaders = [
    "Type",
    "Reference No.",
    "Date",
    "Category",
    "Payment",
    "Status",
    "SP",
    "MS",
  ]

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Projects List" />

          <Row>
            <Col lg="12">
              <FilterProjects
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                categories={categories}
                setCategories={setCategories}
                stages={stages}
                setStages={setStages}
                setAuth={setAuth}
              />

              {/******************* table section**************************/}
              <Table className="project-list-table table-nowrap align-middle table-borderless my-table">
                <thead>
                  <tr style={{ backgroundColor: "rgb(230, 239, 252)" }}>
                    <th scope="col" style={{ width: "20px" }}>
                      #
                    </th>
                    <th scope="col">Category</th>
                    <th scope="col">Reference No.</th>
                    <th scope="col">Date</th>
                    <th scope="col">Type</th>
                    <th scope="col">payment</th>
                    <th scope="col">RD0 Status</th>
                    <th scope="col">InsStages</th>
                    <th scope="col">Classification</th>
                    <th scope="col">MS</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {/***********table body *****************/}
                <tbody>
                  {projectListData.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <input
                            type="checkbox"
                            onClick={() => {
                              checkbox(item[1])
                            }}
                            className="form-check-input  p-1 border-primary"
                          />
                        </td>

                        <td>
                          {item[1].Category === "Residential" ? (
                            <div
                              to="#"
                              onClick={e => {
                                e.preventDefault()
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faHome}
                                title="Residential"
                                id={item[1].Category}
                              />
                              <UncontrolledTooltip
                                placement="left"
                                isOpen={tooltipOpen}
                                target={item[1].Category}
                                toggle={toggle}
                              >
                                {item[1].Category}
                              </UncontrolledTooltip>
                            </div>
                          ) : item[1].Category === "Hotels" ? (
                            <div
                              to="#"
                              onClick={e => {
                                e.preventDefault()
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faHotel}
                                id={item[1].Category}
                              />
                              <UncontrolledTooltip
                                placement="left"
                                isOpen={tooltipOpen}
                                target={item[1].Category}
                                toggle={toggle}
                              >
                                {item[1].Category}
                              </UncontrolledTooltip>
                            </div>
                          ) : item[1].Category === "less than 23 meters" ? (
                            <FontAwesomeIcon icon={faBuilding} />
                          ) : item[1].Category === "Commercial Centers" ? (
                            <FontAwesomeIcon icon={faStore} />
                          ) : item[1].Category === "Entertainment Services" ? (
                            <FontAwesomeIcon icon={faSkating} />
                          ) : item[1].Category === "High Rise Towers" ? (
                            <FontAwesomeIcon icon={faBuilding} />
                          ) : item[1].Category === "High risk buildings" ? (
                            <FontAwesomeIcon icon={faBuilding} />
                          ) : item[1].Category === "Motels" ? (
                            <FontAwesomeIcon icon={faHotel} />
                          ) : item[1].Category === "Educational" ? (
                            <FontAwesomeIcon icon={faSchool} />
                          ) : item[1].Category ===
                            "Gathering buildings Mosques" ? (
                            <FontAwesomeIcon icon={faMosque} />
                          ) : item[1].Category ===
                            "Business Buildings (Airports, Banks, TV Stations Post Offices)" ? (
                            <FontAwesomeIcon icon={faBuilding} />
                          ) : item[1].Category ===
                            "Gathering buildings Sports Premises" ? (
                            <FontAwesomeIcon icon={faRunning} />
                          ) : //  item[1].Category === "Warehouses" ? (
                          //   <FontAwesomeIcon icon={faWarehouse} />
                          // ) :
                          item[1].Category === "Furnished Hotel Apartments" ? (
                            <FontAwesomeIcon icon={faHotel} />
                          ) : // : item[1].Category ===
                          //   "Gathering buildings Wedding Halls Cinemas Theaters" ? (
                          //   <FontAwesomeIcon icon={faWarehouseAlt} />
                          // )
                          item[1].Category === "Hospitals" ? (
                            <FontAwesomeIcon icon={faHospitalAlt} />
                          ) : item[1].Category === "Healthcare Centers" ? (
                            <FontAwesomeIcon icon={faHospital} />
                          ) : item[1].Category ===
                            "Telecommunication Towers" ? (
                            <FontAwesomeIcon icon={faBroadcastTower} />
                          ) : (
                            <FontAwesomeIcon icon={faBuilding} />
                          )}
                        </td>

                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              `project-details/${item[1].ProjectID}`,
                              {
                                ProjectID: item[1].ProjectID,
                              }
                            )
                          }
                        >
                          {item[1].ReferenceNo}
                        </td>
                        <td>{item[1].StartDate}</td>
                        <td>
                          <h5>{item[1].ProjectType}</h5>
                        </td>
                        <td>
                          <span
                            className={
                              item[1].Paid == "1"
                                ? "badge bg-success"
                                : "badge bg-danger"
                            }
                          >
                            {item[1].Paid == "1" ? "Paid" : "not Paid"}
                          </span>
                        </td>
                        <td
                          id={"RD0Status" + key}
                          onClick={() => {
                            setRowData(item[1])                            
                            if (item[1].RD0Status == "Missing Data") {
                              toggleMissingData()
                            } else undefined
                          }}
                        >
                          <span
                            className={
                              item[1].RD0Status == "Manual" ||
                              item[1].RD0Status == "OutOfRD0"
                                ? "badge bg-primary"
                                : item[1].RD0Status == "Approved"
                                ? "badge bg-success"
                                : item[1].RD0Status == "Rejected" ||
                                  item[1].RD0Status == "Missing Data"
                                ? "badge bg-danger"
                                : item[1].RD0Status == "Under Review"
                                ? "badge bg-warning"
                                : item[1].RD0Status == "Edit Required"
                                ? "badge bg-dark"
                                : "badge bg-info"
                            }
                            // onClick={
                            //   item[1].RD0Status == "Missing Data" &&
                            //   toggleMissingData
                            // }
                          >
                            {item[1].RD0Status?.slice(0, 15)}
                            {item[1].RD0Status?.length > 15 ? "..." : ""}
                          </span>
                          {/* <UncontrolledTooltip
                            placement="right"
                            target={"RD0Status" + key}
                          >
                            {item[1].RD0Status}
                          </UncontrolledTooltip> */}
                        </td>
                        <td id={"InsStages" + key}>
                          <span className={"badge bg-warning"}>
                            {item[1].InsStages?.slice(0, 15)}
                            {item[1].InsStages?.length > 15 ? "..." : ""}
                          </span>
                          {/* <UncontrolledTooltip
                            placement="right"
                            target={"InsStages" + key}
                          >
                            {item[1].InsStages}
                          </UncontrolledTooltip> */}
                        </td>
                        <td>
                          <h5> {item[1].SP === "1" ? "SP" : ""}</h5>
                        </td>
                        <td>
                          <h5> {item[1].MS === "1" ? "MS" : ""}</h5>
                        </td>
                        <td>
                          <UncontrolledDropdown className="btn-group bg-primary">
                            <button
                              onClick={() => OverView(item[1])}
                              className="btn btn-primary btn-sm dropdown-toggle"
                              disabled={
                                userPermissions.R7.P !== "3" &&
                                userPermissions.R7.P !== "4" &&
                                userPermissions.R3.P !== "2" &&
                                userPermissions.R3.P !== "3" &&
                                userPermissions.R3.P !== "4"
                              }
                            >
                              Overview
                            </button>
                            <DropdownToggle
                              tag="a"
                              to="#"
                              className="card-drop"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `project-details/${item[1].ProjectID}`,
                                    {
                                      ProjectID: item[1].ProjectID,
                                    }
                                  )
                                }
                              >
                                details
                              </DropdownItem>

                              {(userPermissions.R7.P === "3" ||
                                userPermissions.R7.P === "4") && (
                                <DropdownItem
                                  onClick={() => {
                                    setProject(item[1])
                                    editToggle()
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                              )}
                              {(userPermissions.R1.P === "1" ||
                                userPermissions.R1.P === "2" ||
                                userPermissions.R1.P === "3" ||
                                userPermissions.R1.P === "4" ||
                                userPermissions.R1.G === "1" ||
                                userPermissions.R2.P === "1" ||
                                userPermissions.R2.P === "2" ||
                                userPermissions.R2.P === "3" ||
                                userPermissions.R2.P === "4" ||
                                userPermissions.R2.G === "1" ||
                                userPermissions.R30.P === "1" ||
                                userPermissions.R30.P === "2" ||
                                userPermissions.R30.P === "3" ||
                                userPermissions.R30.P === "4" ||
                                userPermissions.R30.G === "1") && (
                                <DropdownItem
                                  onClick={() => {
                                    setProjectID(item[1].ProjectID)
                                    setTypeSPorMS("MS")
                                    MStoggle()
                                  }}
                                >
                                  Missing Stage
                                </DropdownItem>
                              )}
                              {(userPermissions.R1.P === "1" ||
                                userPermissions.R1.P === "2" ||
                                userPermissions.R1.P === "3" ||
                                userPermissions.R1.P === "4" ||
                                userPermissions.R1.G === "1" ||
                                userPermissions.R2.P === "1" ||
                                userPermissions.R2.P === "2" ||
                                userPermissions.R2.P === "3" ||
                                userPermissions.R2.P === "4" ||
                                userPermissions.R2.G === "1" ||
                                userPermissions.R29.P === "1" ||
                                userPermissions.R29.P === "2" ||
                                userPermissions.R29.P === "3" ||
                                userPermissions.R29.P === "4" ||
                                userPermissions.R29.G === "1") && (
                                <DropdownItem
                                  onClick={() => {
                                    setProjectID(item[1].ProjectID)
                                    setTypeSPorMS("SP")
                                    SPtoggle()
                                  }}
                                >
                                  Classification
                                </DropdownItem>
                              )}

                              {userPermissions.R7.P === "4" && (
                                <DropdownItem
                                  onClick={() => {
                                    DeleteFun(item[1])
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              )}

                              {item[1].Paid == "0" &&
                                (userPermissions.R13.P === "3" ||
                                  userPermissions.R13.P === "4") && (
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      changePaidStatus(item[1])
                                    }}
                                  >
                                    Paid
                                  </DropdownItem>
                                )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>

          {/* *********************** Not found****************************** */}
          {projectListData.length === 0 && (
            <Row>
              <div
                className="d-flex justify-content-center p-3"
                style={{
                  backgroundColor: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Not Found Yet!!
              </div>
            </Row>
          )}

         {missingDataModal&& <MissingData
            missingDataModal={missingDataModal}
            toggleMissingData={toggleMissingData}
            MissingReport={rowData}
          />}

          {/****************pop up****************/}
          {popup && (
            <ProjectOverview
              togglePopup={() => {
                togglePopup()
              }}
              rowData={rowData}
              setAuth={setAuth}
            />
          )}

          {/*****Delete Pop*****************/}
          {dpopup && (
            <DeleteProject
              toggledPopup={() => {
                toggledPopup()
              }}
              rowData={rowData}
              setAuth={setAuth}
            />
          )}

          {/* *********confirm FT  ******************/}
          <Modal isOpen={confmodal} toggle={conftoggle}>
            <ModalBody className="px-4 py-5 text-center">
              {rowData.Paid == 0 ? (
                <p className="text-muted font-size-16 mb-4">
                  Project Type is <span className="text-danger">FT</span> &{" "}
                  <span className="text-danger">Not Paid</span> <br /> Are you
                  sure you want to containue?
                </p>
              ) : (
                <p className="text-muted font-size-16 mb-4">
                  Project Type is{" "}
                  <span className="text-danger">Out of RD0</span>
                  <br /> Are you sure you want to containue?
                </p>
              )}
            </ModalBody>
            <FormGroup className="d-flex ms-auto col-4  justify-content-around">
              <Button
                onClick={() => {
                  conftoggle()
                  togglePopup()
                }}
                className="bg-secondary me-2"
              >
                close
              </Button>

              <Button
                type="button"
                onClick={() => {
                  conftoggle()
                  setConfirm(!confirm)
                  OverView()
                }}
                className=" me-2 bg-primary"
              >
                confirm
              </Button>
            </FormGroup>
          </Modal>

          {/* ********************** Edit Project details ********************************* */}
          {editModal && (
            <EditProjectData
              editToggle={editToggle}
              project={project}
              editing={editing}
              setEditing={setEditing}
              setAuth={setAuth}
            />
          )}

          {/*  *********SP action******** */}
          {SPModel && (
            <ConfirmingAction
              confirmFunc={SP_MSFunction}
              action={"Edite"}
              Confirmetoggle={SPtoggle}
              ConfirmeModel={SPModel}
              massege={"Are you sure you want to Change state to SP ? "}
            />
          )}
          {/*  *********MS action******** */}
          {MSModel && (
            <ConfirmingAction
              confirmFunc={SP_MSFunction}
              action={"Edite"}
              Confirmetoggle={MStoggle}
              ConfirmeModel={MSModel}
              massege={"Are you sure you want to Change state to MS ? "}
            />
          )}

          {/* ********************** Pagination ********************************* */}
          {projectListData.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}

          {/* ********************** Related projects ********************************* */}

          {projectListData.length === 1 && projectListData[0][1].ConstructorID && (
            <Row>
              <RelatedProjects project={projectListData[0]} setAuth={setAuth} />
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProjectsList)
