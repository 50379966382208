import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"

const FilterPSadad = ({ filteredValues, setFilteredValues, setAuth }) => {
  // console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [users, setUsers] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }
  // console.log(
  //   "startDate",
  //   startDate && new Date(startDate).toISOString().split("T")[0]
  // )
  // console.log(
  //   "endDate",
  //   endDate && new Date(endDate).toISOString().split("T")[0]
  // )
  // console.log("dateRange", dateRange)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const paymentOptions = [
    { value: "Yes", label: "Paid" },
    { value: "Partially", label: "Partially Paid" },
    { value: "No", label: "Not Paid" },
  ]

  // ************************* Cost Range Error *****************************
  const errors = {
    projectCostFrom: "",
    projectCostTo: "",
  }

  const checkingErrors = (from, to) => {
    // negative value
    if (filteredValues[from] < 0)
      errors[from] = "must be greater than or equal to 0"
    if (filteredValues[to] < 0)
      errors[to] = "must be greater than or equal to 0"

    // only one value is available
    if (filteredValues[from] && !filteredValues[to]) errors[to] = "required"
    if (filteredValues[to] && !filteredValues[from]) errors[from] = "required"

    // the to value is smaller
    if (
      filteredValues[from] &&
      filteredValues[to] &&
      filteredValues[from] * 1 > filteredValues[to] * 1
    ) {
      errors[from] = "must be smaller"
      errors[to] = "must be greater"
    }
  }

  checkingErrors("projectCostFrom", "projectCostTo")



  return (
    <>
      <Row className="mb-3">
    
        {/* ******************** merchant_reference NO ******************/}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    merchant_reference: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    merchant_reference: allItems,
                  })
                }
                type="text"
                name="merchant_reference"
                placeholder="Marchant No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>
        {/* ******************** Reference No ****************************/}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>
        {/* ******************** sadadNo No ****************************/}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    sadadNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    sadadNo: allItems,
                  })
                }
                type="number"
                name="sadadNo"
                placeholder="Sadad No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>
        {/* ******************** Quotation Date ***************************/}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Quotation Date ..."
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default FilterPSadad
