import React, { useEffect, useState } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
  Input,
  Button,
} from "reactstrap"
import axios from "axios"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteUser from "./DeleteUser"
import PaginationComponent from "common/PaginationComponent"
import FilterUsers from "./FilterUsers"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import AddUser from "./AddUser"
import EditUser from "./EditUser"
import { usePrevious } from "common/CustomHooks"

//import Images
// import UserData from "./userData"

const UserList = () => {
  const history = useHistory()

  //meta title
  document.title = "User | DomApp - React Admin & Dashboard Template"

  const role = localStorage.getItem("role")

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ********************* Add User Modal*****************************
  const [addUserModal, setAddUserModal] = useState(false)
  const addUserToggle = () => {
    setAddUserModal(!addUserModal)
  }

  // ********************* Edit User Modal*****************************
  const [selectedUser, setSelectedUser] = useState()

  const [editUserModal, setEditUserModal] = useState(false)
  const editUserToggle = () => {
    setEditUserModal(!editUserModal)
  }

  // ********************* Delete User Modal*****************************

  const [deleteUserModal, setDeleteUserModal] = useState(false)
  const deleteUserToggle = () => {
    setDeleteUserModal(!deleteUserModal)
  }

  // ********** Filter Section *********************
  const initialFilteredValues = {
    UserName: [],
    Role: [],
    Status: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //---------Delete------------------//
  const [dpopup, setdPopup] = useState(false)
  const [rowData, setRowData] = useState({})

  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const DeleteFun = item => {
    toggledPopup()
    setRowData(item)
  }
  const deletefunc = () => {
    rowData.hide = true
    Dtoggle()
  }

  //------ checked rows -------//
  const [checkedRows, setCheckedRows] = useState([])
  const checkbox = item => {
    setCheckedRows([item, ...checkedRows])
  }

  const [editing, setEditing] = useState(false)
  const [UserData, setUserData] = useState([])

  const prevFilteredValues = usePrevious(filteredValues);
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
}
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Users: filteredValues.UserName.map(item => item.value).join(","),
      Roles: filteredValues.Role.map(item => item.value).join(","),
      Status: filteredValues.Status,
      Page: page,
    }
    axios
      .post(`https://test.rdapp.net/api/Users/View`, formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        setTotalPages(res.data.TotalPages)
        array.splice(-4)
        setUserData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues])

  //----------- Delete user modal -------------------//

  const [deleteToggle, setDeleteToggle] = useState(false)
  const [deletedUser, setDeletedUser] = useState(null)

  const deleteToggleHandler = () => {
    setDeleteToggle(!deleteToggle)
  }

  const deleteUserHandler = id => {
    deleteToggleHandler()
    setDeletedUser(UserData.filter(user => user[0] === id)[0])
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // ********************************************* //
  // not allowed views
  if (
    userPermissions.R9.P !== "1" &&
    userPermissions.R9.P !== "2" &&
    userPermissions.R9.P !== "3" &&
    userPermissions.R9.P !== "4" &&
    userPermissions.R9.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Users" />

            {/*************** New User Section *************/}
            {(userPermissions.R9.P === "2" ||
              userPermissions.R9.P === "3" ||
              userPermissions.R9.P === "4") && (
              <div className="d-flex justify-content-between mb-4">
                {/* <Link to="Add-User" className="ms-auto"> */}
                <button
                  className="btn btn-primary py-2 px-4 me-2 ms-auto"
                  onClick={() => addUserToggle()}
                >
                  Add User
                </button>
                {/* </Link> */}
              </div>
            )}

            {/*************** Filter Section *************/}
            <FilterUsers
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
            />

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Role</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last visit</th>
                          {(userPermissions.R9.P === "3" ||
                            userPermissions.R9.P === "4") && (
                            <th scope="col">Action</th>
                          )}
                        </tr>
                      </thead>
                      {/***********table body *************************************/}
                      <tbody>
                        {UserData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                type="checkbox"
                                onClick={() => {
                                  checkbox(item[1])
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>{item[1].UserID}</td>
                            <td>
                              {item[1].FirstName + " " + item[1].LastName}
                            </td>

                            <td>{item[1].RoleName}</td>

                            <td>
                              <span
                                className={
                                  item[1].Status == "On"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {item[1].Status == "On"
                                  ? "Active"
                                  : "Dismissed"}
                              </span>
                            </td>
                            <td>{item[1].LastVisit}</td>

                            {(userPermissions.R9.P === "3" ||
                              userPermissions.R9.P === "4") && (
                              <td>
                                {(userPermissions.R9.P === "3" ||
                                  userPermissions.R9.P === "4") && (
                                  <span
                                    className="text-primary"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i
                                      onClick={e => {
                                        setSelectedUser(item[1])
                                        editUserToggle()
                                      }}
                                      className="mdi mdi-pencil font-size-18"
                                      id="edittooltip"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="edittooltip"
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </span>
                                )}
                                {userPermissions.R9.P === "4" && (
                                  <Link
                                    to="#"
                                    className="text-danger ms-1"
                                    onClick={() => {
                                      // DeleteFun(item[1])
                                      // deleteUserHandler(item[0])
                                      setSelectedUser(item[1])
                                      deleteUserToggle()
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      id="deletetooltip"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="deletetooltip"
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </Link>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {/* ****************** Add User *************************** */}
            {addUserModal && (
              <AddUser
                addUserToggle={addUserToggle}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ****************** Edit User *************************** */}
            {editUserModal && (
              <EditUser
                editUserToggle={editUserToggle}
                selectedUser={selectedUser}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ****************** Delete User *************************** */}
            {deleteUserModal && (
              <DeleteUser
                deleteUserToggle={deleteUserToggle}
                selectedUser={selectedUser}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* **************** Pagination ************************** */}
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(UserList)
