import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import TicketsList from "./TicketsList"
import { convertPermission } from "permissionUtils"

const Tickets = () => {
  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tickets" breadcrumbItem="Tickets List" />

          {/* ********************** Filter Section ***************************** */}

          {(userPermissions.R11.P === "1" ||
            userPermissions.R11.P === "2" ||
            userPermissions.R11.P === "3" ||
            userPermissions.R11.P === "4") && <TicketsList />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tickets
