import React, { useEffect, useState, useRef, createRef, useMemo } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  FormGroup,
} from "reactstrap"
import axios from "axios"
import RDZero from "./RD-1"
import ProjectHeader from "../ProjectHeader"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"
import RD6RelatedTickets from "./tickets/RD1RelatedTickets"

const AnswerPage = props => {
  const history = useHistory()
  const projectId = props.match.params.ProjectID

  let selectedSections = props.location.state?.selectedSections

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  let project

  const [allValues, setAllValues] = useState([])

  const [activeTab, setActiveTab] = useState("1")
  const [data, setData] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: "13",
    }

    axios
      .post("https://test.rdapp.net/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =============", res)
        let array = Object.values(res.data)
        array.splice(-1)
        let sections = []
        array.forEach(section => {
          selectedSections.includes(section.SectionID) && sections.push(section)
        })
        setData(sections)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [submittedReports, setSubmittedReports] = useState([])
  const isLastTab = activeTab == data.length + 1
  const PreviousFunc = () => {
    if (activeTab > 1) {
      setActiveTab((activeTab - 1).toString())
    }
  }
  const Previous = activeTab > 1

  const formRef = useRef()
  const formRefs = useRef([])
  formRefs.current = data.map((item, i) => formRefs.current[i] ?? createRef())

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <div className="page-content">
      <ProjectHeader projectID={projectId} />

      <h4 className="mb-3">RD-1 Report Answer</h4>
      <Nav tabs fill style={{ width: "60%" }}>
        {data.map((item, key) => {
          return (
            <NavItem key={key}>
              <NavLink className={activeTab == key + 1 ? "active" : ""}>
                {item.SectionName}
              </NavLink>
            </NavItem>
          )
        })}
        <NavItem>
          <NavLink>conclusion</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((item, key) => {
          return (
            <TabPane tabId={(key + 1).toString()} key={key}>
              <RDZero
                id={item.SectionID}
                projectId={projectId}
                submittedReports={submittedReports}
                setSubmittedReports={setSubmittedReports}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                submittedKey={key + 1}
                tabsLength={data.length + 1}
                allValues={allValues}
                setAllValues={setAllValues}
                setAuthfun={setAuth}
                userPermissions={userPermissions}
                refAP={formRefs.current[key]}
                formRef={formRef}
              />
            </TabPane>
          )
        })}
        <TabPane tabId={(data.length + 1).toString()}>
          <RD6RelatedTickets projectId={projectId} />
        </TabPane>
      </TabContent>
      <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
        {isLastTab ? (
          <div>
            <Button onClick={PreviousFunc} className="bg-primary me-2">
              {"< previous"}
            </Button>
            <Button
              type="submit"
              className="bg-primary"
              onClick={() =>
                formRefs.current[activeTab - 2].current["props"] &&
                formRefs.current[activeTab - 2].current.props.onSubmit()
              }
            >
              Submit
            </Button>
          </div>
        ) : (
          <div>
            {Previous && (
              <Button onClick={PreviousFunc} className="bg-primary me-2">
                {"< previous"}
              </Button>
            )}
            <Button
              type="submit"
              className="bg-primary"
              onClick={() =>
                formRefs.current[activeTab - 1].current["props"] &&
                formRefs.current[activeTab - 1].current.props.onSubmit()
              }
              // formRefs.current[0]&&console.log("formRef",formRefs.current[0].current.props)
            >
              {"Next >"}
            </Button>
          </div>
        )}
      </FormGroup>
    </div>
  )
}

export default AnswerPage
