import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const AddNewQuotation = ({
  newQuotationToggle,
  editing,
  setEditing,
  setAuth,
}) => {
  const history = useHistory()

  const userID = localStorage.getItem("id")
  const [modal, setModal] = useState(true)
  const toggle = () => {
    newQuotationToggle()
  }

  const onClose = () => {
    newQuotationToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const [projectCost, setProjectCost] = useState("")
  const [referenceNo, setReferenceNo] = useState("")
  //   console.log(projectCost)

  // ************** Getting Project Cost *****************
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: referenceNo,
    }
    axios
      .post("https://test.rdapp.net/api/ProjectCostView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res===========>", res)
        if (res.data.error === false) {
          setProjectCost(res.data.Cost)
        }
      })
      .catch(err => console.log(err))
  }, [referenceNo])

  const RD7PercentageHandler = (WP, G, S, projectCost) => {
    let G1, S1, WP1;

    if (projectCost <= 800000 && G > 0) {
        G1 = 0.15;
    } else {
        G1 = (G / 100) * 0.15;
    }

    if (projectCost <= 800000 && S > 0) {
        S1 = 0.1;
    } else {
        S1 = (S / 100) * 0.1;
    }

    if (projectCost <= 800000 && WP > 0) {
        WP1 = 0.2;
    } else {
        WP1 = (WP / 100) * 0.2;
    }

    return parseFloat((G1 + S1 + WP1).toFixed(2));
}

const RD7CostHandler = (WP, G, S,  projectCost) => {
  let G1 = (G / 100) * 0.15;
  let S1 = (S / 100) * 0.1;
  let WP1 = (WP / 100) * 0.2;
  let Gcost;
  let Scost;
  let WPcost;

  if (G1 > 0) {
      if (projectCost <= 800000) {
          Gcost = Math.max((projectCost * G1) / 100, 1200);
          if (Gcost === 1200) {
              G1 = 0.15;
          }
      } else {
        Gcost = Math.max((projectCost * G1) / 100, 1200);

      }
  } else {
      Gcost = projectCost * G1;
  }

  if (S1 > 0) {
      if (projectCost <= 800000) {
          Scost = Math.max((projectCost * S1) / 100, 800);
          if (Scost === 800) {
              S1 = 0.1;
          }
      } else {
        Scost = Math.max((projectCost * S1) / 100, 800);
      }
  } else {
      Scost = projectCost * S1;
  }

  if (WP1 > 0) {
      if (projectCost <= 800000) {
          WPcost = Math.max((projectCost * WP1) / 100, 1600);
          if (WPcost === 1600) {
              WP1 = 0.2;
          }
      } else {
        WPcost = Math.max((projectCost * WP1) / 100, 1600);
      }
  } else {
      WPcost = projectCost * WP1;
  }
  console.log("Gcost + Scost + WPcost",Gcost , Scost , WPcost);
  

  return Gcost + Scost + WPcost;
};

  // const RD7PercentageHandler = (WP, G, S) => {
  //   if (WP === true) {
  //     return 0.45
  //   } else if (projectCost <= 800000 && G > 0 && S === 0) {
  //     return 0.15
  //   } else if (projectCost <= 800000 && G > 0 && S > 0) {
  //     return 0.25
  //   } else if (projectCost > 800000) {
  //     return G * 0.0015 + S * 0.001
  //   } else {
  //     return ""
  //   }
  // }

  // const RD7CostHandler = (WP, G, S, RD7CostPer) => {
  //   if (WP === true) {
  //     return Math.max(3600, (0.45 * projectCost) / 115)
  //   } else if (S === 0) {
  //     return Math.max(1200, (RD7CostPer * projectCost) / 115)
  //   } else if (S > 0) {
  //     return Math.max(2000, (RD7CostPer * projectCost) / 115)
  //   } else {
  //     return ""
  //   }
  // }

  const submitHandler = (values, onSubmitProps) => {
    if (!projectCost) return

    onSubmitProps.resetForm()

    const formData = new FormData()
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))

    formData.append("UserID", userID)
    formData.append("PCost", projectCost)
    formData.append("ReferenceNo", referenceNo)
    formData.append("S", values.S)
    formData.append("G", values.G)
    formData.append("WP", values.WP)
    formData.append("NoUnit", values.Units)
    formData.append("RD7Percent", values.RD7CostPer)
    formData.append("RD7Cost", values.RD7Cost)
    formData.append("VAT", values.VAT)
    formData.append("RD7Total", values.RD7Total)
    values.DiscountCheck && formData.append("Discount", values.Discount)

    // for (const value of formData.entries()) {
    //   console.log(value)
    // }

    axios
      .post("https://test.rdapp.net/api/AddRD7Quotation", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          New Quotation
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Refrence No</Label>
                <Input
                  type="number"
                  name="ReferenceNo"
                  value={referenceNo}
                  onChange={e => {
                    setReferenceNo(e.target.value)
                  }}
                  disabled={
                    userPermissions.R14.P !== "2" &&
                    userPermissions.R14.P !== "3" &&
                    userPermissions.R14.P !== "4"
                  }
                />
                {!projectCost ? (
                  <p className="error px-1 w-100">
                    valid reference no. required
                  </p>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label>Estimated Project Costs</Label>
                <Input
                  type="text"
                  name="projectCost"
                  value={projectCost ? projectCost + " SAR" : ""}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Formik
            // enableReinitialize
            validateOnBlur
            validateOnChange
            initialValues={{
              G: "",
              S: "",
              WP: "",
              Units: 1,
              RD7CostPer: "",
              RD7Cost: "",
              RD7Total: "",
              VAT: "",
              RD7Total: "",
              Discount: "",
              DiscountCheck: false,
            }}
            validate={values => {
              let errors = {}

              if (values.WP === "") errors.WP = "required"
              if (values.WP < 0 || values.WP > 100)
                errors.WP = "must be between 0 , 100"

              
                if (values.G === "") errors.G = "required"
                if (values.G < 0 || values.G > 100)
                  errors.G = "must be between 0 , 100"

                if (values.S === "") errors.S = "required"
                if (values.S < 0 || values.S > 100)
                  errors.S = "must be between 0 , 100"
              

              if (!values.Units) errors.Units = "required"

              if (values.DiscountCheck && !values.Discount)
                errors.Discount = "required"

              console.log("errors", errors)
              return errors
            }}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                {projectCost && (
                  <>
                    <hr
                      style={{ height: "2px", backgroundColor: "lightgray" }}
                    />

                    {/* ******************** Completion of Percantage ************************************** */}
                    <Row className="my-2">
                      <Col sm={4} className="d-flex align-items-center">
                        <Label>Completion of Percantage %</Label>
                      </Col>
                      <Col sm={8}>
                        <Row>
                          <Col
                            sm={4}
                            className="d-flex flex-column align-items-center"
                          >
                            <Label>G :</Label>
                            <Input
                              name="G"
                              type="number"
                              min={0}
                              max={100}
                              // disabled={props.values.WP}
                              value={props.values.G}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            {props.errors.G ? (
                              <p className="error px-1 w-100">
                                {props.errors.G}
                              </p>
                            ) : null}
                          </Col>
                          <Col
                            sm={4}
                            className="d-flex flex-column align-items-center"
                          >
                            <Label onClick={() => console.log(props.errors)}>
                              S :
                            </Label>
                            <Input
                              type="number"
                              name="S"
                              min={0}
                              max={100}
                              // disabled={props.values.WP}
                              value={props.values.S}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            {props.errors.S ? (
                              <p className="error px-1 w-100">
                                {props.errors.S}
                              </p>
                            ) : null}
                          </Col>
                          <Col
                            sm={4}
                            className="d-flex flex-column align-items-center"
                          >
                            
                              <Label htmlFor="WP" >
                                WP :
                              </Label>
                              <Input
                                type="number"
                                name="WP"
                                id="WP"
                                min={0}
                                max={100}
                                // defaultChecked={props.values.WP}
                                onChange={e => {
                                  props.setFieldValue("WP", e.target.value)
                                }}
                                onBlur={props.handleBlur}
                              />
                            
                            {props.errors.WP ? (
                              <p className="error px-1 w-100">
                                {props.errors.WP}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* ******************** No of Units ************************************** */}
                    <Row className="my-2">
                      <Col sm={4}>
                        <Label>No of Units</Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          min={1}
                          name="Units"
                          value={props.values.Units}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        {props.errors.Units ? (
                          <p className="error px-1 w-100">
                            {props.errors.Units}
                          </p>
                        ) : null}
                      </Col>
                    </Row>

                    {/* ******************** RD7 Cost Percentage ************************************** */}
                    <Row className="my-2">
                      <Col sm={4}>
                        <Label>RD7 Cost Percentage</Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name="RD7CostPer"
                          id="RD7CostPer"
                          value={
                            (props.values.RD7CostPer = RD7PercentageHandler(
                              props.values.WP,
                              props.values.G,
                              props.values.S,projectCost
                            ))
                          }
                          disabled
                        />
                      </Col>
                      <Col sm={1} className="d-flex  align-items-center">
                        %
                      </Col>
                      {/* <Col
                        sm={3}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>
                          <Input
                            type="checkbox"
                            defaultChecked={features.manaully}
                            onChange={() => {
                              setFeatures({
                                ...features,
                                manaully: !features.manaully,
                              })
                            }}
                          />
                          <Label className="mx-2">Insert Manually</Label>
                        </div>
                      </Col> */}
                    </Row>

                    {/* ******************** RD7 Cost ************************************** */}
                    <Row className="my-2">
                      <Col sm={4}>
                        <Label>RD7 Cost</Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name="RD7Cost"
                          id="RD7Cost"
                          onChange={()=>{
                            console.log("props.values.RD7Cost ",props.values.RD7Cost );
                            
                          }}
                          value={
                            (props.values.RD7Cost = Math.round(
                              RD7CostHandler(
                                props.values.WP,
                                props.values.G,
                                props.values.S,
                                projectCost
                              )
                            ))
                          }
                          disabled
                        />
                      </Col>
                      <Col sm={1} className="d-flex  align-items-center">
                        SAR
                      </Col>
                    </Row>

                    {/* ******************** Discount ************************************** */}
                    <Row className="my-2">
                      <Col sm={4} className="d-flex align-items-center">
                        <div>
                          <Input
                            type="checkbox"
                            defaultChecked={props.values.DiscountCheck}
                            onChange={e =>
                              props.setFieldValue(
                                "DiscountCheck",
                                e.target.checked
                              )
                            }
                          />
                          <Label className="mx-2">Discount</Label>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          min={0}
                          max={100}
                          name="Discount"
                          value={props.values.Discount}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          disabled={!props.values.DiscountCheck}
                        />
                        {props.errors.Discount ? (
                          <p className="error px-1 w-100">
                            {props.errors.Discount}
                          </p>
                        ) : null}
                      </Col>
                      <Col sm={1} className="d-flex  align-items-center">
                        %
                      </Col>
                    </Row>

                    {/* ******************** VAT ************************************** */}
                    <Row className="my-2">
                      <Col sm={4}>
                        <Label>VAT</Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name="VAT"
                          value={
                            (props.values.VAT = Math.round(
                              props.values.RD7Cost *
                                0.15 *
                                (1 -
                                  (props.values.DiscountCheck &&
                                  props.values.Discount
                                    ? props.values.Discount / 100
                                    : 0))
                            ))
                          }
                          //   onChange={props.handleChange}
                          //   onBlur={props.handleBlur}
                          disabled
                        />
                      </Col>
                      <Col sm={1} className="d-flex  align-items-center">
                        SAR
                      </Col>
                    </Row>

                    {/* ******************** RD7 Total Cost(including VAT) ************************************** */}
                    <Row className="my-2">
                      <Col sm={4}>
                        <Label>
                          RD7 Total Cost <br /> (incl. VAT & Discount)
                        </Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name="RD7Total"
                          value={
                            (props.values.RD7Total = Math.ceil(
                              props.values.RD7Cost *
                                (1 -
                                  (props.values.DiscountCheck &&
                                  props.values.Discount
                                    ? props.values.Discount / 100
                                    : 0)) +
                                props.values.VAT
                            ))
                          }
                          //   onChange={props.handleChange}
                          //   onBlur={props.handleBlur}
                          disabled
                        />
                      </Col>
                      <Col sm={1} className="d-flex  align-items-center">
                        SAR
                      </Col>
                    </Row>
                  </>
                )}
                <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    close
                  </Button>
                  <Button
                    type="submit"
                    className="bg-primary"
                    disabled={
                      userPermissions.R14.P !== "2" &&
                      userPermissions.R14.P !== "3" &&
                      userPermissions.R14.P !== "4"
                    }
                  >
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddNewQuotation
