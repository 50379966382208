import React, { useEffect, useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import RD6 from "./RD6"
import ProjectHeader from "pages/RD-0/ProjectHeader"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"
import OtherRD6Section from "./OtherRD6Section"
import { filter } from "lodash"
import LoadingInsider from "common/LoadingInsider"

const RD6AnswerPage = props => {
  const projectId = props.match.params.ProjectID

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const history = useHistory()

 const [allValues, setAllValues] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  const [allSections, setAllSections] = useState([])
  const [data, setData] = useState([])
  const [otherSections, setOtherSections] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: "11",
    }

    // selecting Design or Inspection or Both for Sections
    if (
      ((userPermissions.R3.P === "2" ||
        userPermissions.R3.P === "3" ||
        userPermissions.R3.P === "4") &&
        (userPermissions.R4.P === "2" ||
          userPermissions.R4.P === "3" ||
          userPermissions.R4.P === "4")) ||
      userPermissions.R20.P === "2" ||
      userPermissions.R20.P === "3" ||
      userPermissions.R20.P === "4" ||
      userPermissions.R4.G === "1"
    ) {
      formData.Ptype = ""
    } else if (
      userPermissions.R3.P === "2" ||
      userPermissions.R3.P === "3" ||
      userPermissions.R3.P === "4"
    ) {
      formData.Ptype = "1"
    } else if (
      userPermissions.R4.P === "2" ||
      userPermissions.R4.P === "3" ||
      userPermissions.R4.P === "4"
    ) {
      formData.Ptype = "2"
    }

    // *****************Fetching all sections **********************
    axios
      .post("https://test.rdapp.net/api/Sections/View", {
        ...formData,
        Ptype: "",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =============", res)
        let array = Object.values(res.data)
        array.splice(-1)
        setAllSections(array)
      })
      .catch(error => {
        console.log(error)
      })

    // *****************Fetching only required sections **********************
    axios
      .post("https://test.rdapp.net/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =============", res)
        let array = Object.values(res.data)
        array.splice(-1)
        setData(array)
      })
      .catch(error => {
        console.log(error)
      })

    // *****************Selecting other sections **********************
    // data.map(item => {
    //   setOtherSections([
    //     ...allSections.filter(section => section.SectionID !== item.SectionID),
    //     ...otherSections,
    //   ])
    // })
  }, [])

  // *****************Selecting other sections **********************
  const [activeOtherSection, setActiveOtherSection] = useState("initialID")
  // console.log("activeOtherSection", activeOtherSection)

  useEffect(() => {
    if (data.length > 0 && allSections.length > 0) {
      let filterdSections = []

      allSections.forEach(item => {
        let dataIDs = data.map(el => el.SectionID)
        if (!dataIDs.includes(item.SectionID)) filterdSections.push(item)
      })

      setOtherSections(filterdSections)
      setActiveOtherSection(filterdSections[0]?.SectionID)
    }
  }, [data, allSections])

  // console.log("allSections", allSections)
  // console.log("data", data)
  // console.log("otherSections", otherSections)
  
  const [submittedReports, setSubmittedReports] = useState([])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R4.P !== "2" &&
    userPermissions.R4.P !== "3" &&
    userPermissions.R4.P !== "4"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <div className="page-content">
        <ProjectHeader projectID={projectId} />

        {otherSections.map((item, key) => {
          // if (item.done === true)
          if (item.SectionID === activeOtherSection)
            return (
              <OtherRD6Section
                key={key}
                id={item.SectionID}
                projectId={projectId}
                // SpecialSystem={SpecialSystem}
                submittedReports={submittedReports}
                setSubmittedReports={setSubmittedReports}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                submittedKey={key + 1}
                tabsLength={data.length}
                allValues={allValues}
                setAllValues={setAllValues}
                setAuth={setAuth}
                setActiveOtherSection={setActiveOtherSection}
                nextSection={otherSections[key + 1]?.SectionID}
              />
            )
        })}

        <h4 className="mb-3">RD-6 Report Answers</h4>

        {!activeOtherSection ? (
          <div>
            <Nav tabs fill>
              {data.map((item, key) => {
                return (
                  <NavItem key={key}>
                    <NavLink
                      className={activeTab == key + 1 ? "active" : ""}
                      // onClick={() => setActiveTab((key + 1).toString())}
                    >
                      {item.SectionName}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {data.map((item, key) => {
                return (
                  <TabPane tabId={(key + 1).toString()} key={key}>
                    <RD6
                      id={item.SectionID}
                      projectId={projectId}
                      // SpecialSystem={SpecialSystem}
                      submittedReports={submittedReports}
                      setSubmittedReports={setSubmittedReports}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      submittedKey={key + 1}
                      tabsLength={data.length}
                      allValues={allValues}
                      setAllValues={setAllValues}
                      setAuth={setAuth}
                      sectionType={item.Ptype}
                    />
                  </TabPane>
                )
              })}
            </TabContent>
          </div>
        ) : (
          <LoadingInsider type="spin" color="gray" />
        )}
      </div>
    )
  }
}

export default RD6AnswerPage
