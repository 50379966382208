import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Label, Input, Col, FormGroup } from "reactstrap"

const Icons = ({
  answerIds,
  props,
  setAnswerIds,
  inputType,
  defaultInput,
  id,
}) => {
  const addAnswer = () => {
    let lastId = answerIds[answerIds.length - 1]
    let temp = lastId + 1
    setAnswerIds([...answerIds, temp])
  }
  

  const removeAnswer = itemId => {
    const index = answerIds.indexOf(itemId)
    let tempAnswerIds = [...answerIds]
    tempAnswerIds.splice(index, 1)
    setAnswerIds(tempAnswerIds)
  }
  const [disableDefault, setDisableDefault] = useState(false)
  useEffect(() => {
    if (props.values[`default${id}`] == true) {
      setDisableDefault(true)
    }
  }, [])

  return (
    <Col sm={4} className="d-flex mt-2  justify-content-around">
      <FormGroup className="d-flex justify-content-around">
        <Link
          to="#"
          onClick={() => {
            let lastId = answerIds[answerIds.length - 1]
            if (inputType != "Dropdown") {
              if (lastId < 10) {
                addAnswer()
              }
            } else {
              addAnswer()
            }
            props.values[`Answer${lastId + 1}`] = ""
            props.values[`AnswerHint${lastId + 1}`] = ""
            props.values[`default${lastId + 1}`] = false
          }}
          className="p-0"
        >
          <i className="mdi mdi-plus font-size-14" id="edittooltip" />
        </Link>

        <Link
          to="#"
          onClick={() => {
            if (answerIds.length > 1) {
              removeAnswer(id)
              props.values["Answer" + id] && delete props.values["Answer" + id]
              props.values["AnswerHint" + id] &&  delete props.values["AnswerHint" + id]
              props.values["default" + id] && delete props.values["default" + id]
              props.values["ParentAID"+id] && delete props.values["ParentAID"+id]
              props.values["AnswerOperation"+id] && delete props.values["AnswerOperation"+id]
              props.values["QuestionOperation"+id] && delete props.values["QuestionOperation"+id]
              props.values["ParentQuestion"+id] && delete props.values["ParentQuestion"+id]
              props.values["OtherEmail"+id] && delete props.values["OtherEmail"+id]

            }
          }}
          className="p-1 py-0"
        >
          <i className="mdi mdi-minus font-size-8" id="edittooltip" />
        </Link>
      </FormGroup>
      {/* // ) : (
      //   ""
      // )} */}
      {defaultInput !== false && (
        <FormGroup className="d-flex col-10 justify-content-between" check>
          <FormGroup disabled={disableDefault}>
            <Label check>
              <Input
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                className="form-control"
                name={`default${id}`}
                id={`default${id}`}
                type="checkbox"
                value={props.values[`default${id}`] || false}
                defaultChecked={props.values["default" + id]}
              />
              default
            </Label>
          </FormGroup>
        </FormGroup>
      )}
    </Col>
  )
}

export default Icons
