import React, { useEffect, useState } from "react"
import { Tree, TreeNode } from "react-organizational-chart"
// import { styled } from "styled-components"
import DepartmentCard from "./DepartmentCard"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import axios from "axios"

const ExampleTree = ({ setAuth, userPermissions }) => {
  const history = useHistory()

  const [departmentsData, setDepartmentsData] = useState([])
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://test.rdapp.net/api/OrgChart.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setDepartmentsData(array)
      })
  }, [editing])

  const filterDepartmentHandler = id => {
    let department = departmentsData.filter(item => item.OCID === id)[0]
    // console.log("department", id, department)
    return department
  }

  const levelDepartmentHandler = departmentID => {
    const department = filterDepartmentHandler(departmentID)

    let level

    if (department.ParentID === "0") {
      level = "1"
    } else if (filterDepartmentHandler(department.ParentID).ParentID === "0") {
      level = "2"
    } else if (
      filterDepartmentHandler(
        filterDepartmentHandler(department.ParentID).ParentID
      ).ParentID === "0"
    ) {
      level = "3"
    } else if (
      filterDepartmentHandler(
        filterDepartmentHandler(
          filterDepartmentHandler(department.ParentID).ParentID
        ).ParentID
      ).ParentID === "0"
    ) {
      level = "4"
    } else if (
      filterDepartmentHandler(
        filterDepartmentHandler(
          filterDepartmentHandler(
            filterDepartmentHandler(department.ParentID).ParentID
          ).ParentID
        ).ParentID
      ).ParentID === "0"
    ) {
      level = "5"
    }

    return level
  }

  const departmentObjectHandler = departmentID => {
    if (departmentsData.length > 0) {
      let department = filterDepartmentHandler(departmentID)
      // console.log("department", department)

      return {
        ...department,
        child: department.child.map(item => ({
          ...filterDepartmentHandler(item.OCID),
          child: filterDepartmentHandler(item.OCID).child.map(i => ({
            ...filterDepartmentHandler(i.OCID),
            child: filterDepartmentHandler(i.OCID).child.map(it => ({
              ...filterDepartmentHandler(it.OCID),
              // child: "",
            })),
          })),
          // .map(i => filterDepartmentHandler(i.OCID))
          // .map(it => filterDepartmentHandler(it.OCID)),
        })),
      }
    }
  }

  useEffect(() => {
    if (departmentsData.length > 0) {
      const x = departmentObjectHandler("1")
      // console.log("x", x)
    }
    // console.log("departmentsData", departmentsData)
    // if (departmentsData.length > 0) {
    //   let main = filterDepartmentHandler("1")
    //   main = {
    //     name: main.Name,
    //     children: main.child.map(item => filterDepartmentHandler(item.OCID)),
    //   }
    //   console.log("main", main)
    // }

    // if (departmentsData.length > 0) {
    //   let data = departmentsData.filter(item => (item.OCID = "1"))[0]

    //   data.child.map(item => {
    //     console.log(
    //       departmentsData,
    //       item.OCID,
    //       departmentsData.filter(i => i.OCID === item.OCID)
    //     )
    //     // filterDepartmentHandler(item.OCID)
    //   })

    //   console.log("filterDepartmentHandler", filterDepartmentHandler(6))
    //   const ourData = {
    //     name: data.Name,
    //     children: data.child.map(item => filterDepartmentHandler(item.OCID)),
    //   }
    //   console.log("ourData", ourData)
    // }
  }, [departmentsData])

  const ChildTree = ({ departmentID }) => {
    // console.log(
    //   "filterDepartmentHandler=========",
    //   departmentID,
    //   filterDepartmentHandler(departmentID).child.length
    // )
    return (
      <TreeNode
        label={
          <DepartmentCard
            department={filterDepartmentHandler(departmentID)}
            level={levelDepartmentHandler(departmentID)}
            setAuth={setAuth}
            setEditing={setEditing}
            editing={editing}
            userPermissions={userPermissions}
          />
        }
      >
        {filterDepartmentHandler(departmentID).child.length > 0
          ? filterDepartmentHandler(departmentID).child.map((item, i) => {
              // console.log("item.OCID", departmentID, item.OCID)
              return <ChildTree key={i} departmentID={item.OCID} />
            })
          : null}
      </TreeNode>
    )
  }

  if (departmentsData.length > 0) {
    return (
      <Tree
        lineWidth={"2px"}
        lineColor={"green"}
        lineBorderRadius={"10px"}
        label={
          <DepartmentCard
            department={filterDepartmentHandler("1")}
            level={levelDepartmentHandler("1")}
            setAuth={setAuth}
            setEditing={setEditing}
            editing={editing}
            userPermissions={userPermissions}
          />
        }
      >
        {/* {filterDepartmentHandler("1").child.length > 0 &&
          filterDepartmentHandler("1").child.map((item, i) => (
            <ChildTree key={i} departmentID={item.OCID} />
          ))} */}
        {filterDepartmentHandler("1").child.length > 0 &&
          filterDepartmentHandler("1").child.map((item, index) => (
            <TreeNode
              key={index}
              label={
                <DepartmentCard
                  department={filterDepartmentHandler(item.OCID)}
                  level={levelDepartmentHandler(item.OCID)}
                  setAuth={setAuth}
                  setEditing={setEditing}
                  editing={editing}
                  userPermissions={userPermissions}
                />
              }
            >
              {filterDepartmentHandler(item.OCID).child.length > 0
                ? filterDepartmentHandler(item.OCID).child.map(
                    (item2, index2) => {
                      // console.log("item.OCID", departmentID, item.OCID)
                      return (
                        <TreeNode
                          key={index2}
                          label={
                            <DepartmentCard
                              department={filterDepartmentHandler(item2.OCID)}
                              level={levelDepartmentHandler(item2.OCID)}
                              setAuth={setAuth}
                              setEditing={setEditing}
                              editing={editing}
                              userPermissions={userPermissions}
                            />
                          }
                        >
                          {filterDepartmentHandler(item2.OCID).child.length > 0
                            ? filterDepartmentHandler(item2.OCID).child.map(
                                (item3, index3) => {
                                  // console.log("item.OCID", departmentID, item.OCID)
                                  return (
                                    <TreeNode
                                      key={index3}
                                      label={
                                        <DepartmentCard
                                          department={filterDepartmentHandler(
                                            item3.OCID
                                          )}
                                          level={levelDepartmentHandler(
                                            item3.OCID
                                          )}
                                          setAuth={setAuth}
                                          setEditing={setEditing}
                                          editing={editing}
                                          userPermissions={userPermissions}
                                        />
                                      }
                                    >
                                      {filterDepartmentHandler(item3.OCID).child
                                        .length > 0
                                        ? filterDepartmentHandler(
                                            item3.OCID
                                          ).child.map((item4, index4) => {
                                            // console.log("item.OCID", departmentID, item.OCID)
                                            return (
                                              <TreeNode
                                                key={index4}
                                                label={
                                                  <DepartmentCard
                                                    department={filterDepartmentHandler(
                                                      item4.OCID
                                                    )}
                                                    level={levelDepartmentHandler(
                                                      item4.OCID
                                                    )}
                                                    setAuth={setAuth}
                                                    setEditing={setEditing}
                                                    editing={editing}
                                                    userPermissions={
                                                      userPermissions
                                                    }
                                                  />
                                                }
                                              >
                                                {filterDepartmentHandler(
                                                  item4.OCID
                                                ).child.length > 0
                                                  ? filterDepartmentHandler(
                                                      item4.OCID
                                                    ).child.map(
                                                      (item5, index5) => {
                                                        // console.log("item.OCID", departmentID, item.OCID)
                                                        return (
                                                          <TreeNode
                                                            key={index5}
                                                            label={
                                                              <DepartmentCard
                                                                department={filterDepartmentHandler(
                                                                  item5.OCID
                                                                )}
                                                                level={levelDepartmentHandler(
                                                                  item5.OCID
                                                                )}
                                                                setAuth={
                                                                  setAuth
                                                                }
                                                                setEditing={
                                                                  setEditing
                                                                }
                                                                editing={
                                                                  editing
                                                                }
                                                                userPermissions={
                                                                  userPermissions
                                                                }
                                                              />
                                                            }
                                                          >
                                                            {filterDepartmentHandler(
                                                              item5.OCID
                                                            ).child.length > 0
                                                              ? filterDepartmentHandler(
                                                                  item5.OCID
                                                                ).child.map(
                                                                  (
                                                                    item6,
                                                                    index6
                                                                  ) => {
                                                                    // console.log("item.OCID", departmentID, item.OCID)
                                                                    return (
                                                                      <TreeNode
                                                                        key={
                                                                          index6
                                                                        }
                                                                        label={
                                                                          <DepartmentCard
                                                                            department={filterDepartmentHandler(
                                                                              item6.OCID
                                                                            )}
                                                                            level={levelDepartmentHandler(
                                                                              item6.OCID
                                                                            )}
                                                                            setAuth={
                                                                              setAuth
                                                                            }
                                                                            setEditing={
                                                                              setEditing
                                                                            }
                                                                            editing={
                                                                              editing
                                                                            }
                                                                            userPermissions={
                                                                              userPermissions
                                                                            }
                                                                          />
                                                                        }
                                                                      ></TreeNode>
                                                                    )
                                                                  }
                                                                )
                                                              : null}
                                                          </TreeNode>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </TreeNode>
                                            )
                                          })
                                        : null}
                                    </TreeNode>
                                  )
                                }
                              )
                            : null}
                        </TreeNode>
                      )
                    }
                  )
                : null}
            </TreeNode>
          ))}
      </Tree>
    )
  }
}

export default ExampleTree
