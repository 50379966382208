import React from "react"
import { Label, Input, FormGroup } from "reactstrap"

function RadioOrCheckboxGroup({
  props,
  style,
  disableToggle,
  type,
  name,
  id,
  title,
  value,
  defaultChecked,
}) {
  return (
    <FormGroup className={style} check inline>
      <Input
        onClick={disableToggle && disableToggle}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        className="form-control"
        type={type}
        name={name}
        id={id ? id : title}
        value={props.values[name] ? props.values[name] : value}
        defaultChecked={defaultChecked}
      />
      <Label for={id ? id : title}>{title}</Label>
    </FormGroup>
  )
}

export default RadioOrCheckboxGroup
