import React, { useState } from "react"
import { Button, UncontrolledTooltip } from "reactstrap"
import PropTypes from "prop-types"
// import { Link } from "@material-ui/core"
import { withRouter, Link } from "react-router-dom"

function HelpComponent(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <>
      <Link
        to="#"
        onClick={e => {
          e.preventDefault()
        }}
      >
        <i
          className="bx bx-help-circle font-size-15"
          id={"Tooltip-" + props.id}
        ></i>
        <UncontrolledTooltip
          placement="right"
          isOpen={tooltipOpen}
          target={"Tooltip-" + props.id}
          toggle={toggle}
        >
          {props.Title}
        </UncontrolledTooltip>
      </Link>
    </>
  )
}
// TooltipItem.propTypes = {
//   item: PropTypes.object,
//   id: PropTypes.string,
// }

// function TooltipExampleMulti(props) {
//   return (
//     <>
//       {[
//         {
//           placement: "top",
//           text: "Tooltip on Top",
//         },
//         {
//           placement: "bottom",
//           text: "Tooltip on Bottom",
//         },
//         {
//           placement: "left",
//           text: "Tooltip on Left",
//         },
//         {
//           placement: "right",
//           text: "Tooltip on Right",
//         },
//       ].map((tooltip, i) => {
//         // eslint-disable-next-line react/no-array-index-key
//         return <TooltipItem key={i} item={tooltip} id={i} />
//       })}
//     </>
//   )
// }

export default HelpComponent
