import React, { useEffect, useState } from "react"
import { Input, Col } from "reactstrap"
import CheckboxADropdown from "./CheckboxADropdown"
import RadioOrCheckboxGroup from "./RadioOrCheckboxGroup"
import MultiSelectInspection from "./MultiSelectInspection"
import Comments from "./Comments"

function Observation({
  props,
  answerId,
  degreeList,
  transferList,
  active,
  type,
}) {
  let fourComment = type === "Ins" ? ["7", "9", "8"] : ["1", "2", "5"]

  const [degreeValue, setDegreeValue] = useState([])
  const [transferValue, setTransferValue] = useState([])

  let moreComment = false
  degreeValue.forEach(
    item => fourComment.includes(item.value) && (moreComment = true)
  )

  // let observationDegreeList = degreeList.filter(item => {
  //   return (item.DegreeID != 8) & (item.DegreeID != 12)
  // })
  useEffect(() => {
    props.values["degree" + answerId] &&
      setDegreeValue(props.values["degree" + answerId])
  }, [])

  
  return (
    <div className="col-12">
      <div className="d-flex col-12 justify-content-around">
        <Col sm={2} className="me-2">
          <Input
            type="textarea"
            id="Answer"
            name={`Answer` + answerId}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            placeholder="Enter your Answer"
            className=" d-flex me-2 form-control"
            style={{ height: "30px" }}
            value={props.values["Answer" + answerId]}
          />
        </Col>
        <Col sm={3} className="me-2">
          {degreeValue &&
            degreeValue.map((degreeName, key) => (
              <Comments
                key={key}
                commentsNumber={
                  degreeName.value
                    ? fourComment.includes(degreeName.value)
                      ? 4
                      : 1
                    : fourComment.includes(degreeName)
                    ? 4
                    : 1
                }
                className="d-flex me-2 mb-1 mt-1  form-control"
                props={props}
                name={"comments"}
                placeholder={degreeName.value ? degreeName.value : degreeName}
                id={answerId}
              />
            ))}
        </Col>
        <Col sm={2}>
          <MultiSelectInspection
            isMulti={true}
            props={props}
            id={answerId}
            data={degreeList}
            name={"degree"}
            title={"Degree"}
            setValue={setDegreeValue}
            className={"me-2 "}
          />
          {props.errors["degree" + answerId] ? (
            <div className="error mb-2">
              {props.errors["degree" + answerId]}
            </div>
          ) : null}
        </Col>
        <Col sm={2}>
          <MultiSelectInspection
            isMulti={true}
            props={props}
            id={answerId}
            data={transferList}
            setValue={setTransferValue}
            name={"transferTo"}
            title={"Transfer-To"}
            className={"me-2 "}
          />
          {props.errors["transferTo" + answerId] ? (
            <div className="error mb-2" >
              {props.errors["transferTo" + answerId]}
            </div>
          ) : null}
        </Col>

        <Col sm={3}>
          <CheckboxADropdown
            props={props}
            name={"Imagerequired" + "-" + answerId}
            title={"Image-required"}
            id={answerId}
          />
        </Col>
        {active && (
          <Col sm={2}>
            <RadioOrCheckboxGroup
              props={props}
              type={"checkbox"}
              name={"activeAnswer" + answerId}
              title={"Active"}
              style={"col-2"}
              defaultChecked={props.values[`${"activeAnswer" + answerId}`]}
              value={props.values[`${"activeAnswer" + answerId}`]}
            />
          </Col>
        )}
      </div>
    </div>
  )
}

export default Observation
