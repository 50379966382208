import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"

const AVAddNewQuotation = ({ newQuotationToggle, editing, setEditing }) => {
  const userID = localStorage.getItem("id")
  const [modal, setModal] = useState(true)
  const toggle = () => {
    newQuotationToggle()
  }

  const onClose = () => {
    newQuotationToggle()
  }

  const [projectCost, setProjectCost] = useState("")
  const [projectData, setProjectData] = useState("")
  const [referenceNo, setReferenceNo] = useState("")

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: referenceNo,
    }
    axios
      .post("https://test.rdapp.net/api/ProjectCostView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          history.push("/logout")
        }

        // console.log("res===========>", res)
        if (res.data.error === false) {
          setProjectCost(res.data.Cost)
          setProjectData(res.data)
        }
      })
      .catch(err => console.log(err))
  }, [referenceNo])

  const RD7PercentageHandler = (WP, G, S) => {
    if (WP === true) {
      return 0.45
    } else if (projectCost <= 800000 && G > 0 && S === 0) {
      return 0.15
    } else if (projectCost <= 800000 && G > 0 && S > 0) {
      return 0.25
    } else if (projectCost > 800000) {
      return G * 0.0015 + S * 0.001
    } else {
      return ""
    }
  }

  const RD7CostHandler = (WP, G, S, RD7CostPer) => {
    if (WP === true) {
      return Math.max(3600, (0.45 * projectCost) / 115)
    } else if (S === 0) {
      return Math.max(1200, (RD7CostPer * projectCost) / 115)
    } else if (S > 0) {
      return Math.max(2000, (RD7CostPer * projectCost) / 115)
    } else {
      return ""
    }
  }

  const submitHandler = (values, onSubmitProps) => {
    // if (!projectCost) return

    // onSubmitProps.resetForm()

    const formData = new FormData()
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("UserID", userID),
      formData.append("ReferenceNo", referenceNo),
      formData.append("AVCost", 600),
      formData.append("VAT", 90),
      formData.append("AVTotal", 690)

    // for (const value of formData.entries()) {
    //   console.log(value)
    // }

    axios
      .post("https://test.rdapp.net/api/AV/AddAVQuotation.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          history.push("/logout")
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(err => console.log(err))
    onClose()
  }

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          New Quotation{" "}
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Refrence No</Label>
                <Input
                  type="number"
                  name="ReferenceNo"
                  value={referenceNo}
                  onChange={e => {
                    setReferenceNo(e.target.value)
                  }}
                />
                {!projectCost ? (
                  <p className="error px-1 w-100">
                    valid reference no. required
                  </p>
                ) : null}
              </FormGroup>
            </Col>

            <Formik
              initialValues={{}}
              // enableReinitialize
              validateOnBlur
              validateOnChange
              validate={values => {
                let errors = {}
                console.log("values ========>", values)

                console.log("errors", errors)
                return errors
              }}
              onSubmit={submitHandler}
            >
              {props =>
                projectData.ConstructorName && (
                  <Form onSubmit={props.handleSubmit}>
                    <Row>
                      <Label className="d-flex align-items-center mt-2 ">
                        Owner Name :{" "}
                        <Col sm={5} className="ms-5">
                          <b> {projectData.OwnerName}</b>
                        </Col>
                      </Label>
                    </Row>
                    <Row>
                      <Label className="d-flex align-items-center mt-2 ">
                        Contractor Name :
                        <Col sm={5} className="ms-3">
                          <b> {projectData.ConstructorName}</b>
                        </Col>
                      </Label>
                    </Row>
                    <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                      <Button
                        onClick={() => {
                          onClose()
                        }}
                        className="bg-primary"
                      >
                        close
                      </Button>
                      <Button type="submit" className="bg-primary">
                        Submit
                      </Button>
                    </FormGroup>
                  </Form>
                )
              }
            </Formik>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AVAddNewQuotation
