import React from "react"
import { Col, Input, Label, Row } from "reactstrap"

const LocationInputs = ({ filteredValues, setFilteredValues, errors }) => {
  return (
    <div
      className="p-3"
      style={{
        borderRadius: "5px",
      }}
    >
      <Row>
        <Col>
          <Label>Latitude</Label>
          <Input
            type="number"
            name="lat"
            value={filteredValues.lat}
            onChange={e =>
              setFilteredValues({ ...filteredValues, lat: e.target.value })
            }
            placeholder="lat ..."
          />
          {errors.lat && (
            <span className="error mx-3 d-flex justify-content-end">
              {errors.lat}
            </span>
          )}
        </Col>
        <Col>
          <Label>Longitude</Label>
          <Input
            type="number"
            name="long"
            value={filteredValues.long}
            onChange={e =>
              setFilteredValues({ ...filteredValues, long: e.target.value })
            }
            placeholder="long ..."
          />
          {errors.long && (
            <span className="error mx-3 d-flex justify-content-end">
              {errors.long}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <Label>Radius</Label>
        </Col>
        <Col sm={7}>
          <Input
            type="number"
            name="rad"
            value={filteredValues.rad}
            onChange={e =>
              setFilteredValues({ ...filteredValues, rad: e.target.value })
            }
            placeholder="rad ..."
          />
          {errors.rad && (
            <span className="error mx-3 d-flex justify-content-end">
              {errors.rad}
            </span>
          )}
        </Col>
        <Col className="d-flex align-items-center">
          <Label>m</Label>
        </Col>
      </Row>
    </div>
  )
}

export default LocationInputs
