import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import { Formik } from "formik"
import { invalid } from "moment"
import ImageUploadAPreview from "pages/RD-7/RD7ReportAnswers/ImageUploadAPreview"

function Accurate({
  summaryToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) {
  const history = useHistory()

  // *********************** Validate handler ******************************** //
  const validateHandler = values => {
    let errors = {}
    if (!values.Report) {
      errors.Report = "please upload file"
    }
    if (!values.Result) {
      errors.Result = "please select option"
    }
    console.log("values", values)
    console.log("errors", errors)
    return errors
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    const formData = new FormData()

    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("RD7ID", selectedItem.RD7ID)
    formData.append("File", values.Report)
    formData.append("Status", values.Result)
    axios
      .post("https://test.rdapp.net/api/RD7/AccurateTest.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        // setEditing(!editing)
        if (res.data.error === false) {
          history.push("/rd7-reports")
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div>
      <h5>RD7 Accurate</h5>
      <Container>
        <Formik
          initialValues={{ Result: "", Report: "" }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Row className="mt-5 ">
                <Col sm={2} className="d-flex align-items-center">
                  <Label>Report:</Label>
                </Col>
                <Col sm={4}>
                  <Input
                    type="file"
                    // value={props.values.Report}
                    name={`Report`}
                    id="Report"
                    // accept="application/msword,application/pdf, image/*"
                    onChange={e =>
                      props.setFieldValue(("Report"), e.target.files[0])
                    }
                  />

                  {props.errors[`Report`] ? (
                    <p className="error mx-2 d-flex justify-content-end mt-1">
                      {props.errors[`Report`]}
                    </p>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-5 ">
                <Col sm={2} className="d-flex align-items-center">
                  <Label>Result:</Label>
                </Col>
                <Col sm={4}>
                  <Input
                    name={`Result`}
                    type="select"
                    onChange={props.handleChange}
                  >
                    <option selected disabled>
                      -- Choose --
                    </option>
                    <option value={"Accept"}>Accept</option>
                    <option value={"Reject"}>Reject</option>
                  </Input>
                  {props.errors[`Result`] ? (
                    <p className="error mx-2 d-flex justify-content-end">
                      {props.errors[`Result`]}
                    </p>
                  ) : null}
                </Col>
              </Row>

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <button type="submit" className="btn btn-danger">
                  Submit
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

export default Accurate
