import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import PaginationComponent from "common/PaginationComponent"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import FilterProjectCosts from "./FilterProjectCosts"
import ViewCost from "./ViewCost"
import { usePrevious } from "common/CustomHooks"

const ProjectCosts = () => {
  const history = useHistory()

  const [projectCosts, setProjectCosts] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ***************** Pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // ***************** View Cost Modal *********************
  const [selectedItem, setSelectedItem] = useState()
  const [viewCostModal, setViewCostModal] = useState()
  const viewCostToggle = () => {
    setViewCostModal(!viewCostModal)
  }



  // **************** Filter Section ************************
  const initialFilteredValues = {
    category: [],
    referenceNo: [],
    startDate: "",
    endDate: "",
    region: [],

    floorsAreaFrom: "",
    floorsAreaTo: "",

    estProjectCostFrom: "",
    estProjectCostTo: "",

    IDICostFrom: "",
    IDICostTo: "",

    estCostRatioFrom: "",
    estCostRatioTo: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  const prevFilteredValues = usePrevious(filteredValues);

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      Category: filteredValues.category.map(item => item.value).join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
      Region: filteredValues.region.map(item => item.value).join(","),

      TAreaFrom: "",
      TAreaTo: "",
      EstPCostFrom: "",
      EstPCostTo: "",
      IDICostFrom: "",
      IDICostTo: "",
      RatioFrom: "",
      RatioTo: "",
    }

    if (
      filteredValues.floorsAreaFrom * 1 >= 0 &&
      filteredValues.floorsAreaTo * 1 >= 0 &&
      filteredValues.floorsAreaTo * 1 >= filteredValues.floorsAreaFrom * 1
    ) {
      formData.TAreaFrom = filteredValues.floorsAreaFrom
      formData.TAreaTo = filteredValues.floorsAreaTo
    }

    if (
      filteredValues.estProjectCostFrom * 1 >= 0 &&
      filteredValues.estProjectCostTo * 1 >= 0 &&
      filteredValues.estProjectCostTo * 1 >=
        filteredValues.estProjectCostFrom * 1
    ) {
      formData.EstPCostFrom = filteredValues.estProjectCostFrom
      formData.EstPCostTo = filteredValues.estProjectCostTo
    }

    if (
      filteredValues.IDICostFrom * 1 >= 0 &&
      filteredValues.IDICostTo * 1 >= 0 &&
      filteredValues.IDICostTo * 1 >= filteredValues.IDICostFrom * 1
    ) {
      formData.IDICostFrom = filteredValues.IDICostFrom
      formData.IDICostTo = filteredValues.IDICostTo
    }

    if (
      filteredValues.estCostRatioFrom * 1 >= 0 &&
      filteredValues.estCostRatioTo * 1 >= 0 &&
      filteredValues.estCostRatioTo * 1 >= filteredValues.estCostRatioFrom * 1
    ) {
      formData.RatioFrom = filteredValues.estCostRatioFrom
      formData.RatioTo = filteredValues.estCostRatioTo
    }

    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      
      setPage(1)
    }


    axios
      .post(
        "https://test.rdapp.net/api/CostEstimation/ProjectCostEstimation.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =========>", res)
        setTotalPages(res.data.TotalPages)
        let array = Object.values(res.data)
        array.splice(-4)
        // console.log("array", array)
        setProjectCosts(array)
      })
      .catch(err => console.log(err))
  }, [editing, page, filteredValues])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R12.P !== "1" &&
    userPermissions.R12.P !== "2" &&
    userPermissions.R12.P !== "3" &&
    userPermissions.R12.P !== "4" &&
    userPermissions.R12.G !== "1"
  ) {
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Project Costs" />

            {/* ********************** Filter Section ***************************** */}
            <FilterProjectCosts
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />

            {/* ********************** Table Section ***************************** */}
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Category</th>
                          <th scope="col">Reference No</th>
                          <th scope="col">Project Added Date</th>
                          <th scope="col">Region</th>
                          <th scope="col">
                            Est Cost/m<sup>2</sup>
                          </th>
                          <th scope="col">Total Floors Area</th>
                          <th scope="col">Est Project Cost</th>
                          <th scope="col">IDI Cost</th>
                          <th scope="col">Est Cost Ratio</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      {/***********table body *****************/}
                      <tbody>
                        {projectCosts.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                // value={item.Code}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                  // selectedTicketsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>
                              <i
                                className={
                                  item.BuildingCategory === "Residential"
                                    ? "fas fa-home"
                                    : item.BuildingCategory === "Hotels"
                                    ? "fas fa-hotel"
                                    : item.BuildingCategory ===
                                      "less than 23 meters"
                                    ? "fas fa-building"
                                    : item.BuildingCategory ===
                                      "Commercial Centers"
                                    ? "fas fa-store"
                                    : item.BuildingCategory ===
                                      "Entertainment Services"
                                    ? "fas fa-skating"
                                    : item.BuildingCategory ===
                                      "High Rise Towers"
                                    ? "fas fa-building"
                                    : item.BuildingCategory ===
                                      "High risk buildings"
                                    ? "fas fa-building"
                                    : item.BuildingCategory === "Motels"
                                    ? "fas fa-hotel"
                                    : item.BuildingCategory === "Educational"
                                    ? "fas fa-school"
                                    : item.BuildingCategory ===
                                      "Gathering buildings Mosques"
                                    ? "fas fa-mosque"
                                    : item.BuildingCategory ===
                                      "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                                    ? "fas fa-building"
                                    : item.BuildingCategory ===
                                      "Gathering buildings Sports Premises"
                                    ? "fas fa-running"
                                    : item.BuildingCategory === "Warehouses"
                                    ? "fas fa-warehouse"
                                    : item.BuildingCategory === "Industrial"
                                    ? "fas fa-industry"
                                    : item.BuildingCategory ===
                                      "Furnished Hotel Apartments"
                                    ? "fas fa-hotel"
                                    : item.BuildingCategory ===
                                      "Gathering buildings Wedding Halls Cinemas Theaters"
                                    ? "fas fa-warehouse"
                                    : item.BuildingCategory === "Hospitals"
                                    ? "fas fa-hospital-alt"
                                    : item.BuildingCategory ===
                                      "Healthcare Centers"
                                    ? "fas fa-hospital"
                                    : item.BuildingCategory ===
                                      "Telecommunication Towers"
                                    ? "fas fa-broadcast-tower"
                                    : ""
                                }
                                id="categorytooltip"
                              />
                              <UncontrolledTooltip
                                placement="left"
                                target="categorytooltip"
                              >
                                {item.BuildingCategory}
                              </UncontrolledTooltip>
                            </td>

                            <td>{item.ReferenceNo}</td>
                            <td>{item.projectAdddedDate}</td>
                            <td>{item.Region}</td>
                            <td>{item.EMCost}</td>
                            <td>{item.TArea}</td>
                            <td>{item.ECost}</td>
                            <td>{item.PCost}</td>
                            {/* <td>{item.Ratio}%</td> */}
                            <td>
                              <span
                                className={
                                  item.Ratio * 1 >= 120 && item.Ratio * 1 < 130
                                    ? "badge bg-warning"
                                    : item.Ratio * 1 >= 130
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.Ratio} %
                              </span>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewCostToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {/* ******************** Pagination *************************** */}
            {projectCosts.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}

            {/* ********************  View Cost Modal *************************** */}
            {viewCostModal && (
              <ViewCost
                viewCostToggle={viewCostToggle}
                selectedItem={selectedItem}
                editing={editing}
                setEditing={setEditing}
              />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ProjectCosts
