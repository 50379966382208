import React, { useState } from "react"
import { Modal, Spinner, Col } from "reactstrap"

function LoadingComponent({ response, setResponse }) {
  const close = () => {
    setResponse(!response)
  }
  return (
    <div>
      {!response && (
        <Modal isOpen={!response} toggle={close} className="bg-transparent">
          <div className="d-flex my-2 mx-2 ms-3 ">
            
            <h5 className="">...Loading</h5>
            <div className="mt-1">
              <Spinner
                type="grow"
                className="ms-2 "
                size="sm"
                color="secondary"
              />
              <Spinner
                type="grow"
                className="ms-2"
                size="sm"
                color="secondary"
              />
              <Spinner
                type="grow"
                className="ms-2 "
                size="sm"
                color="secondary"
              />
            </div>
            <Col className="col-2">
              <button
                type="button"
                className="btn-close position-absolute end-0 bottom-0 m-3 mt-1 "
                onClick={close}
              ></button>
            </Col>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default LoadingComponent
