import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Form, FormGroup, Input, Table } from "reactstrap"

const StructureType = ({ setAuth }) => {
  const [structureList, setStructureList] = useState([])
  const [editing, setEditing] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post(
        "https://test.rdapp.net/api/CostEstimation/structureTypeList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setStructureList(array)
      })
      .catch(err => console.log(err))
  }, [editing])

  const initialValues = {}
  structureList.map((item, i) => {
    initialValues[`CostPer-${i + 1}`] = item.CostPer
  })

  const validateHandler = values => {
    let errors = {}

    for (let i = 0; i < structureList.length; i++) {
      if (values[`CostPer-${i + 1}`] < 0)
        errors[`CostPer-${i + 1}`] = "value must be greater than or equal to 0"
      if (values[`CostPer-${i + 1}`] === "")
        errors[`CostPer-${i + 1}`] = "required"
    }

    console.log("values ===========>", values)
    console.log("errors ===========>", errors)

    return errors
  }

  const submitHandler = values => {

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      structureTypeArray: [],
    }

    for (let i = 0; i < structureList.length; i++) {
      formData.structureTypeArray.push({
        STID: structureList[i].STID,
        CostPer: values[`CostPer-${i + 1}`],
      })
    }

    axios
      .post(
        "https://test.rdapp.net/api/CostEstimation/EditstructureType.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        setEditing(!editing)
      })
      .catch(err => console.log(err))
  }

  return (
    <div style={{ width: "60%" }}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateHandler}
        onSubmit={submitHandler}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Table className="project-list-table table-nowrap align-middle text-center table-borderless">
              <thead>
                <tr>
                  <th>Structure Type</th>
                  <th>Additional Cost Percentage %</th>
                </tr>
              </thead>
              <tbody>
                {structureList.map((item, i) => (
                  <tr key={i}>
                    <td>{item.Name}</td>
                    <td>
                      <Input
                        type="number"
                        min={0}
                        name={`CostPer-${i + 1}`}
                        id={`CostPer-${i + 1}`}
                        value={props.values[`CostPer-${i + 1}`]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control"
                        placeholder="Cost Percentage ..."
                      />
                      {props.touched[`CostPer-${i + 1}`] &&
                        props.errors[`CostPer-${i + 1}`] && (
                          <span className="error mx-1 d-flex justify-content-end">
                            {props.errors[`CostPer-${i + 1}`]}
                          </span>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-end">
              <Button
                type="submit"
                className="bg-primary"
                // disabled={
                //   userPermissions.R7.P !== "3" && userPermissions.R7.P !== "4"
                // }
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StructureType
