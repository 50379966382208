import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"

import CostsForm from "./CostsForm"
import UsersForm from "./UsersForm"
import DetailsForm from "./DetailsForm"
import axios from "axios"
import LoadingInsider from "common/LoadingInsider"

const EditProjectData = ({
  editToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  const [modal, setModal] = useState(true)
  const toggle = () => {
    editToggle()
  }

  const onClose = () => {
    editToggle()
  }

  // console.log("selectedItem", selectedItem)
  const [project, setProject] = useState()

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Ref: selectedItem.ReferenceNo,
    }

    axios
      .post("https://test.rdapp.net/api/ProjectsView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-4)
        // console.log("array", array[0])
        setProject(array[0])
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Update Project Data</ModalHeader>
        <ModalBody>
          {project ? (
            <div>
              <Tabs id="justify-tab-example" className="mb-3" justify fill>
                <Tab eventKey="costs" title="Project Costs">
                  <CostsForm
                    project={project}
                    onClose={onClose}
                    editing={editing}
                    setEditing={setEditing}
                    setAuth={setAuth}
                  />
                </Tab>
                <Tab eventKey="project-users" title="Project Users">
                  <UsersForm
                    project={project}
                    onClose={onClose}
                    editing={editing}
                    setEditing={setEditing}
                    setAuth={setAuth}
                  />
                </Tab>
                <Tab eventKey="project" title="Project Details">
                  <DetailsForm
                    project={project}
                    onClose={onClose}
                    editing={editing}
                    setEditing={setEditing}
                    setAuth={setAuth}
                  />
                </Tab>
              </Tabs>
            </div>
          ) : (
            <LoadingInsider type="spin" color="gray" />
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditProjectData
