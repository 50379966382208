import React, { useState } from "react"
import { Row, Col, Card, Form, CardBody, Container, Modal } from "reactstrap"
import Dropzone from "react-dropzone"
import LoadingComponent from "common/LoadingComponent"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

//   const [selectedFiles, setselectedFiles] = useState([])
{
  /* <ImageDropzone
selectedFiles={selectedFiles}
setselectedFiles={setselectedFiles}            
/> */
}

function ImageDropzone({ setselectedFiles, selectedFiles }) {

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles([...selectedFiles, ...files])
  }
  const dropfile = file => {
    let temp = [...selectedFiles]
    const index = temp.indexOf(file)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setselectedFiles([...temp])
  }
  return (
    <>
      <Card>
        <CardBody>
          <Form>
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
              noKeyboard={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} onClick={e=>e.stopPropagation()}/>
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row
                        className="align-items-center"
                        style={{ height: "100px" }}
                      >
                        <Col className="col-4">
                          <img
                            data-dz-thumbnail=""
                            style={{ height: "100%" }}
                            className="avatar-xl rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        <Col className="col-2">
                          <button
                            type="button"
                            className="btn-close position-absolute end-0 bottom-0 m-3"
                            onClick={() => dropfile(f)}
                          ></button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
          </Form>

          {/* <div className="text-center mt-4">
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendFiles}
            >
              Send Files
            </button>
          </div> */}
        </CardBody>
      </Card>
    </>
  )
}

export default ImageDropzone
