import React, { useState } from "react"
import { Modal, Spinner, Col, ModalHeader, ModalBody } from "reactstrap"

const UnAuthorizedComponent = () => {
  return (
    <div>
      <Modal isOpen={open} className="bg-transparent modal-md">
        <ModalHeader className="error">
          Unauthorization{" "}
          <div className="position-absolute end-0 top-0 m-3">
            <Spinner
              type="grow"
              className="ms-2 "
              size="sm"
              color="secondary"
            />
            <Spinner type="grow" className="ms-2" size="sm" color="secondary" />
            <Spinner
              type="grow"
              className="ms-2 "
              size="sm"
              color="secondary"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="text-center my-4">
            <h5>Your session is expired !!</h5>
            <h5>You will be redirected to log in again in seconds</h5>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default UnAuthorizedComponent
