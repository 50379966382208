import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import NewTicketModal from "./NewTicketModal"
import ConfirmingAction from "common/ConfirmingAction"

const TicketSearch = ({
  TicketSearchToggle,
  ticketSearchModal,
  setTicketData,
  newTicketToggle,
  ConfirmeNewTickettoggle,
  AddNewTicketFunction,
}) => {
  const onClose = () => {
    TicketSearchToggle()
  }

  const [referenceNo, setReferenceNo] = useState("")
  const [projectID, setProjectID] = useState("")
  const [referenceNoError, setReferenceNoError] = useState(
    "valid reference no. required!"
  )
  useEffect(() => {
    setTicketData({ ReferenceNo: referenceNo, ProjectID: projectID })
  }, [referenceNo, projectID])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: referenceNo,
    }
    axios
      .post("https://test.rdapp.net/api/RefCheck.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === true) {
          setReferenceNoError("valid reference no. required!")
        }

        if (res.data.error === false) {
          setReferenceNoError("")(setProjectID(res.data.ProjectID))
        }
      })
      .catch(err => console.log(err))
  }, [referenceNo])
  // const AddNewTicketFunction = () => {
  //   newTicketToggle()
  //   TicketSearchToggle()
  // }

  // // **********confirm New Ticket Action action***************
  // const [ConfirmNewTicketModel, setConfirmNewTicketModel] = useState(false)
  // const ConfirmeNewTickettoggle = () => {
  //   setConfirmNewTicketModel(!ConfirmNewTicketModel)
  // }
  // const confirmFunc = () => {
  //   AddNewTicketFunction()
  // }

  return (
    <div>
      <Modal
        className="modal-md"
        isOpen={ticketSearchModal}
        toggle={TicketSearchToggle}
      >
        <ModalHeader>
          New Ticket
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Refrence No</Label>
                <Input
                  type="number"
                  name="ReferenceNo"
                  value={referenceNo}
                  onChange={e => {
                    setReferenceNo(e.target.value)
                  }}
                />

                <p className="error px-1 w-100">{referenceNoError}</p>
              </FormGroup>
            </Col>

      
              
                  <FormGroup className="d-flex ms-auto mt-3 justify-content-between">
                    <Button
                      onClick={() => {
                        onClose()
                      }}
                      className="bg-primary"
                    >
                      close
                    </Button>

                    {!referenceNoError && (
                      <Button type="submit" className="bg-primary" onClick={AddNewTicketFunction}>
                        {"Create New Ticket"}
                      </Button>
                    )}
                  </FormGroup>
                
             
        
          </Row>
        </ModalBody>
      </Modal>

      {/* *********confirm New Ticket Action action******** */}
      {/* {ConfirmNewTicketModel && (
        <ConfirmingAction
          confirmFunc={confirmFunc}
          action={"warning"}
          ConfirmeNewTickettoggle={ConfirmeNewTickettoggle}
          ConfirmNewTicketModel={ConfirmNewTicketModel}
          massege={"Are you sure that you want to add ticket and send it to the client immediately ?"}
        />
      )} */}
    </div>
  )
}

export default TicketSearch
