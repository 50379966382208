import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { Col, Row, Table } from "reactstrap"
import ImageFullOptions from "common/ImageFullOptions"


function SolvingData({ data, setAuth }) {
    // image
    const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
    const toggleImageFullOptions = () => {
      setViewImageFullOptions(!viewImageFullOptions)
    }
  return (
    <Row className="mt-2">
    <Col lg="12">
      <div className="">
        <div className="table-responsive">
          {/******************* table section**************************/}
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col">Image_Name</th>
                <th scope="col">UpdaterID</th>                 
                <th scope="col">Type</th>
                <th scope="col">Updater</th>
                <th scope="col">Attached</th>
              </tr>
            </thead>

            {/***********table body *****************/}
            <tbody>               
                <React.Fragment >
                {data.map((item, key) => (
                    <tr style={{ position: "relative" }} key={key}>
                      <td>{item.FileNamne}</td>
                      <td>{item.UpdateID}</td>
                      <td>{item.Action}</td>
                      <td>{item.UserName}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm "
                          onClick={toggleImageFullOptions}
                        >
                          preview
                        </button>
                      </td>
                      <td>
                        <a
                          href={item.File}
                          download
                            target="_blank"
                                 rel="noreferrer"
                        
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="btn btn-primary"
                          />
                        </a>
                      </td>
                  { viewImageFullOptions &&   <ImageFullOptions
                        viewImageFullOptions={viewImageFullOptions}
                        toggleImageFullOptions={toggleImageFullOptions}
                        name={`Attached`}
                        target={"Image"}
                        setAuth={setAuth}
                        value={[item.File]}
                        StageID={10}
                        type={"view"}
                      />}
                    </tr>
                  ))}
                </React.Fragment>
           
            </tbody>
          </Table>
        </div>
      </div>
    </Col>
  </Row>
  )
}

export default SolvingData