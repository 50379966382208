import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import { convertPermission } from "permissionUtils"
import ExampleTree from "./ExampleTree"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

const CompanyStructure = () => {
  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R9.P !== "1" &&
    userPermissions.R9.P !== "2" &&
    userPermissions.R9.P !== "3" &&
    userPermissions.R9.P !== "4" &&
    userPermissions.R9.G !== "1" &&
    userPermissions.R1.G !== "1" &&
    userPermissions.R2.G !== "1" &&
    userPermissions.R3.G !== "1" &&
    userPermissions.R4.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Settings" breadcrumbItem="Company Structure" />

            {/* ******************* Example Tree ******************** */}
            <div
              style={{
                overflowX: "scroll",
                paddingBottom: "40px",
                minHeight: "100vh",
              }}
            >
              <ExampleTree
                setAuth={setAuth}
                userPermissions={userPermissions}
              />
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default CompanyStructure
