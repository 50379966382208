import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"

const ViewRDDRReport = ({
  viewToggle,
  selectedItem,
  userPermissions,
  setAuth,
  editing,
  setEditing,
  isManager,
}) => {
  // console.log("selectedItem", selectedItem)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewToggle()
  }

  const onClose = () => {
    viewToggle()
  }

  const history = useHistory()
  const [reportStatus, setReportStatus] = useState(selectedItem.Status)
  const [rejectedReason, setRejectedReason] = useState(
    selectedItem.RejectedReason || ""
  )

  const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "Rejected", label: "Rejected" },
  ]

  // ************************ Reports History *********************************
  const [reportsHistory, setReportsHistory] = useState([])
  // console.log("reportsHistory", reportsHistory)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Type: "DR",
      ProjectID: selectedItem.ProjectID,
    }
    axios
      .post(
        "https://test.rdapp.net/api/Reports/Report_Rev_List.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log(res)
          let array = Object.values(res.data)
          array.splice(-1)
          setReportsHistory(array)
        }
      })
      .catch(err => console.log(err))
  }, [])

  console.log("reportStatus", reportStatus)
  const updateHandler = values => {
    history.push(`/rd-DR/${selectedItem.ProjectID}`)
    if (reportStatus !== selectedItem.Status) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        SPID: selectedItem.SPID,
        PStageID: selectedItem.PStageID,
        Status: reportStatus,
        RejectedReason: rejectedReason,
        Type: "DR",
      }
      console.log("formData", formData)

      axios
        .post(
          "https://test.rdapp.net/api/Reports/Edit_Report.php",
          formData
        )
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          setEditing(!editing)
        })
        .catch(err => console.log(err))
    }

    // history.push(`/RD-DR-reasons/${selectedItem.ProjectID}`)

    onClose()
  }

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View RD-DR Report
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Tabs className="mb-5" justify fill>
            <Tab eventKey="report" title="RD-1 Report">
              <Table bordered>
                <tbody>
                  <tr>
                    <th>Reference No</th>
                    <td>{selectedItem.ReferenceNo}</td>
                    <th>Soil Report</th>
                    <td>{selectedItem.Soil}</td>
                  </tr>

                  <tr>
                    <th>Type</th>
                    <td>{selectedItem.Rtype}</td>
                    <th>Status</th>
                    <td>
                      <span
                        className={
                          selectedItem.Status == "Approved"
                            ? "badge bg-success"
                            : selectedItem.Status == "Rejected"
                            ? "badge bg-danger"
                            : selectedItem.Status == "Pending"
                            ? "badge bg-warning"
                            : ""
                        }
                      >
                        {selectedItem.Status}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th>Designer</th>
                    <td>{selectedItem.DesignerOfficeName}</td>
                    <th>Contractor</th>
                    <td>{selectedItem.ConstructorName}</td>
                  </tr>
                </tbody>
              </Table>

              {isManager && (
                <Form className="ms-1">
                  <Row className="mt-5">
                    <Col sm={3} className="d-flex align-items-center">
                      <Label htmlFor="report-status">
                        Change Report Status
                      </Label>
                    </Col>
                    <Col sm={3}>
                      <Input
                        type="select"
                        name="status"
                        id="report-status"
                        value={reportStatus}
                        onChange={e => {
                          // console.log("e", e.target.value)
                          setReportStatus(e.target.value)
                        }}
                        className="form-control border-dark"
                        disabled={selectedItem.Status !== "Pending"}
                      >
                        {statusOptions.map((status, i) => (
                          <option key={i} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  {reportStatus === "Rejected" && (
                    <Row className="mt-3 ">
                      <Col sm={3} className="d-flex align-items-center">
                        <Label htmlFor="report-status">Reject reason :</Label>
                      </Col>
                      <Col>
                        <Input
                          sm={8}
                          type="textarea"
                          name="RejectReason"
                          id="report-status"
                          defaultValue={selectedItem.RejectedReason}
                          onChange={e => {
                            setRejectedReason(e.target.value)
                          }}
                          className="form-control border-dark"
                          disabled={selectedItem.RejectedReason}
                        />
                      </Col>
                    </Row>
                  )}
                </Form>
              )}
            </Tab>

            <Tab eventKey="history" title="Report History">
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "scroll",
                  paddingBottom: "30px",
                }}
              >
                <Table bordered className="text-center mb-5">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Rev</th>
                      <th>By</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {selectedItem.Rtype === "Full"
                      ? reportsHistory.map((item, i) => {
                          console.log("item", item)

                          return (
                            <tr key={i} style={{ position: "relative" }}>
                              <th>{i + 1}</th>
                              <td>{item.StageDate}</td>
                              <td>
                                {item.Rev?.length === 1
                                  ? `0${item.Rev}`
                                  : item.Rev}
                              </td>
                              <td>{item.UserName}</td>
                              <td>
                                <div>
                                  <span
                                    className={
                                      item.Status == "Approved"
                                        ? "badge bg-success"
                                        : item.Status == "Rejected"
                                        ? "badge bg-danger"
                                        : item.Status == "Pending"
                                        ? "badge bg-warning"
                                        : ""
                                    }
                                    id={"Status" + i}
                                  >
                                    {item.Status}
                                  </span>
                                  {item.RejectedReason && (
                                    <UncontrolledTooltip
                                      placement="left"
                                      target={"Status" + i}
                                    >
                                      {item.RejectedReason}
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </td>
                              <td>
                                <a
                                  href={
                                    selectedItem.Rtype === "Simple"
                                      ? item.LinkS
                                      : item.LinkC
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                  download
                                >
                                  Export
                                </a>
                              </td>
                            </tr>
                          )
                        })
                      : reportsHistory.map((item, i) => {
                          console.log("item", item)

                          return (
                            <tr style={{ position: "relative" }} key={i}>
                              <th>{1}</th>
                              <td>{selectedItem.CreatedAt}</td>
                              <td>
                                {selectedItem.Rev?.length === 1
                                  ? `0${selectedItem.Rev}`
                                  : selectedItem.Rev}
                              </td>
                              <td>{selectedItem.UserName}</td>
                              <td>
                                <div>
                                  <span
                                    className={
                                      selectedItem.Status == "Approved"
                                        ? "badge bg-success"
                                        : selectedItem.Status == "Rejected"
                                        ? "badge bg-danger"
                                        : selectedItem.Status == "Pending"
                                        ? "badge bg-warning"
                                        : ""
                                    }
                                    id={"Status" + 1}
                                  >
                                    {selectedItem.Status}
                                  </span>
                                  {selectedItem.RejectedReason && (
                                    <UncontrolledTooltip
                                      placement="left"
                                      target={"Status" + i}
                                    >
                                      {selectedItem.RejectedReason}
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </td>
                              <td>
                                <a
                                  href={
                                    item.LinkS //projectID
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  <button
                                    onClick={() => {
                                      // setSelectedItem(item)
                                      // viewToggle()
                                    }}
                                    className="btn btn-primary btn-sm dropdown-toggle"
                                  >
                                    Export
                                  </button>
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>

          <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
            {reportStatus === "Rejected" && !rejectedReason && (
              <Row className="d-flex ms-auto">
                <p className="error">You should type rejected reason </p>
              </Row>
            )}
            <div className="col-3 d-flex ms-auto justify-content-around">
              <Button className="bg-primary" onClick={onClose}>
                Close
              </Button>

              <Button
                className="bg-primary"
                onClick={
                  !(reportStatus === "Rejected" && !rejectedReason) &&
                  updateHandler
                }
              >
                Update
              </Button>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewRDDRReport
