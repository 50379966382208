import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input } from "reactstrap"

const FilterProjects = ({
  filteredValues,
  setFilteredValues,
  categories,
  setCategories,
  stages,
  setStages,
  setAuth,
}) => {
  const history = useHistory()

  const formData = {
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
  }

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
    }
    axios
      .post("https://test.rdapp.net/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        // console.log("data", data)
        setCategories(data.map(item => item[1]))
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // console.log("stages", stages)
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/Stages/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        setStages(array.map(item => item[1].StageName))
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const filteredFeatures = [
    "Category",
    "Reference No.",
    "Date",
    "Type",
    "Payment",
    "RD0Status",
    // "InsStages"
    "SP",
    "MS",
  ]
  const statusOptions = [
    "Pending",
    "Approved",
    "Rejected",
    "Manual",
    "Out_Of_RDAPP",
    "Missing Data",
    "Manger Approval",
    "Under Review",
    "Edit Required",
    "InProgress",
  ]
  const InsStage = [
    "Pending",
    "Approved",
    "Rejected",
    "Manual",
    "Out_Of_RDAPP",
    "Missing Data",
    "Manger Approval",
    "Under Review",
    "Edit Required",
    "InProgress",
  ]

  return (
    <div className="d-flex justify-content-center flex-wrap">
      {filteredFeatures.map((feature, i) =>
        feature === "Status" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1" key={i}>
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="status"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      Status: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Last Stage...
                  </option>
                  {stages.map((stage, i) => (
                    <option key={i} value={stage.StageName}>
                      {stage.StageName}
                    </option>
                  ))}
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "RD0Status" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="status"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      RD0Status: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Status...
                  </option>
                  {statusOptions.map((status, i) => (
                    <option key={i} value={status}>
                      {status}
                    </option>
                  ))}
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "Payment" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="payment"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      Payment: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Payment ...
                  </option>

                  <option value="P">Paid</option>
                  <option value="N">not Paid</option>
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "Category" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex col-12">
                <input
                  list="categoriesList"
                  placeholder="Category..."
                  name="category"
                  onChange={e => {
                    setFilteredValues({
                      ...filteredValues,
                      Category: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                />
                <datalist id="categoriesList">
                  {categories.map((category, i) => (
                    <option key={i} value={category.Name} />
                  ))}
                </datalist>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "SP" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="SP"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      SP: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Classification...
                  </option>
                  <option value="1">SP</option>
                  <option value="0">RP</option>
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "MS" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="MS"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      MS: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    MissingStage...
                  </option>
                  <option value="1">MS</option>
                  <option value="0">FS</option>
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "Type" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="type"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      Type: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Type...
                  </option>
                  <option value="FT">FT</option>
                  <option value="NT">NT</option>
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type={
                    feature === "Reference No."
                      ? "number"
                      : feature === "Date"
                      ? "date"
                      : "text"
                  }
                  className="form-control border-dark"
                  placeholder={feature}
                  onChange={e => {
                    let updatedObject = {}

                    feature === "Reference No."
                      ? (updatedObject["ReferenceNo"] = e.target.value)
                      : (updatedObject[feature] = e.target.value)

                    setFilteredValues({
                      ...filteredValues,
                      ...updatedObject,
                    })
                  }}
                />
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default FilterProjects
