import { Formik } from "formik"
import * as yup from "yup"
import React from "react"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const DetailsForm = ({ project, onClose, editing, setEditing, setAuth }) => {
  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const projectValidationSchema = yup.object().shape({
    projectAddress: yup.string().required("required"),
    projectName: yup.string().required("required"),
    soil: yup.string().required("required"),
    startDate: yup.string().required("required"),
    endDate: yup.string().required("required"),
    occupancyDate: yup.string().required("required"),
  })

  const submitHandler = (values, onSubmitProps) => {
    onSubmitProps.resetForm()
    // console.log("values", values)
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: project.ProjectID,
      Action: "Details",
      ProjectAddress: values.projectAddress,
      ProjectName: values.projectName,
      Soil: values.soil,
      StartDate: values.startDate,
      EndDate: values.endDate,
      OccupancyDate: values.occupancyDate,
    }

    axios
      .post("https://test.rdapp.net/api/EditProject", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        // updating project header data by useEffect dependencies
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    // console.log("formData========>", formData)
    onClose()
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          projectAddress: project.ProjectAddress || "",
          projectName: project.ProjectName || "",
          soil: project.Soil || "",
          startDate: project.StartDate || "",
          endDate: project.EndDate || "",
          occupancyDate: project.OccupancyDate || "",
        }}
        validationSchema={projectValidationSchema}
        onSubmit={submitHandler}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="project-address">Project Address</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="project-address"
                  name="projectAddress"
                  value={props.values["projectAddress"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Project Address ..."
                />
              </Col>
              {props.touched.projectAddress && props.errors.projectAddress ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.projectAddress}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="project-name">Project Name</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="project-name"
                  name="projectName"
                  value={props.values["projectName"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Project Name ..."
                />
              </Col>
              {props.touched.projectName && props.errors.projectName ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.projectName}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="soil">Soil</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="soil"
                  name="soil"
                  value={props.values["soil"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="string"
                  className="form-control"
                  placeholder="Soil ..."
                />
              </Col>
              {props.touched.soil && props.errors.soil ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.soil}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="start-date">Start Date</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="start-date"
                  name="startDate"
                  value={props.values["startDate"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="date"
                  className="form-control"
                  placeholder="Start Date ..."
                />
              </Col>
              {props.touched.startDate && props.errors.startDate ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.startDate}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="end-date">End Date</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="end-date"
                  name="endDate"
                  value={props.values["endDate"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="date"
                  className="form-control"
                  placeholder="End Date ..."
                />
              </Col>
              {props.touched.endDate && props.errors.endDate ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.endDate}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="occupancy-date">Occupancy Date</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="occupancy-date"
                  name="occupancyDate"
                  value={props.values["occupancyDate"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="date"
                  className="form-control"
                  placeholder="Occupancy Date ..."
                />
              </Col>
              {props.touched.occupancyDate && props.errors.occupancyDate ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.occupancyDate}
                </span>
              ) : null}
            </Row>

            <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-primary"
                disabled={
                  userPermissions.R7.P !== "3" && userPermissions.R7.P !== "4"
                }
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DetailsForm
