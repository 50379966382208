import { Formik } from "formik"
import * as yup from "yup"
import React from "react"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const CostsForm = ({ project, onClose, editing, setEditing, setAuth }) => {
  // console.log("project------->", project)

  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const costsValidationSchema = yup.object().shape({
    cost: yup.number().required("required"),
    constructionCosts: yup.number().required("required"),
    nonConstructionCosts: yup.number().required("required"),
    protectiveItemsCosts: yup.number().required("required"),
    equipmentsCosts: yup.number().required("required"),
    outdoorCosts: yup.number().required("required"),
    designFees: yup.number().required("required"),
    tax: yup.number().required("required"),
  })

  const submitHandler = (values, onSubmitProps) => {
    onSubmitProps.resetForm()
    // console.log("values", values)

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: project.ProjectID,
      Action: "Costs",
      Cost: values.cost,
      ConstructionCosts: values.constructionCosts,
      NonConstructionCosts: values.nonConstructionCosts,
      ProtectiveItemsCosts: values.protectiveItemsCosts,
      EquipmentsCosts: values.equipmentsCosts,
      OutdoorCosts: values.outdoorCosts,
      DesignFees: values.designFees,
      Tax: values.tax,
    }

    axios
      .post("https://test.rdapp.net/api/EditProject", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        // updating project header data by useEffect dependencies
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    // console.log("formData========>", formData)
    onClose()
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          cost: project.Cost || "",
          constructionCosts: project.ConstructionCosts || "",
          nonConstructionCosts: project.NonConstructionCosts || "",
          protectiveItemsCosts: project.ProtectiveItemsCosts || "",
          equipmentsCosts: project.EquipmentsCosts || "",
          outdoorCosts: project.OutdoorCosts || "",
          designFees: project.DesignFees || "",
          tax: project.Tax || "",
        }}
        validationSchema={costsValidationSchema}
        onSubmit={submitHandler}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="cost">Cost</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="cost"
                  name="cost"
                  value={props.values["cost"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Cost ..."
                />
              </Col>
              {props.touched.cost && props.errors.cost ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.cost}
                </span>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="construction-costs">Construction Costs</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="construction-costs"
                  name="constructionCosts"
                  value={props.values["constructionCosts"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Construction Costs ..."
                />
              </Col>
              {props.touched.constructionCosts &&
              props.errors.constructionCosts ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.constructionCosts}
                </span>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="nonConstruction-costs">
                  Non Construction Costs
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  id="nonConstruction-costs"
                  name="nonConstructionCosts"
                  value={props.values["nonConstructionCosts"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Non Construction Costs ..."
                />
              </Col>
              {props.touched.nonConstructionCosts &&
              props.errors.nonConstructionCosts ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.nonConstructionCosts}
                </span>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="protectiveItems-costs">
                  Protective Items Costs
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  id="protectiveItems-costs"
                  name="protectiveItemsCosts"
                  value={props.values["protectiveItemsCosts"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Protective Items Costs ..."
                />
              </Col>
              {props.touched.protectiveItemsCosts &&
              props.errors.protectiveItemsCosts ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.protectiveItemsCosts}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="equipments-costs">Equipments Costs</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="equipments-costs"
                  name="equipmentsCosts"
                  value={props.values["equipmentsCosts"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Equipments Costs ..."
                />
              </Col>
              {props.touched.equipmentCosts && props.errors.equipmentCosts ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.equipmentCosts}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="outdoor-costs">Outdoor Costs</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="outdoor-costs"
                  name="outdoorCosts"
                  value={props.values["outdoorCosts"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Outdoor Costs ..."
                />
              </Col>
              {props.touched.outdoorCosts && props.errors.outdoorCosts ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.outdoorCosts}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="design-fees">Design Fees</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="design-fees"
                  name="designFees"
                  value={props.values["designFees"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Design Fees ..."
                />
              </Col>
              {props.touched.designFees && props.errors.designFees ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.designFees}
                </span>
              ) : null}
            </Row>

            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <Label htmlFor="tax">Tax</Label>
              </Col>
              <Col md={8}>
                <Input
                  id="tax"
                  name="tax"
                  value={props.values["tax"]}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className="form-control"
                  placeholder="Tax ..."
                />
              </Col>
              {props.touched.tax && props.errors.tax ? (
                <span className="error" style={{ textAlign: "right" }}>
                  {props.errors.tax}
                </span>
              ) : null}
            </Row>

            <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-primary"
                disabled={
                  userPermissions.R7.P !== "3" && userPermissions.R7.P !== "4"
                }
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CostsForm
