import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"

const ViewRD3Report = ({
  viewToggle,
  selectedItem,
  userPermissions,
  setAuth,
  editing,
  setEditing,
  cleanReport,
}) => {
  // console.log("selectedItem", selectedItem)
  // console.log("cleanReport", cleanReport)
  const [declaration, setDeclaration] = useState("")
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewToggle()
  }

  const onClose = () => {
    viewToggle()
  }

  const history = useHistory()

  const statusOptions =
    userPermissions.R2.G === "1" || userPermissions.R4.G === "1"
      ? [
          { value: "InProgress", label: "InProgress" },
          { value: "Hold", label: "Hold" },
          { value: "Completed", label: "Completed" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
          { value: "Uploaded", label: "Uploaded" },
          { value: "Unuploaded", label: "Unuploaded" },
        ]
      : [
          { value: "", label: "Select Status ..." },
          { value: "Hold", label: "Hold" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
        ]

  const validateHandler = values => {
    let errors = {}

    //status error
    if (!values.status) errors.status = "required"

    //description error
    if (
      (values.status === "Rejected" || values.status === "Hold") &&
      !values.description
    )
      errors.description = "required"

    //reject reason error
    if (
      (selectedItem.DesStatus === "Approved" ||
        selectedItem.InsStatus === "Approved") &&
      (userPermissions.R24.P === "2" ||
        userPermissions.R24.P === "3" ||
        userPermissions.R24.P === "4") &&
      values.status === "Rejected" &&
      !values.rejectReason
    ) {
      errors.rejectReason = "required"
    }

    console.log("values", values)
    console.log("errors", errors)

    return errors
  }

  const submitHandler = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD3ID: selectedItem.RD3ID,
    }
    if (values.status === "Rejected" || values.status === "Hold")
      formData.Description = values.description

    // ******for Design supervisor ************
    if (
      selectedItem.InsReport === true &&
      (userPermissions.R1.G === "1" || userPermissions.R3.G === "1")
    )
      formData.DesStatus = values.status

    // for Inspection supervisor ************
    if (
      selectedItem.InsReport === true &&
      selectedItem.AreaManagerID === localStorage.getItem("id")
    )
      formData.InsStatus = values.status

    // for Inspection Review Engineer ************
    if (
      (selectedItem.DesStatus === "Approved" ||
        selectedItem.InsStatus === "Approved") &&
      (userPermissions.R24.P === "2" ||
        userPermissions.R24.P === "3" ||
        userPermissions.R24.P === "4" ||
        userPermissions.R24.G === "1")
    ) {
      formData.InsrevStatus = values.status
      if (values.status === "Rejected") formData.InsrevTo = values.rejectReason
    }

    // for Inspection Department Manager ************
    if (
      selectedItem.InsrevStatus === "Approved" ||
      (selectedItem.DesStatus === "Approved" &&
        (userPermissions.R2.G === "1" || userPermissions.R4.G === "1"))
    )
      formData.ReportStatus = values.status

    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/RD3/EditRD3.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  const [RD3HistoryList, setRD3HistoryList] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD3ID: selectedItem.RD3ID,
    }

    axios
      .post("https://test.rdapp.net/api/RD3/RD3History.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setRD3HistoryList(array)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedItem.ProjectID,
    }
    axios
      .post(`https://test.rdapp.net/api/Declaration.php`, formData)
      .then(res => {
        setDeclaration(res.data.Declaration)
      })
      .catch(err => console.log(err))
  }, [])
  console.log("selectedItem.AreaManagerID", selectedItem.AreaManagerID)
  console.log("selectedItem.InsReport", selectedItem.InsReport)
  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View RD-3 Report
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Tabs className="mb-5" justify fill>
            <Tab eventKey="report" title="RD-3 Report">
              <Table bordered>
                <tbody>
                  <tr>
                    <th>Category</th>
                    <td>
                      <i
                        className={
                          selectedItem.Category === "Residential"
                            ? "fas fa-home"
                            : selectedItem.Category === "Hotels"
                            ? "fas fa-hotel"
                            : selectedItem.Category === "less than 23 meters"
                            ? "fas fa-building"
                            : selectedItem.Category === "Commercial Centers"
                            ? "fas fa-store"
                            : selectedItem.Category === "Entertainment Services"
                            ? "fas fa-skating"
                            : selectedItem.Category === "High Rise Towers"
                            ? "fas fa-building"
                            : selectedItem.Category === "High risk buildings"
                            ? "fas fa-building"
                            : selectedItem.Category === "Motels"
                            ? "fas fa-hotel"
                            : selectedItem.Category === "Educational"
                            ? "fas fa-school"
                            : selectedItem.Category ===
                              "Gathering buildings Mosques"
                            ? "fas fa-mosque"
                            : selectedItem.Category ===
                              "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                            ? "fas fa-building"
                            : selectedItem.Category ===
                              "Gathering buildings Sports Premises"
                            ? "fas fa-running"
                            : selectedItem.Category === "Warehouses"
                            ? "fas fa-warehouse"
                            : selectedItem.Category === "Industrial"
                            ? "fas fa-industry"
                            : selectedItem.Category ===
                              "Furnished Hotel Apartments"
                            ? "fas fa-hotel"
                            : selectedItem.Category ===
                              "Gathering buildings Wedding Halls Cinemas Theaters"
                            ? "fas fa-warehouse"
                            : selectedItem.Category === "Hospitals"
                            ? "fas fa-hospital-alt"
                            : selectedItem.Category === "Healthcare Centers"
                            ? "fas fa-hospital"
                            : selectedItem.Category ===
                              "Telecommunication Towers"
                            ? "fas fa-broadcast-tower"
                            : "fas fa-building"
                        }
                        id="categorytooltip"
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target="categorytooltip"
                      >
                        {selectedItem.Category || "N/A"}
                      </UncontrolledTooltip>
                    </td>
                    <th>Owner</th>
                    <td>{selectedItem.OwnerName}</td>
                  </tr>

                  <tr>
                    <th>Reference No</th>
                    <td>{selectedItem.ReferenceNo}</td>
                    <th>Contractor</th>
                    <td>{selectedItem.ConstructorName}</td>
                  </tr>

                  <tr>
                    <th>Type</th>
                    <td>{selectedItem.Type}</td>
                    <th>Status</th>
                    <td>{selectedItem.ReportStatus}</td>
                  </tr>

                  <tr>
                    <th>Inspector</th>
                    <td>{selectedItem.InspectionName}</td>
                  </tr>

                  <tr>
                    <th>Design Manager</th>
                    <td>{selectedItem.DesMangerName}</td>
                    <th>Area Manager</th>
                    <td>{selectedItem.AreaManagerName}</td>
                  </tr>
                </tbody>
              </Table>

              <Formik
                initialValues={{
                  // status: initialStatus,
                  description: "",
                  // description: selectedItem.Description,
                }}
                validate={validateHandler}
                onSubmit={submitHandler}
              >
                {props => (
                  <Form className="ms-1" onSubmit={props.handleSubmit}>
                    {
                      //for design supervisor
                      (userPermissions.R1.G === "1" ||
                        userPermissions.R3.G === "1" ||
                        // for inspection supervisor
                        selectedItem.AreaManagerID ===
                          localStorage.getItem("id") ||
                        // for Inspection Review Engineer
                        userPermissions.R24.P === "2" ||
                        userPermissions.R24.P === "3" ||
                        userPermissions.R24.P === "4" ||
                        // for Inspection Department Manager
                        userPermissions.R2.G === "1" ||
                        userPermissions.R4.G === "1") && (
                        <>
                          {/* ************************* Report Status ************************** */}
                          <Row className="mt-5">
                            <Col sm={3} className="d-flex align-items-center">
                              <Label htmlFor="report-status">
                                Change status of report {selectedItem.StageName}
                              </Label>
                            </Col>
                            <Col sm={4}>
                              <Input
                                type="select"
                                name="status"
                                id="report-status"
                                onChange={props.handleChange}
                                className="form-control border-dark"
                                disabled={
                                  //for design supervisor
                                  ((userPermissions.R1.G === "1" ||
                                    userPermissions.R3.G === "1") &&
                                    (selectedItem.InsReport === false ||
                                      selectedItem.DesStatus === "Approved")) ||
                                  // for inspection supervisor
                                  (selectedItem.AreaManagerID ===
                                    localStorage.getItem("id") &&
                                    (selectedItem.InsReport === false ||
                                      selectedItem.InsStatus === "Approved")) ||
                                  // for Inspection Review Engineer
                                  ((userPermissions.R24.P === "2" ||
                                    userPermissions.R24.P === "3" ||
                                    userPermissions.R24.P === "4" ||
                                    userPermissions.R24.G === "1") &&
                                    ((selectedItem.DesStatus !== "Approved" &&
                                      selectedItem.InsStatus !== "Approved") ||
                                      selectedItem.InsrevStatus ===
                                        "Approved")) ||
                                  // for Inspection Department Manager
                                  ((userPermissions.R2.G === "1" ||
                                    userPermissions.R4.G === "1") &&
                                    // selectedItem.InsrevStatus !== "Approved" ||
                                    (selectedItem.DesStatus !== "Approved" ||
                                      selectedItem.ReportStatus === "Approved" ||
                                      selectedItem.ReportStatus === "Uploaded"))
                                }
                              >
                                {statusOptions.map((status, i) => (
                                  <option
                                    key={i}
                                    value={status.value}
                                    selected={
                                      selectedItem.ReportStatus === status.value
                                    }
                                  >
                                    {status.label}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </Row>

                          {/* *************** Rejection Reason for inspection Review Engineer ****************** */}
                          {(selectedItem.DesStatus === "Approved" ||
                            selectedItem.InsStatus === "Approved") &&
                            (userPermissions.R24.P === "2" ||
                              userPermissions.R24.P === "3" ||
                              userPermissions.R24.P === "4") &&
                            props.values.status === "Rejected" && (
                              <Row className="mt-5">
                                <Col
                                  sm={3}
                                  className="d-flex align-items-center"
                                >
                                  <Label htmlFor="report-status">
                                    Rejection Department
                                  </Label>
                                </Col>
                                <Col sm={4}>
                                  <Input
                                    type="select"
                                    name="rejectReason"
                                    id="rejectReason"
                                    onChange={props.handleChange}
                                    className="form-control border-dark"
                                  >
                                    <option value="">Select Reason ...</option>
                                    <option value="Des">Design Report</option>
                                    <option value="Ins">
                                      Inspection Report
                                    </option>
                                  </Input>
                                  {props.errors.rejectReason ? (
                                    <p className="error mx-2 d-flex justify-content-end">
                                      {props.errors.rejectReason}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                            )}

                          {/* ************************* Description textarea ************************** */}
                          {(props.values.status === "Rejected" ||
                            props.values.status === "Hold") && (
                            <Row className="mt-4">
                              <Col sm={3} className="d-flex align-items-center">
                                <Label htmlFor="report-status">
                                  Description
                                </Label>
                              </Col>
                              <Col>
                                <Input
                                  type="textarea"
                                  name="description"
                                  id="report-description"
                                  value={props.values.description}
                                  onChange={props.handleChange}
                                  className="form-control border-dark"
                                />

                                {props.errors.description ? (
                                  <p className="error mx-2 d-flex justify-content-end">
                                    {props.errors.description}
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                          )}
                        </>
                      )
                    }

                    <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                      <div className="col-5 d-flex  justify-content-around">
                        {
                          <div>
                            <a
                              onClick={e =>
                                selectedItem.InsReport === false &&
                                e.preventDefault()
                              }
                              href={
                                selectedItem.InsReport === true
                                  ? 
                                  `https://inspection.cpvarabia.com/wordlib/vendor/phpoffice/phpword/samples/rd3.php?PStageID=${selectedItem.PStageID}`
                                  : "#"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              download={selectedItem.InsReport === true}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-label"
                                disabled={selectedItem.InsReport === false}
                              >
                                Export Word
                                <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                              </button>
                            </a>
                          </div>
                        }

                        <Button
                          className="bg-primary"
                          onClick={() => {
                            if (selectedItem.TicketNum * 1 > 0) {
                              // history.push(
                              //   `/rd-3/${selectedItem.ProjectID}/tickets`
                              // )
                              declaration
                                ? history.push(
                                    `/rd-3/${selectedItem.ProjectID}/tickets`
                                  )
                                : history.push(
                                    `/PrivacyATerms/${selectedItem.ProjectID}`,
                                    {
                                      data: selectedItem,
                                      from: "RD3",
                                      to: `/rd-3/${selectedItem.ProjectID}/tickets`,
                                    }
                                  )
                            } else {
                              if (
                                userPermissions.R4.P === "1" ||
                                userPermissions.R4.P === "2" ||
                                userPermissions.R4.P === "3" ||
                                userPermissions.R4.P === "4" ||
                                userPermissions.R4.G === "1"
                              ) {
                                declaration
                                  ? history.push(
                                      `/rd-3/${selectedItem.ProjectID}/visits`
                                    )
                                  : history.push(
                                      `/PrivacyATerms/${selectedItem.ProjectID}`,
                                      {
                                        data: selectedItem,
                                        from: "RD3",
                                        to: `/rd-3/${selectedItem.ProjectID}/visits`,
                                      }
                                    )
                                // history.push(
                                //   `/rd-3/${selectedItem.ProjectID}/visits`
                                // )
                              } else {
                                // history.push(`/rd-3/${selectedItem.ProjectID}`)
                                declaration
                                  ? history.push(
                                      `/rd-3/${selectedItem.ProjectID}`
                                    )
                                  : history.push(
                                      `/PrivacyATerms/${selectedItem.ProjectID}`,
                                      {
                                        data: selectedItem,
                                        from: "RD3",
                                        to: `/rd-3/${selectedItem.ProjectID}`,
                                      }
                                    )
                              }
                            }
                          }}
                          disabled={
                            //for inspector
                            ((selectedItem.InsStatus === "Rejected" ||
                              selectedItem.InsStatus === "RD3 Completion") &&
                              (userPermissions.R4.P === "2" ||
                                userPermissions.R4.P === "3" ||
                                userPermissions.R4.P === "4") &&
                              userPermissions.R4.G !== "1") ||
                            //for design supervisor
                            ((userPermissions.R1.G === "1" ||
                              userPermissions.R3.G === "1") &&
                              selectedItem.InsStatus === "Approved") ||
                            // for inspection supervisor
                            (selectedItem.AreaManagerID ===
                              localStorage.getItem("id") &&
                              selectedItem.InsStatus === "InProgress" &&
                              (userPermissions.R4.P === "2" ||
                                userPermissions.R4.P === "3" ||
                                userPermissions.R4.P === "4") &&
                              userPermissions.R4.G !== "1") ||
                            // for Inspection Review Engineer
                            ((userPermissions.R24.P === "2" ||
                              userPermissions.R24.P === "3" ||
                              userPermissions.R24.P === "4" ||
                              userPermissions.R24.G === "1") &&
                              selectedItem.DesStatus === "Approved" &&
                              selectedItem.InsrevStatus !== "Approved") ||
                            // for Inspection Department Manager
                            ((userPermissions.R2.G === "1" ||
                              userPermissions.R4.G === "1") &&
                              (selectedItem.InsrevStatus === "Approved" ||
                                selectedItem.DesStatus === "Approved"))
                              ? false
                              : true
                          }
                        >
                          OverView RD3
                        </Button>
                      </div>

                      {(userPermissions.R1.G === "1" ||
                        userPermissions.R3.G === "1" ||
                        selectedItem.AreaManagerID ===
                          localStorage.getItem("id") ||
                        userPermissions.R24.P === "2" ||
                        userPermissions.R24.P === "3" ||
                        userPermissions.R24.P === "4" ||
                        userPermissions.R2.G === "1" ||
                        userPermissions.R4.G === "1") && (
                        <Button
                          type="submit"
                          className="bg-primary"
                          disabled={
                            //for design supervisor
                            ((userPermissions.R1.G === "1" ||
                              userPermissions.R3.G === "1") &&
                              (selectedItem.InsReport === false ||
                                selectedItem.DesStatus === "Approved")) ||
                            // for inspection supervisor
                            (selectedItem.AreaManagerID ===
                              localStorage.getItem("id") &&
                              (selectedItem.InsReport === false ||
                                selectedItem.InsStatus === "Approved")) ||
                            // for Inspection Review Engineer
                            ((userPermissions.R24.P === "2" ||
                              userPermissions.R24.P === "3" ||
                              userPermissions.R24.P === "4" ||
                              userPermissions.R24.G === "1") &&
                              ((selectedItem.DesStatus !== "Approved" &&
                                selectedItem.InsStatus !== "Approved") ||
                                selectedItem.InsrevStatus === "Approved")) ||
                            // for Inspection Department Manager
                            ((userPermissions.R2.G === "1" ||
                              userPermissions.R4.G === "1") &&
                              // selectedItem.InsrevStatus !== "Approved" ||
                              (selectedItem.DesStatus !== "Approved" ||
                                selectedItem.ReportStatus === "Approved" ||
                                selectedItem.ReportStatus === "Uploaded"))
                          }
                        >
                          Submit
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Tab>

            <Tab eventKey="history" title="RD3 History">
              <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Updater</th>
                      <th>Action</th>
                      <th>Description</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RD3HistoryList.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.UpdaterName}</td>
                        <td>{item.Action || "-"}</td>
                        <td>{item.Description || "-"}</td>
                        <td>{item.CreatedAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewRD3Report
