import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import { Formik } from "formik"

const AddNewVisit = ({
  newVisitToggle,
  ProjectID,
  editing,
  setEditing,
  setAuth,
}) => {
  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    newVisitToggle()
  }
  const onClose = () => {
    newVisitToggle()
  }

  const [users, setUsers] = useState([])
  const [stages, setStages] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    // **************** Fetching Users List*****************
    axios
      .post("https://test.rdapp.net/api/RD7/MenuRD7UsersList.php", {
        ...formData,
        Type: "Inspection",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    // ************* Fetching Types List *****************
    axios
      .post("https://test.rdapp.net/api/Stages/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        array = array.filter(
          item => item.StageID !== "10" && item.StageID !== "11"
        )
        setStages(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // *********************** Validate handler ******************************** //
  const validateHandler = values => {
    let errors = {}

    if (!values.inspector) errors.inspector = "required"
    if (!values.type) errors.type = "required"
    if (!values.date) errors.date = "required"

    console.log("values", values)
    console.log("errors", errors)
    return errors
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
      InspectorID: values.inspector,
      Type: values.type,
      Date: values.date,
    }

    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/RD6/AddVisit.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Add New Site Visit
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            inspector: "",
            type: "",
            date: "",
          }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <FormGroup>
                <Label>Inspector</Label>
                <Input
                  type="select"
                  name="inspector"
                  className="react-select-container"
                  onChange={props.handleChange}
                >
                  <option value="">Select inspector ...</option>7
                  {users.map((user, i) => (
                    <option key={i} value={user.value}>
                      {user.label}
                    </option>
                  ))}
                </Input>
                {props.errors.inspector ? (
                  <p className="error px-2 d-flex justify-content-end">
                    {props.errors.inspector}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>Type</Label>
                <Input
                  type="select"
                  name="type"
                  className="react-select-container"
                  onChange={props.handleChange}
                >
                  <option value="">Select type ...</option>
                  {stages.map((stage, i) => (
                    <option key={i} value={stage.StageID}>
                      {stage.StageName}
                    </option>
                  ))}
                </Input>
                {props.errors.type ? (
                  <p className="error px-2 d-flex justify-content-end">
                    {props.errors.type}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>Date</Label>
                <Input
                  type="date"
                  name="date"
                  className="react-select-container"
                  onChange={props.handleChange}
                />
                {props.errors.date ? (
                  <p className="error px-2 d-flex justify-content-end">
                    {props.errors.date}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-danger">
                  Add Visit
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AddNewVisit
