import React from "react"
import { FormGroup, Input } from "reactstrap"

function DropdownMultiFunctions({ props, id }) {
  return (
    <FormGroup className="d-flex">
      {props.values["Single"] == 1 ? (
        <Input
          id="Answer"
          name={`Answer${id}`}
          value={props.values["Answer" + id]}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          className="form-control"
          placeholder="Enter your Answer"
          type="Text"
        />
      ) : (
        <Input
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          className="form-control"
          name={`Answer${id}`}
          id={`dropType`}
          type="select"
          // value={props.props.values[`dropType`] || false}
          defaultValue="1"
        >
          <option value={"tickManual"}>{"Tick Manual"}</option>
          <option value={"tickRD6"}>{"Tick (RD6)"}</option>
          <option value={"ticks"}>{"Multi Texts"}</option>
        </Input>
      )}
      <Input
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        className="form-control"
        name={`Single`}
        id={`dropType`}
        type="select"
        // value={props.props.values[`dropType`] || false}
        defaultValue="1"
      >
        <option value={"1"} defaultChecked>
          {"Single"}
        </option>
        <option value={"0"}>{"Multiple"}</option>
      </Input>
    </FormGroup>
  )
}

export default DropdownMultiFunctions
