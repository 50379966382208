import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup } from "reactstrap"

function QuestionType({props,setAnswerIds,  clickForm }) {
  return (
    <FormGroup
      className="d-flex mb-0"
      onChange={e => {
        clickForm(e)
      }}
    >
      <h6 className="col-1">Type :</h6>
      {/* first Column */}
      <FormGroup className="col-4 d-flex justify-content-around flex-wrap">
        {/* Radio */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="radio"
            name="Type"
            className="form-control"
            id="radio"
            value="radio"
          />
          <Label className="form-check-label " for="radio">
            Radio
          </Label>
        </FormGroup>
        {/* Boolean */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onClick={() => {
              setAnswerIds([1, 2])
              props.values[`Answer1`] = "Yes"
              props.values[`Answer2`] = "No"
            }}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="boolean"
            value="boolean"
          />
          <Label for="boolean">Boolean</Label>
        </FormGroup>
        {/* Checkbox */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="checkBox"
            value="checkbox"
          />
          <Label for="checkBox">checkBox</Label>
        </FormGroup>
        {/* Dropdown */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="dropdown"
            value="dropdown"
          />
          <Label for="dropdown">Dropdown</Label>
        </FormGroup>
        {/* Type validation */}
        {props.touched.Type && props.errors.Type ? (
          <div
            className="me-5"
          >
            {props.errors.Type}
          </div>
        ) : null}
      </FormGroup>
      {/* second Column */}
      <FormGroup className="col-3 d-flex justify-content-around flex-wrap">
        {/* Text */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="text"
            value="text"
          />
          <Label className="form-check-label " for="text">
            Text
          </Label>
        </FormGroup>
        {/* file */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="file"
            value="file"
          />
          <Label for="file">File</Label>
        </FormGroup>
        {/* Number */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="number"
            value="number"
          />
          <Label for="number">Number</Label>
        </FormGroup>
        {/* Date */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="date"
            value="date"
          />
          <Label for="date">Date</Label>
        </FormGroup>
      </FormGroup>
      {/* third columns */}
      <FormGroup className="col-4" check inline>
        {/* Ticks(RD6) */}
        <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="Tick"
            value="addTicketManual"
          />
          <Label for="Tick">Tick(RD6)</Label>
        </FormGroup>
        {/* TextList */}
        {/* <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="textlist"
            value="textlist"
          />
          <Label for="textlist">text list</Label>
        </FormGroup> */}
        {/* unitsList */}
        {/* <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="unitsList"
            value="unitsList"
          />
          <Label for="units">Units list</Label>
        </FormGroup> */}
        {/* observation */}
        {/* <FormGroup className="col-4" check inline>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="form-control"
            type="radio"
            name="Type"
            id="observation"
            value="observation"
          />
          <Label for="observation">Observation</Label>
        </FormGroup> */}
      </FormGroup>
    </FormGroup>
  )
}

export default QuestionType
