import React, { useState } from "react"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import axios from "axios"

const DisallowedDelete = ({ deleteRoleToggle }) => {
  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    deleteRoleToggle()
  }
  const onClose = () => {
    deleteRoleToggle()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Delete Role
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-delete-off-outline"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">
            Couldn't delete any role with active users!!
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DisallowedDelete
