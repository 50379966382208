import React, { useState } from "react"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewLocation = ({ selectedItem, locationToggle }) => {


  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    locationToggle()
  }
  const onClose = () => {
    locationToggle()
  }

  const handleCopy = (event) => {
    navigator.clipboard.writeText(event.target.value)
      .then(() => {
        toast.success('Text copied to clipboard', {
          autoClose: 1000, 
          hideProgressBar:true,
          theme: "light",
        });
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>
        Location on maps
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-center">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=FDA,31.558471,%2031.081580&zoom=12&size=800x400&maptype=roadmap%20&markers=color:red%7Clabel:C%7C${selectedItem.Location}%20&key=AIzaSyCbq3_1wQQjZVAI25qnPgzxhOtp6NLe55k`}
            alt="location"
          />
        </Row>
        <Row className="d-flex justify-content-start ms-1">
          <Label className="d-flex ms-1 col-5 p-2  my-3 align-items-center  justify-content-start   ">
            <Col sm={3} className="">
             <span className="font-weight-bold" style={{fontSize:"14px"}}>Location :</span>  </Col>
            <Col sm={7} >
              <Input
                value={selectedItem.Location}
                className="mb-0 align-center rounded-pill text-center "
                onClick={handleCopy}
                readOnly
              />
            </Col>
          </Label>
          <ToastContainer />
        </Row>

        <Col className="d-flex ms-auto col-2 mt-2  justify-content-end">
          <Button
            onClick={() => {
              onClose()
            }}
            className="bg-primary"
          >
            Close
          </Button>
        </Col>
      </ModalBody>
    </Modal>
  )
}

export default ViewLocation
