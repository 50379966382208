import React, { useEffect, useState } from "react"
import { Button, Form, FormGroup, Col } from "reactstrap"
import AddARemove from "./AddARemove"

const OtherChoice = props => {
  const [answerIds, setAnswerIds] = useState([1])
  // props.values[]
  let initalAnswers = []
  if (props.LengthOfotherCheckbox) {
    // console.log("props.LengthOfotherCheckbox", props.LengthOfotherCheckbox)
    for (let i = 1; i < props.LengthOfotherCheckbox; i++) {
      initalAnswers.push(i)
    }
  }
  useEffect(() => {
    setAnswerIds(initalAnswers)
  }, [props.LengthOfotherCheckbox])

  const [validOtherIds, setValidOtherIds] = useState([])
  // console.log("props.label :>> ", props.label)

  return (
    <>
      {answerIds.map(id => (
        <FormGroup key={id} className="d-flex">
          {/* <label htmlFor="others" className="me-2">
            {props.label}
          </label> */}
          <input
            onChange={props.props.handleChange}
            type="text"
            id="other"
            name={`${props.name}-${id}`}
            style={{ height: "24px" }}
            disabled={
              !initalAnswers.includes(id) || !validOtherIds.includes(id)
            }
            defaultValue={props.props.values[`${props.name}-${id}`]}
          />
          <div className="ms-5">
            <input
              type="Checkbox"
              id="others"
              onClick={() => {
                if (validOtherIds.includes(id)) {
                  setValidOtherIds(
                    validOtherIds.filter(validId => validId != id)
                  )
                } else {
                  setValidOtherIds([...validOtherIds, id])
                }
              }}
              defaultChecked={props.props.values[`${props.name}-${id}`]}
            />
            <label htmlFor="others" className="ms-1">
              {props.label}
            </label>
          </div>
          <AddARemove
            answerIds={answerIds}
            setAnswerIds={setAnswerIds}
            id={id}
            name={[`${props.name}-${id}`]}
            props={props.props}
          />
        </FormGroup>
      ))}
    </>
  )
}

export default OtherChoice
