import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import Select from "react-select"
import { useHistory } from "react-router-dom"

const AddEmployees = ({
  addEmployeesToggle,
  department,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("department", department)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    addEmployeesToggle()
  }
  const onClose = () => {
    addEmployeesToggle()
  }

  // *********************** Fetch users data ******************************** //
  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      OrgChart: true,
    }

    // axios
    //   .post("https://test.rdapp.net/api/MenuUsersView", formData)
    //   .then(res => {
    //     if (res.data.error === true && res.data.message === "Access denied!") {
    //       setAuth(true)
    //       setTimeout(() => {
    //         history.push("/logout")
    //         setTimeout(() => {
    //           history.push("/login")
    //         }, 1000)
    //       }, 4000)
    //     }

    //     let data = Object.values(res.data)
    //     data.splice(-1)

    //     //Removing employess in the same department from the menu
    //     department.Employees.map(item => {
    //       data = data.filter(i => item.UserID !== i.UserID)
    //     })

    //     let dataUsers = data.map(user => ({
    //       value: user.UserID,
    //       label: `${user.FirstName} ${user.LastName}`,
    //     }))
    //     setUsers(dataUsers)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }, [])

  const [addedUsers, setAddedUsers] = useState([])
  // console.log("addedUsers", addedUsers)

  let errors = {}
  if (addedUsers.length === 0) errors.addedUsers = "required"

  // *********************** Submit handler ******************************** //

  const submitHandler = e => {
    e.preventDefault()
    if (!errors.addedUsers) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Action: "Move",
        OCID: department.OCID,
        Employees: addedUsers.map(item => item.value).join(","),
      }
      // console.log("formData", formData)

      axios
        .post("https://test.rdapp.net/api/OrgChartAction.php", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }
          // console.log(res)
          setEditing(!editing)
        })
        .catch(err => console.log(err))
      onClose()
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Add Employees
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>

      <ModalBody>
        <Form onSubmit={submitHandler}>
          <FormGroup>
            <Label htmlFor="employees">Choose Employees</Label>
            <Select
              isMulti
              options={users}
              name="employees"
              id="employees"
              className="react-select-container"
              onChange={e => {
                // console.log(e)
                // setTransferTo(e.value)
                // props.setFieldValue("supervisor", e.value)
                setAddedUsers(e)
              }}
              classNamePrefix="select"
              placeholder="Choose Employees ..."
            />

            {errors.addedUsers ? (
              <p className="error mx-2 d-flex justify-content-end">
                {errors.addedUsers}
              </p>
            ) : null}
          </FormGroup>

          <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
            <Button className="bg-primary" onClick={onClose}>
              Cancel
            </Button>
            <Button className="bg-primary" type="submit">
              Add
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddEmployees
