import React from "react"
import { Label, Input, FormGroup } from "reactstrap"

function RadioOrCheckboxGroup(props) {
  return (
    <FormGroup className={props.style} check inline>
      <Input
        onClick={props.disableToggle ? props.disableToggle:props.onClick}
        onChange={props.props.handleChange}
        onBlur={props.props.handleBlur}
        className="form-control"
        type={props.type}
        name={props.name}
        id={props.id ? props.id : props.title}
        value={
          props.props.values[props.name]
            ? props.props.values[props.name]
            : props.value
        }
        defaultChecked={props.defaultChecked}
        checked={props.checked}
      />
      <Label for={props.id ? props.id : props.title}>{props.title}</Label>
    </FormGroup>
  )
}

export default RadioOrCheckboxGroup
