import React, { useState, useEffect } from "react"

function Input(props) {
  return (
    <input
      type={props.type}
      className={`form-control ${props.hasError ? "border-danger" : ""}`}
      id={props.id}
      value={props.value}
      onChange={props.handleChange}
    />
  )
}

const SystemSettings = () => {
  const [system, setSystem] = useState({
    siteName: "",
    siteDescription: "",
    siteKeywords: "",
    siteUrl: "",
    loginAttempts: "",
    numberStages: "",
    maxQuestion: "",
    sync: "",
  })
  const [systemErrs, setSystemErr] = useState({
    siteNameErr: "",
    siteDescriptionErr: "",
    siteKeywordsErr: "",
    siteUrlErr: "",
    loginAttemptsErr: "",
    numberStagesErr: "",
    maxQuestionErr: "",
    syncErr: "",
  })

  const [isSuccessul, setisSuccessul] = useState(false)
  const [isError, setisError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const handelSystemOnchange = event => {
    setSystem({
      ...system,
      [event.target.id]: event.target.value,
    })
    handleValidation(event.target.id, event.target.value)
  }

  const handleValidation = (field, value) => {
    switch (field) {
      case "siteName":
        setSystemErr({
          ...systemErrs,
          siteNameErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "siteDescription":
        setSystemErr({
          ...systemErrs,
          siteDescriptionErr:
            value.length === 0
              ? "This field is required"
              : value.length < 50 && "characters should be more than 50",
        })
        break
      case "siteKeywords":
        setSystemErr({
          ...systemErrs,
          siteKeywordsErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "sync":
        setSystemErr({
          ...systemErrs,
          syncErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "siteUrl":
        setSystemErr({
          ...systemErrs,
          siteUrlErr:
            value.length === 0
              ? "This field is required"
              : !value.match(
                  /[(https(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
                )
              ? "this field should be Url link"
              : "",
        })
        break
      case "loginAttempts":
        setSystemErr({
          ...systemErrs,
          loginAttemptsErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "maxQuestion":
        setSystemErr({
          ...systemErrs,
          maxQuestionErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "numberStages":
        setSystemErr({
          ...systemErrs,
          numberStagesErr: value.length === 0 ? "This field is required" : "",
        })
        break
      default:
        setSystemErr({
          ...systemErrs,
        })
        break
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    let emptyValues = Object.values(system).filter(v => {
      return v === ""
    })
    let errors = Object.values(systemErrs).filter(e => {
      return e !== ""
    })

    if (emptyValues.length > 0 || errors.length > 0) {
      let msg = errors.length > 0 ? errors[0] : "Please check required fields"
      setErrorMsg(msg)
      setisError(true)
    } else {
      setisSuccessul(true)
    }
    console.log(system)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="col-12">
          <h3 className="mb-4">Create System </h3>
          <form onSubmit={e => handleSubmit(e)}>
            <div className="row">
              <div className="col-6">
                <div>
                  <div className="form-group mb-4">
                    <label htmlFor="siteName">site Name</label>
                    <Input
                      type="text"
                      siteKeywords
                      id="siteName"
                      hasError={systemErrs.siteNameErr}
                      value={system.siteName}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="siteNameHelp" className="form-text text-danger">
                      {systemErrs.siteNameErr}
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="siteKeywords">site Keywords</label>
                    <Input
                      type="number"
                      id="siteKeywords"
                      hasError={systemErrs.siteKeywordsErr}
                      value={system.siteKeywords}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="sitekeywordHelp" className="form-text text-danger">
                      {systemErrs.siteKeywordsErr}
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="loginAttempts">Login Attempts</label>
                    <Input
                      type="number"
                      id="loginAttempts"
                      hasError={systemErrs.loginAttemptsErr}
                      value={system.loginAttempts}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div
                      id="loginAttemptHelp"
                      className="form-text text-danger"
                    >
                      {systemErrs.loginAttemptsErr}
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="sync">sync (minutes)</label>
                    <Input
                      type="number"
                      id="sync"
                      hasError={systemErrs.syncErr}
                      value={system.sync}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="syncHelp" className="form-text text-danger">
                      {systemErrs.syncErr}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="mt-4 mt-lg-0">
                  <div className="form-group mb-4">
                    <label htmlFor="siteDescription">site Description</label>
                    <Input
                      type="text"
                      id="siteDescription"
                      hasError={systemErrs.siteDescriptionErr}
                      value={system.siteDescription}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="descriptionHelp" className="form-text text-danger">
                      {systemErrs.siteDescriptionErr}
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="siteUrl">site Url</label>
                    <Input
                      type="text"
                      id="siteUrl"
                      hasError={systemErrs.siteUrlErr}
                      value={system.siteUrl}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="siteUrlHelp" className="form-text text-danger">
                      {systemErrs.siteUrlErr}
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="numberStages">stages</label>
                    <Input
                      type="number"
                      id="numberStages"
                      hasError={systemErrs.numberStagesErr}
                      value={system.numberStages}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="stagesHelp" className="form-text text-danger">
                      {systemErrs.numberStagesErr}
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="maxQuestion">
                      Max Number Question / Stage
                    </label>
                    <Input
                      type="number"
                      id="maxQuestion"
                      hasError={systemErrs.maxQuestionErr}
                      value={system.maxQuestion}
                      handleChange={e => handelSystemOnchange(e)}
                    />
                    <div id="maxHelp" className="form-text text-danger">
                      {systemErrs.maxQuestionErr}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn-primary btn" type="submit">
              Publish
            </button>
          </form>
          {isSuccessul && (
            <div
              style={{ marginTop: "5px" }}
              className="alert alert-success"
              role="alert"
            >
              System created successfully
            </div>
          )}
          {isError && (
            <div
              style={{ marginTop: "5px" }}
              className="alert alert-danger"
              role="alert"
            >
              {errorMsg}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SystemSettings
