import React from "react"
import { Col, FormGroup, Input, Row, Label } from "reactstrap"
import RadioOrCheckboxGroup from "./RadioOrCheckboxGroup"

function TextList({ props, id }) {
  return (
    <div>
      <Row>
        <Col sm={6}>
          <Label className="d-flex align-items-center">
            <Col>Num of Points :</Col>
            <Col sm={4}>
              <Input
                type="number"
                id="NumOfPoints"
                name={`NumOfPoints`}
                value={props.values["NumOfPoints"]}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onWheel={e => e.target.blur()}
              />
            </Col>
          </Label>
        </Col>
        <Col sm={4}>
          <RadioOrCheckboxGroup
            props={props}
            type={"checkbox"}
            name={"textlistOptional"}
            title={"Optional"}
            id={"textlistOptional"}
            style={"col-2"}
            value={props.values["textlistOptional"]}
            defaultChecked={props.values["textlistOptional"]}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col sm={5} className="mt-2">
          <Label>
            Can Add More :
            <Input
              type="checkbox"
              className="ms-4"
              name={`CanAddMore`}
              value={props.values["CanAddMore"]}
              onChange={props.handleChange}
            />
          </Label>
       
        </Col>
        <Col sm={6}>
          <Label className="d-flex align-items-center">
            <Col>Max Number :</Col>
            <Col sm={4}>
              <Input
              className="text-center " 
                type="number"
                id="MaxNumber"
                name={`MaxNumber`}
                value={props.values["MaxNumber"]}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onWheel={e => e.target.blur()}
                disabled={!props.values["CanAddMore"]}
              />
            </Col>
          </Label>
        </Col>
      </Row>
      {props.errors["NumOfPoints"] ? (
          <div className="error mb-2">{props.errors["NumOfPoints"]}</div>
        ) : null}

    </div>
  )
}

export default TextList
