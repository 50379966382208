import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Input,
  Form,
  Col,
  Label,
} from "reactstrap"
import { useFormik } from "formik"
import FromTo from "./FromTo"
import ApprovedMultiselect from "./ApprovedMultiselect"
import axios from "axios"
import { useHistory } from "react-router-dom"
import LoadingComponent from "common/LoadingComponent"

function AddApproved({
  addApprovedModal,
  toggleAddApproved,
  APID,
  type,
  change,
  setChange,
}) {
  let [data, setViewAPID] = useState()
  const [existedNames, setExistedNames] = useState()
  const [loading, setLoading] = useState(true)

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      fromCost: "",
      toCost: "",
      fromHeight: "",
      toHeight: "",
      fromArea: "",
      toArea: "",
      Cost: {},
      Area: {},
      Height: {},
      Name: "",
      Basement: "",
      User: "",
      SpecialProject: "",
      Category: "",
    },

    validate: values => {
      let errors = {}
      // if (!values["fromCost"] & !values["toCost"]) {
      //   errors["Cost"] = " fill one field at least"
      // }
      if (values["toCost"] && values["fromCost"] > values["toCost"]) {
        errors["Cost"] = "from should be less than to"
      }
      // if (!values["fromArea"] & !values["toArea"]) {
      //   errors["Area"] = " fill one field at least"
      // }
      if (values["toArea"] && values["fromArea"] > values["toArea"]) {
        errors["Area"] = "from should be less than to"
      }
      // if (!values["fromHeight"] & !values["toHeight"]) {
      //   errors["Height"] = " fill one field at least"
      // }
      if (values["toHeight"] && values["fromHeight"] > values["toHeight"]) {
        errors["Height"] = "from should be less than to"
      }

      // if (values["User"].length == 0) {
      //   errors["User"] = "required"
      // }
      // if (values["Category"].length == 0) {
      //   errors["Category"] = " required"
      // }
      if (!values["Name"]) {
        errors["Name"] = "required"
      } else if (existedNames.includes(values["Name"])) {
        errors["Name"] = "Name should be unique"
      }
      // if (values["SpecialProject"].length == 0) {
      //   errors["SpecialProject"] = "this field is required"
      // }
      console.log("values", values)

      console.log("errors", errors)

      return errors
    },

    onSubmit: values => {
      values["Cost"] =
        values["fromCost"] && values["toCost"]
          ? `${values["fromCost"]}, ${values["toCost"]}`
          : ""
      values["Area"] =
        values["fromArea"] && values["fromArea"]
          ? `${values["fromArea"]},${values["toArea"]}`
          : ""
      values["Height"] =
        values["fromArea"] && values["fromArea"]
          ? `${values["fromHeight"]}, ${values["toHeight"]}`
          : ""

      console.log("values", values)
      let formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Action: 1,
        Type: type,
        Cost: values["Cost"],
        Area: values["Area"],
        Height: values["Height"],
        Name: values["Name"],
        Basement: values["Basement"],
        User: values["User"].toString(),
        SpecialProject: values["SpecialProject"].toString(),
        Category: values["Category"].toString(),
      }
      setLoading(!loading)
      axios
        .post("https://test.rdapp.net/api/AutoApprove.php", formData)
        .then(
          res => (console.log(res), setChange(!change), toggleAddApproved(),setLoading(!loading))
          // setLoading(!loading)
        )
        .catch(err => console.log(err))
    },
  })
  const history = useHistory()
  const [users, setUsers] = useState([])
  const [specialProjects, setSpecialProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [auth, setAuth] = useState(false)

  // existed-Names
  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 4,
      Type: type,
    }
    axios
      .post("https://test.rdapp.net/api/AutoApproveList.php", formData)
      .then(res => {
        let data = Object.entries(res.data)
        console.log("res.data", res.data)
        data.splice(-1)

        setExistedNames(res.data.Names)
      })
      .catch(err => console.log(err))
  }, [])
  console.log("existis", existedNames)
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .post("https://test.rdapp.net/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setCategories(
          array.map(item => ({ value: item.BCID, label: item.Name }))
        )
      })
    let specialProjects = [
      { id: "437", title: "Piles" },
      { id: "438", title: "Post Tension" },
      { id: "439", title: "Hollowcore Slab" },
      { id: "440", title: "Precast Wall" },
      { id: "441", title: "Steel Structure" },
      { id: "442", title: "Innovates Material" },
      { id: "443", title: "Timber" },
      { id: "444", title: "More than on building" },
    ]

    setSpecialProjects(
      specialProjects.map(item => ({ value: item.id, label: item.title }))
    )
  }, [])

  return (
    <Modal
      isOpen={addApprovedModal}
      toggle={toggleAddApproved}
      className="modal-md"
    >
      <ModalHeader toggle={toggleAddApproved}>Add Approved Modal</ModalHeader>
      <Form onSubmit={handleSubmit} className="col-12">
        <ModalBody className="ms-5">
          <FormGroup className="form-group d-flex col-12 align-items-center">
            <label htmlFor="Name" className="col-2">
              Name :
            </label>
            <Col sm={4}>
              <Input
                className="form-control col-1"
                type="text"
                id="Name"
                name="Name"
                // value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                defaultValue={data && data.Name}
              />
            </Col>
            <p className=" text-danger ms-1">{touched.Name && errors?.Name}</p>
          </FormGroup>
          <FromTo
            title={"Cost"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            values={values}
            touched={touched}
            defaultValue={data && data.Cost}
          />
          <ApprovedMultiselect
            options={users}
            title={"User"}
            name={"User"}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            defaultValue={data && data.User}
          />
          <ApprovedMultiselect
            options={specialProjects}
            title={"Special Project"}
            name={"SpecialProject"}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            defaultValue={data && data.SpecialProject}
          />
          <ApprovedMultiselect
            options={categories}
            title={"Category"}
            name={"Category"}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            defaultValue={data && data.Category}
          />
          <FromTo
            title={"Area"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            values={values}
            touched={touched}
            defaultValue={data && data.Area}
          />
          <FromTo
            title={"Height"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            values={values}
            touched={touched}
            defaultValue={data && data.Height}
          />
          <FormGroup className="form-group d-flex col-12 ">
            <label htmlFor="Basement" className="col-3">
              Basement :
            </label>
            <Col sm={4}>
              <Input
                className="form-control col-2 h1 shadow-lg"
                type="checkbox"
                id="Basement"
                name="Basement"
                value={values.Basement}
                onChange={handleChange}
                onBlur={handleBlur}
                defaultValue={data && data.Basement}
                defaultChecked={data && data.Basement === "1"}
              />
            </Col>
            <p className="text-danger ms-1">
              {touched.Basement && errors?.Basement}
            </p>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => (toggleAddApproved(), resetForm(), (data = ""))}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {"add"}
          </Button>
        </ModalFooter>
      </Form>
       {/* loading component */}
       <LoadingComponent response={loading} setResponse={setLoading} />
    </Modal>
  )
}

export default AddApproved
