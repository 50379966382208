import React, { useState } from "react"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const ReturnRD6Report = ({
  returnReportToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    returnReportToggle()
  }
  const onClose = () => {
    returnReportToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD6ID: selectedItem.RD6ID,
      ReturnReport: true,
    }

    console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/RD6/EditRD6.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Return RD6 Report
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <Form onSubmit={submitHandler}>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to return this report ?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
              <button type="submit" className="btn btn-danger">
                Return Now
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ReturnRD6Report
