import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap"
import AddDepartment from "./AddDepartment"
import EditDepartment from "./EditDepartment"
import DeleteDepartment from "./DeleteDepartment"
import avatar from "../../assets/images/users/default.jpg"
import AddEmployees from "./AddEmployees"
import DisallowedDelete from "./DisallowedDelete"

const DepartmentCard = ({
  department,
  level,
  setAuth,
  editing,
  setEditing,
  userPermissions,
}) => {
  //   console.log("department", department)
  //   console.log("level==========", level)

  //   ********************* Add Department Modal ***************************
  const [addDepartmentModal, setAddDepartmentModal] = useState(false)
  const addDepartmentToggle = () => {
    setAddDepartmentModal(!addDepartmentModal)
  }

  //   ********************* Edit Department Modal ***************************
  const [editDepartmentModal, setEditDepartmentModal] = useState(false)
  const editDepartmentToggle = () => {
    setEditDepartmentModal(!editDepartmentModal)
  }

  //   ********************* Delete Department Modal ***************************
  const [deleteDepartmentModal, setDeleteDepartmentModal] = useState(false)
  const deleteDepartmentToggle = () => {
    setDeleteDepartmentModal(!deleteDepartmentModal)
  }

  //   ********************* Disallowed Delete Department Modal ***************************
  const [disallowedDeleteModal, setDisallowedDeleteModal] = useState(false)
  const disallowedDeleteToggle = () => {
    setDisallowedDeleteModal(!disallowedDeleteModal)
  }

  const [style, setStyle] = useState({ display: "none" })

  // *************************** Employees List **********************************

  const [employeesModal, setEmployeesModal] = useState(false)
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        !event.target.closest(`#modalContent-${department.OCID}`) &&
        !event.target.closest(`#modalClick-${department.OCID}`)
      ) {
        setEmployeesModal(false)
      }
    }

    window.addEventListener("click", handleOutsideClick)

    return () => {
      window.removeEventListener("click", handleOutsideClick)
    }
  }, [])

  //   ********************* Add Employees Modal ***************************
  const [addEmployeesModal, setAddEmployeesModal] = useState(false)
  const addEmployeesToggle = () => {
    setAddEmployeesModal(!addEmployeesModal)
  }

  // ***************************************************

  const [color, setColor] = useState("lightsteelblue")
  useEffect(() => {
    if (level === "1") {
      setColor("bisque")
    } else if (department.Type === "1") {
      setColor("honeydew")
    }
    //  else if (level === "2") {
    //   setColor("honeydew")
    // } else if (level === "3") {
    //   setColor("lightsteelblue")
    // } else if (level === "4") {
    //   setColor("aliceblue")
    // } else if (level === "5") {
    //   setColor("lightblue")
    // }
  }, [level])

  return (
    <>
      <Card
        style={{
          width: "220px",
          textAlign: "left",
          margin: "0",
          display: "inline-block",
          border: "1px solid red",
          borderRadius: "8px",
        }}
        onMouseEnter={e => {
          setStyle({ display: "block" })
        }}
        onMouseLeave={e => {
          setStyle({ display: "none" })
        }}
      >
        <CardHeader
          style={{
            backgroundColor: "lightgray",
            borderRadius: "8px 8px 0 0",
            borderBottom: "1px solid red",
            padding: "0",
          }}
          className="d-flex justify-content-between align-items-center"
        >
          <h6
            style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            {department.Name}
          </h6>
          <div style={style}>
            {(userPermissions.R9.P === "3" || userPermissions.R9.P === "4") && (
              <span style={{ cursor: "pointer" }} className="text-primary mx-1">
                <i
                  id={`edittooltip-${department.OCID}`}
                  className="mdi mdi-pencil font-size-14 "
                  onClick={editDepartmentToggle}
                ></i>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`edittooltip-${department.OCID}`}
                >
                  edit
                </UncontrolledTooltip>
              </span>
            )}

            {(userPermissions.R9.P === "2" ||
              userPermissions.R9.P === "3" ||
              userPermissions.R9.P === "4") && (
              <span style={{ cursor: "pointer" }} className="text-primary mx-1">
                <i
                  id={`addtooltip-${department.OCID}`}
                  className="mdi mdi-plus font-size-14 "
                  onClick={addDepartmentToggle}
                ></i>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`addtooltip-${department.OCID}`}
                >
                  add
                </UncontrolledTooltip>
              </span>
            )}

            {department.OCID !== "1" && userPermissions.R9.P === "4" && (
              <span
                style={{ cursor: department.OCID !== "1" ? "pointer" : "" }}
                className="text-primary mx-1"
              >
                <i
                  id={`closetooltip-${department.OCID}`}
                  className="mdi mdi-close font-size-14 "
                  onClick={() => {
                    if (department.child.length > 0) {
                      disallowedDeleteToggle()
                    } else {
                      deleteDepartmentToggle()
                    }
                  }}
                ></i>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`closetooltip-${department.OCID}`}
                >
                  delete
                </UncontrolledTooltip>
              </span>
            )}
          </div>
        </CardHeader>

        <CardBody
          style={{
            backgroundColor: color,
            borderRadius: "0 0 8px 8px",
            paddingInline: "0.5rem",
          }}
        >
          {/* <CardTitle tag="p">Supervisor</CardTitle> */}
          <h6>Supervisor</h6>
          <h6 className="mb-2 text-muted">{department.SupervisorName}</h6>

          <h6 className="mt-4">Employees</h6>
          <div className="d-flex justify-content-between">
            {department.Employees.length > 0 && (
              <div className="d-flex">
                {department.Employees.map((item, i) => {
                  if (i < 2)
                    return (
                      <img
                        key={i}
                        src={avatar}
                        alt={`${item.FirstName} ${item.LastName}`}
                        title={`${item.FirstName} ${item.LastName}`}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "3px",
                        }}
                        className="avatar-xs rounded-circle"
                      />
                    )
                })}
                {department.Employees.length > 2 && (
                  <h5 className="mx-1">. . .</h5>
                )}
              </div>
            )}
            <div
              style={{
                position: "relative",
              }}
              className="d-flex align-items-center"
            >
              <CardSubtitle
                id={`modalClick-${department.OCID}`}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                tag="h6"
                onClick={() => {
                  console.log("first")
                  setEmployeesModal(true)
                }}
              >
                {department.Employees.length} Employees
              </CardSubtitle>

              {employeesModal && (
                <Card
                  id={`modalContent-${department.OCID}`}
                  style={{
                    width: "13rem",
                    maxHeight: "20rem",
                    position: "absolute",
                    right: department.Employees.length > 0 ? 0 : null,
                    top: "90%",
                    zIndex: 10,
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                    overflow: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center"
                    style={{ backgroundColor: "lightgray" }}
                  >
                    Employees
                    {(userPermissions.R9.P === "2" ||
                      userPermissions.R9.P === "3" ||
                      userPermissions.R9.P === "4") && (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        className="text-primary mx-1 "
                      >
                        <i
                          id={`addUsertooltip-${department.OCID}`}
                          className="mdi mdi-plus-circle font-size-18 "
                          onClick={() => {
                            addEmployeesToggle()
                            setEmployeesModal()
                          }}
                        ></i>
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`addUsertooltip-${department.OCID}`}
                        >
                          add
                        </UncontrolledTooltip>
                      </span>
                    )}
                  </CardHeader>
                  <ListGroup flush>
                    {department.Employees.map((item, i) => (
                      <ListGroupItem key={i}>
                        {item.FirstName} {item.LastName}
                      </ListGroupItem>
                    ))}
                    {department.Employees.length === 0 && (
                      <ListGroupItem>Not Available</ListGroupItem>
                    )}
                  </ListGroup>
                </Card>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {/* ************************ Add Department ***************************** */}
      {addDepartmentModal && (
        <AddDepartment
          addDepartmentToggle={addDepartmentToggle}
          department={department}
          setAuth={setAuth}
          setEditing={setEditing}
          editing={editing}
        />
      )}
      {/* ************************ Add Employees ***************************** */}
      {addEmployeesModal && (
        <AddEmployees
          addEmployeesToggle={addEmployeesToggle}
          department={department}
          setAuth={setAuth}
          setEditing={setEditing}
          editing={editing}
        />
      )}

      {/* ************************ Edit Department ***************************** */}
      {editDepartmentModal && (
        <EditDepartment
          editDepartmentToggle={editDepartmentToggle}
          department={department}
          setAuth={setAuth}
          setEditing={setEditing}
          editing={editing}
        />
      )}

      {/* ************************ Delete Department ***************************** */}
      {deleteDepartmentModal && (
        <DeleteDepartment
          deleteDepartmentToggle={deleteDepartmentToggle}
          department={department}
          setAuth={setAuth}
          setEditing={setEditing}
          editing={editing}
        />
      )}

      {/* ************************ Delete Department ***************************** */}
      {disallowedDeleteModal && (
        <DisallowedDelete
          disallowedDeleteToggle={disallowedDeleteToggle}
          department={department}
          setAuth={setAuth}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </>
  )
}

export default DepartmentCard
