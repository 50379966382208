import React, { useEffect, useState } from "react"
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const ProjectOverview = ({ togglePopup, rowData, setAuth }) => {
  const history = useHistory()

  const [declaration, setDeclaration] = useState(true)
  const [modal, setmodal] = useState(true)
  const [data, setData] = useState(rowData)
  const [projectStageData, setProjectStageData] = useState({})

  // console.log("projectStageData", projectStageData)
  // console.log("data", data)
  const toggle = () => {
    setmodal(!modal)
  }

  const close = () => {
    toggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: rowData.ProjectID,
    }
    axios
      .post("https://test.rdapp.net/api/LastPStageDataView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("===========================", res.data[0])
        setProjectStageData(res.data[0])
      })
      .catch(err => console.log(err))

    axios
      .post(`https://test.rdapp.net/api/Declaration.php`, formData)
      .then(res => {
        setDeclaration(res.data.Declaration)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>Project overview</ModalHeader>
      <ModalBody style={{ backgroundColor: "#f8f7f7b3" }}>
        <div className="mb-4">
          {data.type == "Residential" ? (
            <i className="fa fa-home me-2"></i>
          ) : (
            <i className="fa fa-sharp fa-hotel  me-2"></i>
          )}
          <Link to="#" className="badge bg-light font-size-14">
            <span> Reference NO. :</span>
            <span>{data.ReferenceNo}</span>
          </Link>
        </div>

        <hr />
        <div className="text-center">
          <Row>
            <Col sm={3}>
              <div>
                <p className="text-muted mb-2">project owner</p>
                <h5 className="font-size-15">{data.OwnerName}</h5>
              </div>
            </Col>
            <Col sm={3}>
              <div className="mt-4 mt-sm-0">
                <p className="text-muted mb-2">Branch</p>
                <h5 className="font-size-15">RIYADH</h5>
              </div>
            </Col>
            <Col sm={3}>
              <div className="mt-4 mt-sm-0">
                <p className="text-muted mb-2">Location</p>
                <h5 className="font-size-15">{data.CityNameEN}</h5>
              </div>
            </Col>
            <Col sm={3}>
              <div className="mt-4 mt-sm-0">
                <p className="text-muted mb-2">Contractor</p>
                <h5 className="font-size-15">{data.ConstructorName}</h5>
              </div>
            </Col>
            <Table className="table table-hover  ">
              <thead className="m-0 p-0">
                <tr className="border-dark">
                  <th>Project Stages</th>
                  <th>By</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(data.Paid == 1 ||
                  data.ProjectType == "NT" ||
                  data.ProjectType == "FT") && (
                  <tr>
                    <td>
                      <Button
                        disabled={
                          userPermissions.R7.P !== "3" &&
                          userPermissions.R7.P !== "4" &&
                          userPermissions.R3.P !== "2" &&
                          userPermissions.R3.P !== "3" &&
                          userPermissions.R3.P !== "4"
                        }
                      >
                        <Link
                          style={{ color: "#fff" }}
                          to={
                            declaration
                              ? {
                                  pathname: `/SpecialSystem/ProjectID=${data.ProjectID}`,
                                  state: { data: data },
                                }
                              : {
                                  pathname: `/PrivacyATerms/${data.ProjectID}`,
                                  state: {
                                    data: data,
                                    from: "RD0",
                                    to: `/SpecialSystem/ProjectID=${data.ProjectID}`,
                                  },
                                }
                          }
                        >
                          RD-0
                        </Link>
                      </Button>
                    </td>
                    <td className="mt-3">{projectStageData.FullName}</td>
                    <td className="mt-3">{projectStageData.StageDate}</td>
                    <td>
                      <span
                        className={
                          projectStageData.Status == "Approved"
                            ? "badge bg-success"
                            : projectStageData.Status == "Rejected"
                            ? "badge bg-danger"
                            : projectStageData.Status == "Pending"
                            ? "badge bg-warning"
                            : projectStageData.Status == "Manual" &&
                              "badge bg-primary"
                        }
                      >
                        {projectStageData.Status}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            close()
            togglePopup()
          }}
        >
          close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProjectOverview
