import React from "react"
import { Input, Col, Row } from "reactstrap"

function Comments(props) {
  let NumberOfComments = [0]
  for (let index = 1; index < props.commentsNumber; index++) {
    NumberOfComments.push(index)
  }
  // console.log("error", [props.placeholder + "Comment(en)" + props.id])
  return (
    <div className={props.classNameDiv}>
      <Col
        sm={props.userSelect ? 6 : 12}
        className={props.userSelect ? "ms-5 me-2" : "me-2"}
      >
        {NumberOfComments.map(
          (number, key) =>
            number < 2 && (
              <div key={key}>
                <Input
                  key={key}
                  className={props.className}
                  type="textarea"
                  id={props.name}
                  name={
                    props.commentsNumber == "4"
                      ? number === 0
                        ? props.placeholder + "Comment(en)" + props.id
                        : number === 1 &&
                          props.placeholder + "Corrective(en)" + props.id
                      : props.placeholder + "Note" + props.id
                  }
                  onChange={props.props.handleChange}
                  onBlur={props.handleBlur}
                  placeholder={
                    props.commentsNumber == "4"
                      ? number === 0
                        ? props.placeholder + "Comment(en)" + props.id
                        : number === 1 &&
                          props.placeholder + "Corrective(en)" + props.id
                      : props.placeholder + "Note" + props.id
                  }
                  style={{ height: "30px" }}
                  value={
                    props.commentsNumber == "4"
                      ? number === 0
                        ? props.props.values[
                            props.placeholder + "Comment(en)" + props.id
                          ]
                        : number === 1 &&
                          props.props.values[
                            props.placeholder + "Corrective(en)" + props.id
                          ]
                      : props.props.values[
                          props.placeholder + "Note" + props.id
                        ]
                  }
                />
                {/* error */}
                {props.commentsNumber == "4" ? (
                  number === 0 ? (
                    props.props.errors[
                      props.placeholder + "Comment(en)" + props.id
                    ] ? (
                      <div className="error  ms-5 mb-2">
                        {
                          props.props.errors[
                            props.placeholder + "Comment(en)" + props.id
                          ]
                        }
                      </div>
                    ) : null
                  ) : (
                    number === 1 &&
                    (props.props.errors[
                      props.placeholder + "Corrective(en)" + props.id
                    ] ? (
                      <div className="error  ms-5 mb-2">
                        {
                          props.props.errors[
                            props.placeholder + "Corrective(en)" + props.id
                          ]
                        }
                      </div>
                    ) : null)
                  )
                ) : props.props.errors[
                    props.placeholder + "Note" + props.id
                  ] ? (
                  <div className="error  ms-5 mb-2">
                    {props.props.errors[props.placeholder + "Note" + props.id]}
                  </div>
                ) : null}
              </div>
            )
        )}
      </Col>

      <Col sm={props.userSelect ? 6 : 12}>
        {NumberOfComments.map(
          (number, key) =>
            number >= 2 && (
              <div key={key}>
                <Input
                  
                  className={props.className}
                  type="textarea"
                  id={props.name}
                  name={
                    props.commentsNumber == "4" &&
                    (number === 2
                      ? props.placeholder + "Comment(Ar)" + props.id
                      : number === 3 &&
                        props.placeholder + "Corrective(Ar)" + props.id)
                  }
                  onChange={props.props.handleChange}
                  onBlur={props.handleBlur}
                  placeholder={
                    props.commentsNumber == "4"
                      ? number === 2
                        ? props.placeholder + "Comment(Ar)" + props.id
                        : number === 3 &&
                          props.placeholder + "Corrective(Ar)" + props.id
                      : props.placeholder + "Note" + props.id
                  }
                  value={
                    props.commentsNumber == "4"
                      ? number === 2
                        ? props.props.values[
                            props.placeholder + "Comment(Ar)" + props.id
                          ]
                        : number === 3 &&
                          props.props.values[
                            props.placeholder + "Corrective(Ar)" + props.id
                          ]
                      : props.props.values[
                          props.placeholder + "Note" + props.id
                        ]
                  }
                  style={{ height: "30px" }}
                />
                {props.commentsNumber == "4" &&
                  (number === 2 ? (
                    props.props.errors[
                      props.placeholder + "Comment(Ar)" + props.id
                    ] ? (
                      <div className="error  ms-5 mb-2">
                        {
                          props.props.errors[
                            props.placeholder + "Comment(Ar)" + props.id
                          ]
                        }
                      </div>
                    ) : null
                  ) : (
                    number === 3 &&
                    (props.props.errors[
                      props.placeholder + "Corrective(Ar)" + props.id
                    ] ? (
                      <div className="error  ms-5 mb-2">
                        {
                          props.props.errors[
                            props.placeholder + "Corrective(Ar)" + props.id
                          ]
                        }
                      </div>
                    ) : null)
                  ))}
              </div>
            )
        )}
      </Col>
    </div>
  )
}

export default Comments
