import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const ConfirmingAction = ({  confirmFunc,action,Confirmetoggle,ConfirmeModel,massege ,funcproperty}) => {
  

  return (
    /**************confirm pop up***************/
    <Modal isOpen={ConfirmeModel} toggle={Confirmetoggle}>
      {/* <ModalBody> */}
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                {action === "delete" ? (
                  <i className="mdi mdi-trash-can-outline"></i>
                ) : (
                  <i className="mdi mdi-alert-outline font-size-20"></i>
                )}
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">{ massege}</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  funcproperty?confirmFunc(funcproperty):confirmFunc()
                }}
              >
                Confirm
              </button>
              <button
                onClick={() => {
                  Confirmetoggle()
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
            </div>
          </ModalBody>
        </div>
      {/* </ModalBody> */}
    </Modal>
  )
}

// **********confirm Action**********
// const [ConfirmeModel, setConfirmeModel] = useState(false)
// const Confirmetoggle = () => {
//   setConfirmeModel(!ConfirmeModel)
// }
//const [funcproperty,setFuncproperty]

//Confirmetoggle(),setFuncproperty(item)


// *********confirming action********//
// {ConfirmeModel && (
//   <ConfirmingAction
//     confirmFunc={Delete(comment.TNID)}
//     action={"delete"}
//     Confirmetoggle={Confirmetoggle}
//     ConfirmeModel={ConfirmeModel}
//     massege={"Are you sure you want to delete this comment"}
// funcproperty={funcproperty}
//   />
// )}

export default ConfirmingAction
