import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import FilterReports from "./filters/Filters"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import SendQuotation from "./filters/Quotation"
import ConfirmingAction from "common/ConfirmingAction"

const RD6Addendum = () => {
  const history = useHistory()
  //Manged Approved
  const [update, setUpdate] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)
  const [checked, setChecked] = useState(false)
  const [ConfirmeModel, setConfirmeModel] = useState(false)

  const [open, setOpen] = useState(false)
  const [clientData, setClientData] = useState({
    conEmail: "",
    conPhone: "",
    ownEmail: "",
    ownPhone: "",
  })

  const [dataTable, setDataTable] = useState([])

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    ReferenceNo: "",
    Status: "",
    Inspector: [],
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: filteredValues.ReferenceNo,
      UserID: filteredValues.Inspector.map(item => item.value).join(","),
      Status: filteredValues.Status,
    }
    axios
      .post("https://test.rdapp.net/api/RD6/AddendumList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        setTotalPages(res.data.TotalPages)

        data.splice(-4)
        setDataTable(data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [update, filteredValues, page])

  const handleDelete = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/RD6/AddendumAction.php", {
        ...formData,
        RD6AddID: ticketID,
        Deleted: "1",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setConfirmeModel(false)
        setUpdate(!update)
        let data = Object.entries(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const statusOptions = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Approved" },
    { value: "3", label: "Rejected" },
    { value: "2", label: "Completed" },
  ]

  const [ticketID, setTicketId] = useState(null)
  const Confirmetoggle = () => {
    setConfirmeModel(!ConfirmeModel)
  }

  const handleDeleteData = id => {
    Confirmetoggle()
    setTicketId(id)
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reports" breadcrumbItem="RD6 Addendum" />

            {/* ********************** Filter Section ***************************** */}
            <div className="d-flex col-12">
              <FilterReports
                statusOptions={statusOptions}
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
            </div>

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Ref</th>
                          <th scope="col">Status</th>
                          <th scope="col">Send Date</th>
                          <th scope="col">Confirmed Date</th>
                          <th scope="col">Final Visit Date</th>
                          <th scope="col">Inspector</th>
                         
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {dataTable &&
                          dataTable.map((item, i) => (
                            <tr key={i}>
                              <td>{item[1].ADDID}</td>

                              <td>{item[1].ReferenceNo}</td>

                              <td><span    className={
                                    item[1].Status == "Pending"
                                      ? "badge bg-warning"
                                      :"badge bg-success"
                                    
                                  }>{item[1].Status}</span></td>

                              <td>{item[1].CreatedAt}</td>

                              <td>{item[1].ApproveAt}</td>

                              <td>{item[1].FinalVisitAT}</td>

                              <td>{item[1].InspectionName}</td>
                              

                              <td>
                                <UncontrolledDropdown className="btn-group bg-primary">
                                  <button
                                    className="btn btn-primary btn-sm dropdown-toggle"
                                    onClick={() => {
                                      setOpen(true)
                                      setClientData({
                                        conEmail: item[1].ConstructorEmail,
                                        conPhone: item[1].ConstructorMobile,
                                        ownEmail: item[1].OwnerEmail,
                                        ownPhone: item[1].OwnerMobile,
                                        link: item[1].Link,
                                        ADDID: item[1].ADDID,
                                        ReferenceNo: item[1].ReferenceNo,
                                      })
                                    }}
                                  >
                                    View
                                  </button>
                                  <DropdownToggle
                                    tag="a"
                                    to="#"
                                    className="card-drop"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>
                                      <a href={item[1].Link}>View Link</a>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => handleDeleteData(item[0])}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {dataTable.length > 1 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
        </div>
        {clientData && (
          <SendQuotation
            open={open}
            setOpen={setOpen}
            clientData={clientData}
          />
        )}

        {ConfirmeModel && (
          <ConfirmingAction
            confirmFunc={handleDelete}
            action={"delete"}
            Confirmetoggle={Confirmetoggle}
            ConfirmeModel={ConfirmeModel}
            massege={"Are you sure you want to delete this ?"}
            // funcproperty={funcproperty}
          />
        )}
      </React.Fragment>
    )
  }
}

export default RD6Addendum
