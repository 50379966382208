export const vacateChildNodes = (event, parentElementID,disabled=true) => {
    if (!event?.target?.checked) {
      let childNodes = document
        .getElementById(`${parentElementID}`)
        .getElementsByTagName("*")
      for (var node of childNodes) {
     
        node.disabled = disabled
        node.checked = false
        node.value = null
      

      }
    }
  }
  