import axios from "axios"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input } from "reactstrap"

const FilterReports = ({ filteredValues, setFilteredValues, setAuth }) => {
  const history = useHistory()

  const [users, setUsers] = useState([])

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(
          user => `${user[1].FirstName} ${user[1].LastName} - ${user[1].UserID}`
        )
        setUsers(dataUsers.sort())
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const filteredFeatures = [
    "Reference No.",
    "Date",
    "User",
    // "Type",
    "Status",
  ]
  const statusOptions = [
    "Pending",
    "Approved",
    "Rejected",
    "Manual",
    "Out_Of_RDAPP",
    "Missing Data",
    "Manger Approval",
    "Under Review",
    "Edit Required",
    "InProgress",
  ]

  return (
    <div className="d-flex justify-content-center col-11">
      {filteredFeatures.map((feature, i) =>
        feature === "Status" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1" key={i}>
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="status"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      Status: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Status...
                  </option>
                  {statusOptions.map((status, i) => (
                    <option key={i} value={status}>
                      {status}
                    </option>
                  ))}
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : feature === "User" ? (
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex col-12">
                <input
                  list="usersList"
                  placeholder="User..."
                  name="user"
                  onChange={e => {
                    // console.log("user =====>", e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      User: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                  disabled={userPermissions.R3.G !== "1"}
                />
                <datalist id="usersList">
                  {users.map((user, i) => (
                    <option key={i} value={user} />
                  ))}
                </datalist>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type={
                    feature === "Reference No."
                      ? "number"
                      : feature === "Date"
                      ? "date"
                      : "text"
                  }
                  className="form-control border-dark"
                  placeholder={feature}
                  onChange={e => {
                    let updatedObject = {}

                    feature === "Reference No."
                      ? (updatedObject["ReferenceNo"] = e.target.value)
                      : (updatedObject[feature] = e.target.value)

                    setFilteredValues({
                      ...filteredValues,
                      ...updatedObject,
                    })
                  }}
                />
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default FilterReports
