import React from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

function ProjectInfo({ projectInfo, toggleProjectInfo, data }) {
  return (
    <Modal isOpen={projectInfo} toggle={toggleProjectInfo} >
      <ModalHeader>Project Info</ModalHeader>
      <ModalBody>
        <Row className="mb-4">
          <h5 style={{fontWeight:"bold"}}> Project General Info :</h5>
          {data.Project_General_info?.map((item, index) => (
            <Row key={index} className="ms-2" >
              <Col sm={4}>
                <h6>{item.name}</h6>
              </Col>
              <Col>
                <p>{item.value?item.value:"no data"}</p>
              </Col>
            </Row>
          ))}
        </Row>
        <Row className="mb-4">
        <h5 style={{fontWeight:"bold"}}> Structural Info :</h5>
          {data.Structural_info?.map((item, index) => (
            <Row key={index} className="ms-2">
              <Col sm={4}>
                <h6>{item.name}</h6>
              </Col>
              <Col>
                <p>{item.value}</p>
              </Col>
            </Row>
          ))}
        </Row>
        <Row >
        <h5 style={{fontWeight:"bold"}}> Soil Info :</h5>
          {data.Soil_info?.map((item, index) => (
            <Row key={index} className="ms-2">
             <Col sm={4}>
                <h6>{item.name}</h6>
              </Col>
              <Col>
                <p>{item.value}</p>
              </Col>
            </Row>
          ))}
        </Row>
       
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleProjectInfo} className="ms-auto">
          close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProjectInfo
