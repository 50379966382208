import { Hidden } from "@material-ui/core"
import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import ImageFullOptions from "common/ImageFullOptions"
import TicketComments from "common/TicketComments"
import UpdatesTicket from "./UpdatesTicket"
import ViewTicketDetails from "./ViewLastDetails"

const TicketControl = ({
  // controlToggle,
  ticketControlled,
  editing,
  setEditing,
  updatePermission,
  setAuth,
  formRef,
  submitANewTicket,
  ToggleviewTicket,
  setIsdegree,
  viewNewTicketModal,
  ToggleviewNewTicket,
  setNewTicketData,
  reset,
  transferToOptions,
  degreeList,
}) => {
  const designIDs = ["1", "2", "3", "4", "5", "13"]
  let fourcomment = ["1", "2", "5"]
  const history = useHistory()
  const validateHandler = values => {
    const errors = {}
    //
    setIsdegree(values.degree)

    if (values.degree && values.transferTo)
      errors.logic = "could not update degree and transfer it also!!"

    // Validate RD5, Inspection RD5 && TR Degree With TR Comments
    if (
      values.degree === "1" ||
      values.degree === "2" ||
      values.degree === "5"
    ) {
      if (!values.CommentEN) errors.CommentEN = "required"
      if (!values.CommentAR) errors.CommentAR = "required"
      if (!values.CorrectiveEN) errors.CorrectiveEN = "required"
      if (!values.CorrectiveAR) errors.CorrectiveAR = "required"
    }

    // Validate Note Degree With TR Comments
    if (values.degree === "3") {
      if (!values.CommentEN) errors.CommentEN = "required"
      if (!values.CommentAR) errors.CommentAR = "required"
    }

    if (values.degree === "4" || values.transferTo) {
      if (!values.CommentEN) errors.CommentEN = "required"
    }

    if (submitANewTicket === "submitANewTicket" && !values.degree) {
      errors.sendData = "Kindly choose degree before submitting"
    } else if (!values.degree && !values.transferTo) {
      errors.sendData = "Kindly update the ticket before submitting"
    }
    console.log("error", errors)

    return errors
  }

  const submitHandler = values => {
    console.log(JSON.stringify("values", values.CorrectiveAR))

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ticketControlled.ProjectID,
      DegreeID: values.degree,
      TransferTo: values.transferTo,
      CommentEN: values.CommentEN,
      CommentAR:
        values.degree == "4" || values.transferTo ? "" : values.CommentAR,
      CorrectiveEN: fourcomment.includes(values.degree)
        ? values.CorrectiveEN
        : "",
      CorrectiveAR: fourcomment.includes(values.degree)
        ? values.CorrectiveAR
        : "",
      Attached: values.Attached,
      TicketID: ticketControlled.TicketID,
      Type: values.degree == "4" ? "2" : "1",
      Copy:submitANewTicket === "submitANewTicket"&& ("1")
    }
    axios
      .post("https://test.rdapp.net/api/EditTicket", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setEditing(!editing)
        // newData()
        if (submitANewTicket === "submitANewTicket") {
          console.log("newticketsds");          
          setNewTicketData(res.data)
          viewNewTicketModal === true && ToggleviewNewTicket()
          ToggleviewTicket()
          ToggleviewNewTicket()
        } else {
          ToggleviewTicket()
          viewNewTicketModal === True && ToggleviewNewTicket()
        }
      })
      .catch(err => console.log(err))
  }

  // image
  const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }

  console.log("ticketControlled.DegreeID",ticketControlled.DegreeID);
  
  //
  return (
    <div>
      <h5 className="my-4"></h5>
      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={{
            degree: "",
            transferTo: "",
            data: "",
            receiveData: [],
            solvedData: [],
            CommentEN: ticketControlled.CommentEN,
            CommentAR: ticketControlled.CommentAR,
            CorrectiveEN: ticketControlled.CorrectiveEN,
            CorrectiveAR: ticketControlled.CorrectiveAR,
            Attached: ticketControlled.Attached,
          }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => {
            return (
              <Form onSubmit={props.handleSubmit} ref={formRef}>
                {ticketControlled.Description && (ticketControlled.DegreeID || ticketControlled.TransferTo ) &&(
                  <ViewTicketDetails
                    ticketControlled={ticketControlled}
                    editing={editing}
                    setEditing={setEditing}
                    updatePermission={updatePermission}
                    setAuth={setAuth}
                    submitANewTicket={submitANewTicket}
                    ToggleviewTicket={ToggleviewTicket}
                    setIsdegree={setIsdegree}
                    viewNewTicketModal={viewNewTicketModal}
                    ToggleviewNewTicket={ToggleviewNewTicket}
                    setNewTicketData={setNewTicketData}
                    reset={reset}
                    transferToOptions={transferToOptions}
                    degreeList={degreeList}
                  />
                )}

                {(ticketControlled.DegreeID !== "4" && ticketControlled.DegreeID !== "12" )  && (
                    <Row className="mt-5">
                      <Col ms={4}>
                        <Input
                          type="select"
                          name="degree"
                          id="degree"
                          onChange={props.handleChange}
                          value={props.values.degree}
                          disabled={props.values.transferTo}
                        >
                          <option value="">Select Degree ...</option>
                          {degreeList.map(
                            (item, i) =>
                              designIDs.includes(item.value) && (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              )
                          )}
                        </Input>
                      </Col>

                      <Col ms={4}>
                        <Input
                          type="select"
                          name="transferTo"
                          id="transferTo"
                          onChange={props.handleChange}
                          value={props.values.transferTo}
                          disabled={props.values.degree}
                        >
                          <option value="">Transfer To...</option>
                          {transferToOptions.map((item, i) => (
                            <option key={i} value={item.ResourceID}>
                              {item.Name}
                            </option>
                          ))}
                          <option value="200">RD0 Issuer</option>
                          <option value="201">Inspection</option>
                        </Input>
                      </Col>
                      <Col
                        ms={3}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Button onClick={() => toggleImageFullOptions()}>
                          Attached Files
                        </Button>
                      </Col>
                    </Row>
                )}
                {props.errors.logic ? (
                  <p className="error mx-2 d-flex ">{props.errors.logic}</p>
                ) : null}

                {(props.values.degree || props.values.transferTo) && (
                  <TicketComments
                    degree={props.values.degree}
                    CommentEN={props.values.CommentEN}
                    CommentAR={props.values.CommentAR}
                    CorrectiveEN={props.values.CorrectiveEN}
                    CorrectiveAR={props.values.CorrectiveAR}
                    handleChange={props.handleChange}
                    errors={props.errors}
                  />
                )}
                <div className="mt-5 pt-3">
                  {props.errors.sendData ? (
                    <p className="error mx-2 d-flex justify-content-end">
                      {props.errors.sendData}
                    </p>
                  ) : null}
                </div>
                {viewImageFullOptions && (
                  <ImageFullOptions
                    viewImageFullOptions={viewImageFullOptions}
                    toggleImageFullOptions={toggleImageFullOptions}
                    props={props}
                    name={`Attached`}
                    target={"Image"}
                    setAuth={setAuth}
                    value={props.values.Attached}
                    StageID={10}
                  />
                )}
              </Form>
            )
          }}
        </Formik>
      </Container>
    </div>
  )
}

export default TicketControl
