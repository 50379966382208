
const digest = '9f3ac51bbda4a16bcb2d1a247020d5933de8432de22384baad7e6733ee24263c';
const css = `._inputElement_jafxa_1,
._inputLabel_jafxa_3 {
	width: 100%;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"inputElement":"_inputElement_jafxa_1","inputLabel":"_inputLabel_jafxa_3"};
export { css, digest };
  