import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Modal, ModalBody, Label, Input } from "reactstrap"
import axios from "axios"

import Select from "react-select"
import { useHistory } from "react-router-dom"

const ParentAChild = props => {
  const [parentQuestions, setQuestions] = useState([]) //parent Question
  // console.log(props.id)

  const history = useHistory()

  useEffect(() => {
    let questionscollect = []
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      SectionID: props.sectionid,
    }
    axios
      .post("https://test.rdapp.net/api/RD0QuestionsView", formData)
      .then(parentQuestions => {
        if (
          parentQuestions.data.error === true &&
          parentQuestions.data.message === "Access denied!"
        ) {
          props.setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let index = 1
        // console.log("parentQuestions.data", parentQuestions.data)
        let array = Object.values(parentQuestions.data)
        array.splice(-1)
        array.forEach(item => {
          questionscollect.push({
            Question: index++ + "- " + item.QTitle,
            Id: item.QID,
          })
        })
        setQuestions(questionscollect)
        // console.log(parentQuestions.data)
      })
  }, [])

  const [linkedAnswer, setLinkedAnswer] = useState([]) //linked Answer
  console.log("linkedAnswer", linkedAnswer)
  const getAnswer = e => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QID: e.value,
    }
    axios
      .post("https://test.rdapp.net/api/AnswersView", formData)
      .then(answers => {
        if (
          answers.data.error === true &&
          answers.data.message === "Access denied!"
        ) {
          props.setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let questionAnswers = []
        let array = Object.values(answers.data)
        array.splice(-1)
        array.forEach(item => {
          let operator = item.Operator
          // console.log(item)
          if (item.Operator == null) {
            operator = " "
          }

          questionAnswers.push({
            id: item.QOID,
            value: operator + " " + item.OptionTitle,
          })
        })
        setLinkedAnswer(questionAnswers)
      })
  }
  /***********question data************ */
  let question = []
  parentQuestions.map((item, index) => {
    return question.push({
      options: [{ label: item.Question, value: item.Id }],
    })
  })
  // console.log("question :>> ", question)
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  // console.log(
  //   "props.props.values.ParentQuestion :>> ",
  //   props.props.values.ParentQuestion
  // )

  // const [ParentIdQuestion, setParentIdQuestion] = useState(null)
  // props.props.values.ParentQuestion = ParentIdQuestion
  return (
    props.ischild && (
      <>
        {/* parent Question  */}
        <div className="d-flex mb-1 ">
          <Label for="ParentQuestion" sm={2}>
            Parent Question :
          </Label>
          <Select
            value={selectedGroup}
            name="ParentQuestion"
            options={question}
            className="select2-selection  p-0 col-10"
            id="ParentQuestion"
            sm={10}
            onChange={e => {
              props.props.handleChange("ParentQuestion")(e.value)
              handleSelectGroup()
              getAnswer(e)
            }}
            onBlur={props.props.handleBlur}
          />
        </div>
        {/* {props.props.touched.ParentQuestion &&
        props.props.errors.ParentQuestion ? (
          <div className="error mb-1" style={{ marginInlineStart: "130px" }}>
            {props.props.errors.ParentQuestion}
          </div>
        ) : null} */}
        {/******* parent Options ***********/}
        <div className="d-flex mb-1" onChange={props.props.handleChange}>
          <Label for="LinkedAnswer" sm={2}>
            Question Options :
          </Label>
          <Input
            id="LinkedAnswer"
            name="ParentAID"
            onChange={props.props.handleChange}
            onBlur={props.props.handleBlur}
            className="form-control"
            type="select"
            sm={10}
          >
            <option selected disabled>
              -- Choose one Question Options --
            </option>

            {linkedAnswer.map((item, index) => {
              return (
                <option name={"ParentAID"} value={item.id} key={index + "b"}>
                  {item.value}
                </option>
              )
            })}
          </Input>
        </div>
        {props.props.touched.ParentAID && props.props.errors.ParentAID ? (
          <div
            className="error me-5 mb-2"
            style={{ marginInlineStart: "130px" }}
          >
            {props.props.errors.ParentAID}
          </div>
        ) : null}
      </>
    )
  )
}

export default ParentAChild