import React, { useEffect, useState } from "react"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

const ProjectStageAnswers = props => {
  const history = useHistory()

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const stageID = props.match.params.stageID
  const stage = props.location.state?.stage
  // console.log("stage", stage)

  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/PStageAnswersView", {
        ...formData,
        PStageID: stageID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("Answers res", Object.entries(res.data))
        let array = Object.entries(res.data)
        array.splice(-1)
        let dataAnswers = array.map(item => item[1])
        // console.log("dataAnswers", dataAnswers)
        setAnswers(dataAnswers)
      })
      .catch(err => console.log(err))

    axios
      .post("https://test.rdapp.net/api/ShowRD0All", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("Questions res", Object.entries(res.data))
        let dataQuestions = Object.entries(res.data).map(item => item[1])
        // console.log("dataQuestions", dataQuestions)
        setQuestions(dataQuestions)
      })
      .catch(err => console.log(err))
  }, [])

  // ************** Pagination ******************//
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [questionAndAnswersPage, setQuestionAndAnswersPage] = useState([])

  // console.log("questionAndAnswersPage", questionAndAnswersPage)

  useEffect(() => {
    // setQuestionAndAnswers(
    //   answers.map((item, i) => {
    //     let A = item
    //     let Q = questions.filter(question => question.QID === item.QID)[0]
    //     return { A, Q, order: i + 1 }
    //   })
    // )
    setTotalPages(Math.ceil(answers.length / 10))

    setQuestionAndAnswersPage(
      answers
        .map((item, i) => {
          let A = item
          let Q = questions.filter(question => question.QID === item.QID)[0]
          return { A, Q, order: i + 1 }
        })
        .filter((item, i) => i + 1 > (page - 1) * 10 && i + 1 <= page * 10)
    )
    // setQuestionAndAnswersPage(
    //   questionAndAnswers.filter(
    //     (item, i) => i + 1 > (page - 1) * 10 && i + 1 <= page * 10
    //   )
    // )
  }, [answers, questions, page])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Projects" breadcrumbItem="Project Details" />

        <Row>
          <Card className="mb-5">
            <CardBody>
              <Row>
                <Col sm={3}>
                  <Card className="my-1" color="light">
                    <CardBody>
                      <CardTitle tag="h5">Stage ID:</CardTitle>
                      <CardText>{stage?.PStageID}</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="my-1" color="light">
                    <CardBody>
                      <CardTitle tag="h5">Stage Name:</CardTitle>
                      <CardText>{stage?.StageName}</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="my-1" color="light">
                    <CardBody>
                      <CardTitle tag="h5">Created By:</CardTitle>
                      <CardText>
                        {stage?.FirstName} {stage?.LastName}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="my-1" color="light">
                    <CardBody>
                      <CardTitle tag="h5">Stage Date:</CardTitle>
                      <CardText>{stage?.StageDate}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>

        {questionAndAnswersPage.map((item, i) => {
          // {questionAndAnswers.map((item, i) => {
          return (
            <Card
              key={i}
              style={{
                // backgroundColor: "#dedee5",
                borderRadius: "5px",
                overflow: "hidden",
              }}
              onClick={() => console.log("item ======>", item.Q.QTitle)}
            >
              <CardBody>
                <CardTitle>
                  {item.order}- {item.Q?.QTitle}
                  {/* {i + 1}- {item.Q?.QTitle} */}
                </CardTitle>

                {item.A.MissingData === "1" ? (
                  <ListGroup>
                    <ListGroupItem>{item.Q?.MissingData}</ListGroupItem>
                  </ListGroup>
                ) : item.A.AnswerType === "Boolean" ||
                  item.A.AnswerType === "Radio" ||
                  (item.A.AnswerType === "Dropdown" &&
                    item.Q?.Single === "1") ? (
                  <ListGroup>
                    <ListGroupItem>{item.A.OptionTitle[0]}</ListGroupItem>
                  </ListGroup>
                ) : item.A.AnswerType === "Dropdown" &&
                  item.Q?.Single === "0" ? (
                  <ListGroup>
                    {item.A.Answers.map((option, index) => (
                      <ListGroupItem key={index}>
                        <p>Answer: {option}</p>
                        <p>RSelect: {item.A.RSelect[index]}</p>
                        {item.A.CSelect[index] && (
                          <p>CSelect: {item.A.CSelect[index]}</p>
                        )}
                        {item.A.Term[index] && (
                          <p>Term: {item.A.Term[index]}</p>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                ) : item.A.AnswerType === "CheckBox" ? (
                  <ListGroup>
                    {item.A.OptionTitle.map((option, index) => (
                      <ListGroupItem key={index}>{option}</ListGroupItem>
                    ))}
                  </ListGroup>
                ) : (
                  // for all other questions (text, integer)
                  <ListGroup>
                    <ListGroupItem>{item.A.Answers[0]}</ListGroupItem>
                  </ListGroup>
                )}
              </CardBody>
            </Card>
          )
        })}

        {totalPages > 1 && (
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </Container>
    </div>
  )
}

export default ProjectStageAnswers
