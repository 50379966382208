import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import Select from "react-select"
import { convertPermission } from "permissionUtils"

const ViewRole = ({ viewRoleToggle, editRoleToggle, selectedItem }) => {
  // console.log("selectedItem", selectedItem)
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    viewRoleToggle()
  }
  const onClose = () => {
    viewRoleToggle()
  }

  const prepareDataValues = value => {
    if (value === "0" || !value) return { value: "0", label: "NONE" }
    if (value === "1") return { value: value, label: "VIEW" }
    if (value === "2") return { value: value, label: "VIEW + ADD" }
    if (value === "3") return { value: value, label: "VIEW + ADD + EDIT" }
    if (value === "4")
      return { value: value, label: "VIEW + ADD + EDIT + DELETE" }
  }

  const testOptions = [
    { value: "0", label: "NONE" },
    { value: "1", label: "VIEW" },
    { value: "2", label: "VIEW + ADD" },
    { value: "3", label: "VIEW + ADD + EDIT" },
    { value: "4", label: "VIEW + ADD + EDIT + DELETE" },
  ]

  const resources = [
    "Design Stages",
    "Inspection Stages",
    "Design Reports",
    "Inspection Reports",
    "Missing Data",
    "System Logs",
    "Projects",
    "Additional Visits",
    "Users",
    "User roles",
    "Tickets",
    "Project Costs",
    "Project Quotations",
    "RD7 Quotations",
    "System Settings",
  ]
  const [permissionsArray, setPermissionArray] = useState([])
  // console.log("permissionsArray", permissionsArray)
  // selectedItem && setPermissionArray(selectedItem.PermissionsArray)
  useEffect(() => {
    setPermissionArray(selectedItem.PermissionsArray)
  }, [selectedItem])



  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>
        Role: {selectedItem.RoleName}
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-2 text-center">
          <Table bordered>
            <thead>
              <tr>
                <th>Resources</th>
                <th>
                  Permissions
                  <span className="text-primary mx-1">
                    <i
                      id="permissiontooltip"
                      className="mdi mdi-information-outline font-size-16 error mx-1"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="permissiontooltip"
                    >
                      select permissions
                    </UncontrolledTooltip>
                  </span>
                </th>
                <th>
                  Global
                  <span className="text-primary mx-1">
                    <i
                      id="globaltooltip"
                      className="mdi mdi-information-outline font-size-16 error mx-1"
                    />
                    <UncontrolledTooltip placement="top" target="globaltooltip">
                      admin permissions
                    </UncontrolledTooltip>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {permissionsArray.map((item, i) => (
                <tr key={i}>
                  <td>{item.ResourceName}</td>
                  <td>{prepareDataValues(item.Permissions).label}</td>
                  <td>{item.Global === "1" ? "true" : "false"}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
            <Button
              onClick={() => {
                onClose()
              }}
              className="bg-primary"
            >
              close
            </Button>
            {(userPermissions.R10.P === "3" ||
              userPermissions.R10.P === "4") && (
              <Button
                onClick={() => {
                  viewRoleToggle()
                  editRoleToggle()
                }}
                className="bg-primary"
                disabled={selectedItem.RoleID === "1"}
              >
                Edit
              </Button>
            )}
          </FormGroup>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ViewRole
