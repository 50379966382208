import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const AddNewRD7 = ({ newReportToggle, editing, setEditing, setAuth }) => {
  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    newReportToggle()
  }
  const onClose = () => {
    newReportToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Type: "Inspection",
    }
    axios
      .post("https://test.rdapp.net/api/RD7/MenuRD7UsersList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [RD5Responsible, setRD5Responsible] = useState("")
  //   console.log("RD5Responsible", RD5Responsible)

  const [referenceNo, setReferenceNo] = useState("")
  const [referenceNoError, setReferenceNoError] = useState("")
  //   console.log("referenceNo", referenceNo)


  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: referenceNo,
      RD5Responsible: RD5Responsible,
    }

    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/RD7/AddRD7Report.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        console.log("res.data.message",res.data.message);
        res.data.error == false
          ? (setEditing(!editing) ,setReferenceNoError(null),onClose())
          : ( res.data.message&&(setReferenceNoError(res.data.message)))
      })
      .catch(err => console.log(err))


  }

  console.log("errorsReferenceNO",referenceNoError);

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        New RD7 Report
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <FormGroup>
            <Label>Reference No</Label>
            <Input
              type="number"
              name="referenceNo"
              className="react-select-container"
              onChange={e => {
                // console.log(e)
                setReferenceNo(e.target.value)
              }}
              placeholder="Reference No ..."
            />
               {referenceNoError ? (
              <div className="error col-12" style={{}}>
                {referenceNoError}
              </div>
            ) : null}
          </FormGroup>
         

          <FormGroup>
            <Label>RD5 Responsible</Label>
            <Select
              options={users}
              name="RD5Responsible"
              className="react-select-container"
              onChange={e => {
                // console.log(e)
                setRD5Responsible(e.value)
              }}
              classNamePrefix="select"
              placeholder="RD5 Responsible ..."
            />
          </FormGroup>

          <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-around">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-danger">
              Add
            </button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddNewRD7
