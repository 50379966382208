import React from "react"
import DegreeDropdown from "./DegreeDropdown"

function RadioInspection({ props, userSelect, degreeList, id }) {
  let answers = ["Yes", "No", "N/A", "Other"]
  let answer = ""
  switch (id) {
    case 1:
      answer = "Yes"
      break
    case 2:
      answer = "No"
      break
    case 3:
      answer = "N/A"
      break
    case 4:
      answer = "Other"
      break
  }

  return (
    <div>
      <DegreeDropdown
        answerId={id}
        answerName={answer}
        props={props}
        style={"col-2"}
        id={id}
        isMulti={true}
        userSelect={userSelect}
        degreeList={degreeList}
      />
    </div>
  )
}

export default RadioInspection
