import { convertPermission } from "permissionUtils"
import React, { useState } from "react"
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"

const ViewRD5 = ({ viewToggle, sendToggle, selectedItem }) => {
  console.log("selectedItem", selectedItem)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewToggle()
  }

  const onClose = () => {
    viewToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View RD-5
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Table bordered>
            <thead>
              <tr>
                <th>Reference No</th>
                <td>{selectedItem.ReferenceNo}</td>
                <th>Status</th>
                <td>{selectedItem.Status}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Owner</th>
                <td>{selectedItem.OwnerName}</td>
                <th>Created By</th>
                <td>{selectedItem.CreatedBy}</td>
              </tr>
              <tr>
                <th scope="row">Contractor</th>
                <td>{selectedItem.ConstructorName}</td>
                <th>Created At</th>
                <td>{selectedItem.CreatedAt}</td>
              </tr>
              {selectedItem.SentDate && (
                <tr>
                  <th></th>
                  <td></td>
                  <th>Issued At</th>
                  <td>{selectedItem.SentDate}</td>
                </tr>
              )}
            </tbody>
          </Table>

          <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-around">
            <a
              href={
                selectedItem.Type === "Des"
                  ? `https://test.rdapp.net/uploads/reports/RD5_Reports/RD5Report.php?RD5T=${selectedItem.Token}`
                  : `https://test.rdapp.net/uploads/reports/RD5_Reports/RD5ReportIns.php?RD5T=${selectedItem.Token}`
              }
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              View RD-5
            </a>
            <Button
              className="bg-primary"
              onClick={() => {
                viewToggle()
                sendToggle()
              }}
              disabled={
                (selectedItem.Type === "Des" &&
                  ((selectedItem.Status !== "ModeratorApproval" &&
                    selectedItem.Status !== "Active") ||
                    (!selectedItem.SentDate &&
                      userPermissions.R19.P !== "1" &&
                      userPermissions.R19.P !== "2" &&
                      userPermissions.R19.P !== "3" &&
                      userPermissions.R19.P !== "4" &&
                      userPermissions.R19.G !== "1"))) ||
                (selectedItem.Type !== "Des" &&
                  selectedItem.Status !== "Active") ||
                (userPermissions.R19.P !== "1" &&
                  userPermissions.R19.P !== "2" &&
                  userPermissions.R19.P !== "3" &&
                  userPermissions.R19.P !== "4" &&
                  userPermissions.R19.G !== "1" &&
                  userPermissions.R21.P !== "1" &&
                  userPermissions.R21.P !== "2" &&
                  userPermissions.R21.P !== "3" &&
                  userPermissions.R21.P !== "4" &&
                  userPermissions.R21.G !== "1" &&
                  userPermissions.R2.G !== "1" &&
                  userPermissions.R4.G !== "1")
              }
            >
              Send RD-5
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewRD5
