import { Formik } from "formik"
import * as yup from "yup"
import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"

const PackupAndSubmit = ({ packupToggle }) => {
  const [submittable, setSubmittable] = useState(false)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    packupToggle()
  }

  const onClose = () => {
    packupToggle()
  }

  const packupHandler = e => {
    e.preventDefault()
    setSubmittable(true)
    console.log("packup done!!")
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Packup and submit</ModalHeader>
        <ModalBody>
          <Formik
          // enableReinitialize={true}
          // initialValues={{
          //   currentPassword: "",
          //   newPassword: "",
          //   confirmNewPassword: "",
          // }}
          // validationSchema={userPasswordSchema}
          // onSubmit={submitHandler}
          >
            <Form>
              <Row style={{ width: "70%", margin: "auto" }}>
                <Button className="bg-primary" onClick={packupHandler}>
                  Get Packup
                </Button>
              </Row>
              <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
                <Button
                  onClick={() => {
                    onClose()
                  }}
                  className="bg-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!submittable}
                  className="bg-primary"
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PackupAndSubmit
