import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import ListContent from "./ListContent"
import AddApproved from "./AddApproved"
import LogContent from "./LogContent"
import classnames from "classnames"
import axios from "axios"
import ViewApprovedModel from "./ViewApprovedModel"
import LogApprovedModel from "./LogApprovedModel"

function ViewModal({ viewModal,toggleViewApproved, type ,APID ,change,setChange}) {
  const [approvedModel, setApprovedModel] = useState()
  const handleSubmit = () => {
  }
  const [activeTab, setActiveTab] = useState("1")

  const [data, setData] = useState()

  return (
    <React.Fragment>
      <Modal
        isOpen={viewModal}
        toggle={toggleViewApproved}
        className="modal-lg"
      >
        {/* <ModalHeader toggle={toggleViewApproved}>Modal title</ModalHeader> */}
        <ModalBody>
          <Nav
            fill
            tabs
            className="rounded nav-tabs-custom col-4"
            role="tablist"
          >
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => setActiveTab((1).toString())}
              >
                View
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => setActiveTab((2).toString())}
              >
                Log
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={(1).toString()}>
              <ViewApprovedModel              
                APID={APID}
                type={type}
                change={change}
                setChange={setChange}
                toggleViewApproved={toggleViewApproved}
              />
            </TabPane>
            <TabPane tabId={(2).toString()}>
              <LogApprovedModel
                type={type}
                setApprovedModel={setApprovedModel}
                // toggleAddApproved={toggleAddApproved}
                APID={APID}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewApproved}>
            close
          </Button>
         
        </ModalFooter>
      </Modal>
   
    </React.Fragment>
  )
}

export default ViewModal
