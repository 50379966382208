import React, { useEffect, useRef, useState } from "react"
import { Label, Input, Col, FormGroup } from "reactstrap"

const CheckboxADropdown = props => {
  let dropdownValue = useRef()
  const [Toggle, setToggle] = useState(true)
  const addToggleToggle = () => {
    setToggle(!Toggle)
    if (Toggle === false) {
      props.props.values[props.name] = ""
      dropdownValue.current.value = 0
    } else {
      props.props.values[props.name] = dropdownValue.current.value
    }
  }
  useEffect(() => {
    props.props.values[props.name] && setToggle(false)
  }, [])
  let imageNumber = [0, 1, 2, 3, 4, 5]
  return (
    <FormGroup
      className="d-flex"
      style={{ paddiing: "3px" }}
      disabled={props.disabled}
    >
      <Col sm={3} className="me-1">
        <Input
          ref={dropdownValue}
          type="select"
          defaultSelect={props.props.values[props.name]}
          className="form-control text-center"
          id={props.name}
          name={props.name}
          placeholder={`number of images`}
          disabled={Toggle}
          onChange={props.props.handleChange}
          onBlur={props.props.handleBlur}
          style={{ minHeight: "30px" }}
        >
          {imageNumber.map((number, key) =>
            key == 0 ? (
              <option value={number} key={key}>
                number
              </option>
            ) : (
              <option
                value={number}
                selected={props.props.values[props.name] == number}
                key={key}
              >
                {number}
              </option>
            )
          )}
        </Input>
        {props.props.errors["Imagerequired" + props.id] ? (
          <div className="error mb-2">
            {props.props.errors["Imagerequired" + props.id]}
          </div>
        ) : null}
      </Col>
      <Col sm={8}>
        <Label className="ms-1">
          <Input
            disabled={props.disabled}
            onClick={() => {
              addToggleToggle()
            }}
            type="checkbox"
            className="me-1"
            name={"imagerequired" + props.id}
            value={true}
            defaultChecked={
              props.props.values[props.name] && props.props.values[props.name]
            }
            onChange={props.props.handleChange}
          />
          {props.title}
        </Label>
      </Col>
    </FormGroup>
  )
}

export default CheckboxADropdown
