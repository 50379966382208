import React, { useState } from "react"
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  UncontrolledTooltip,
} from "reactstrap"

const AVViewqoutation = ({
  AVViewqoutationToggle,

  selectedItem,
}) => {
  // console.log("selectedItem", selectedItem)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    AVViewqoutationToggle()
  }

  const onClose = () => {
    AVViewqoutationToggle()
  }

  // **************************** Payment Link Handler ********************************
  const [tooptipText, setTooptipText] = useState("Copy payment link")
  const paymentLinkHandler = async () => {
    setTooptipText("Copied")
    const paymentLink = selectedItem.PaymentLink

    try {
      await navigator.clipboard.writeText(paymentLink)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }

    setTimeout(() => {
      setTooptipText("Copy payment link")
    }, 2000)
  }

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View Quotation
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Table bordered>
            <thead>
              <tr>
                <th>Reference No</th>
                <td>{selectedItem.ReferenceNo}</td>
                <th>Payment Status</th>
                <td>
                  <span
                    className={
                      selectedItem.Paid == "1"
                        ? "badge bg-success"
                        : "badge bg-danger"
                    }
                  >
                    {selectedItem.Paid === "1" ? "Paid" : "Not Paid"}
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Owner</th>
                <td>{selectedItem.OwnerName}</td>
                <th>Created Date</th>
                <td>{selectedItem.CreatedAt}</td>
              </tr>
              <tr>
                <th scope="row">Contractor</th>
                <td>{selectedItem.ConstructorName}</td>
                <th> AV Total Cost </th>
                <td>{selectedItem.AVTotal} SAR</td>
              </tr>
            </tbody>
          </Table>

          <FormGroup className="d-flex ms-auto col-5 mt-5 justify-content-end">
            <button
              id="paymenttooltip"
              className="btn btn-primary mx-2"
              onClick={paymentLinkHandler}
            >
              Payment Link
              <UncontrolledTooltip placement="left" target="paymenttooltip">
                {tooptipText}
              </UncontrolledTooltip>
            </button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AVViewqoutation
