import React, { useState } from "react"
import { Col, PaginationItem, PaginationLink } from "reactstrap"

const PaginationComponent = ({ page, setPage, totalPages, setTotalPages }) => {
  //   const [page, setPage] = useState(1)
  //   const [totalPages, setTotalPages] = useState(1)
  // console.log("totalPages", totalPages)
  // console.log("page", page)
  const handlePageClick = pageNumber => {
    setPage(pageNumber)
  }
  let pagesArray = []
  for (let i = 0; i < totalPages; i++) {
    pagesArray.push(i + 1)
  }
  // console.log("pagesArray", pagesArray)
  pagesArray = pagesArray.filter(
    (item, index) =>
      item === page ||
      item === page - 1 ||
      item === page - 2 ||
      item === page + 1 ||
      item === page + 2 ||
      item === 1 ||
      item === totalPages
  )
  return (
    <Col xs="12">
      <div className="text-center my-3">
        <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
          <PaginationItem disabled={page === 1}>
            <PaginationLink
              previous
              href="#"
              onClick={() => handlePageClick(page - 1)}
            />
          </PaginationItem>
          {pagesArray.map((item, i) => {
            return (
              <div className="d-flex" key={i}>
                {item === totalPages &&
                  pagesArray[i - 1] !== totalPages - 1 &&
                  totalPages !== 1 && (
                    <PaginationItem disabled>
                      <PaginationLink>
                        <div
                          style={{
                            fontSize: "20px",
                            marginTop: "-25%",
                          }}
                        >
                          ...
                        </div>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                <PaginationItem active={item === page}>
                  <PaginationLink
                    onClick={() => handlePageClick(item)}
                    href="#"
                  >
                    {item}
                  </PaginationLink>
                </PaginationItem>
                {item === 1 && pagesArray[i + 1] !== 2 && totalPages !== 1 && (
                  <PaginationItem disabled>
                    <PaginationLink>
                      <div
                        style={{
                          fontSize: "20px",
                          marginTop: "-25%",
                        }}
                      >
                        ...
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                )}
              </div>
            )
          })}

          <PaginationItem disabled={page === totalPages}>
            <PaginationLink
              next
              href="#"
              onClick={() => handlePageClick(page + 1)}
            />
          </PaginationItem>
        </ul>
      </div>
    </Col>
  )
}

export default PaginationComponent
