import axios from "axios"
import React, { useEffect, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const FilterMissingData = ({ filteredValues, setFilteredValues, setAuth }) => {
  const history = useHistory()

  const [users, setUsers] = useState([])
  const [stages, setStages] = useState([])

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://test.rdapp.net/api/Stages/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        setStages(array.map(item => item[1].StageName))
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  // console.log("filteredValues", filteredValues)

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }
  return (
    <Row>
      <Col md={3}>
        <div className="search-box me-3 col-12">
          <div
            className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden"
            style={
              {
                // minHeight: "40px",
              }
            }
          >
            <i className="bx bx-search-alt search-icon" />
            <MultipleValueTextInput
              onItemAdded={(item, allItems) =>
                setFilteredValues({ ...filteredValues, ReferenceNo: allItems })
              }
              onItemDeleted={(item, allItems) =>
                setFilteredValues({ ...filteredValues, ReferenceNo: allItems })
              }
              type="number"
              name="item-input"
              placeholder="Reference No ..."
              className="border-0"
            />
          </div>
        </div>
      </Col>

      <Col md={3}>
        <div className="search-box me-3 col-12">
          <div className="position-relative mb-2 form-control border-dark ">
            <i className="bx bx-search-alt search-icon" />
            <DatePicker
              isClearable
              className="border-0"
              selectsRange={true}
              dateFormat="yyyy/MM/dd"
              startDate={startDate}
              endDate={endDate}
              onChange={update => {
                setDateRange(update)
                setFilteredValues({
                  ...filteredValues,
                  StartDate: update[0] ? convertDate(update[0]) : null,
                  EndDate: update[1] ? convertDate(update[1]) : null,
                })
              }}
              // withPortal
              placeholderText="Date ..."
            />
          </div>
        </div>
      </Col>

      <Col md={3}>
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-2 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={users}
              name="users"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.User}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, User: e })
              }}
              classNamePrefix="select"
              placeholder="User ..."
              isDisabled={userPermissions.R3.G !== "1"}
            />
          </div>
        </div>
      </Col>

      <Col md={3}>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12 ">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="select"
                name="stageName"
                onChange={e => {
                  // console.log(e.target.value)
                  setFilteredValues({
                    ...filteredValues,
                    StageName: e.target.value,
                  })
                }}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
              >
                <option selected value="">
                  Stage Name ...
                </option>
                {stages.map((stage, i) => (
                  <option key={i} value={stage}>
                    {stage}
                  </option>
                ))}
              </Input>
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FilterMissingData
