import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import AVAddNewQuotation from "./AVAddNewQuotation"
import AVViewqoutation from "./AVViewqoutation"
import AVDeleteQuotstion from "./AVDeleteQuotstion"
import AVChangePayment from "./AVChangePayment"
import AVSendQutation from "./AVSendQutation"
import AddtionalVisitFilter from "./AddtionalVisitFilter"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import PaginationComponent from "common/PaginationComponent"
import { usePrevious } from "common/CustomHooks"
import TransferAV from "./TransferAV"

const AdditionalVisit = () => {
  const [quotations, setQuotations] = useState([])
  const [editing, setEditing] = useState(false)

  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  //******************************** New Quotation Toggle********************************//
  const [newQuotationModal, setNewQuotationModal] = useState(false)
  const newQuotationToggle = () => {
    setNewQuotationModal(!newQuotationModal)
  }

  //******************************** View Quotation Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [AVViewqoutationModal, setAVViewqoutationModal] = useState(false)
  const AVViewqoutationToggle = () => {
    setAVViewqoutationModal(!AVViewqoutationModal)
  }
  //******************************** Change Payment Toggle********************************//
  const [paymentModal, setPaymentModal] = useState(false)
  const paymentToggle = () => {
    setPaymentModal(!paymentModal)
  }
  //******************************** Delete Toggle********************************//
  const [deleteModal, setDeleteModal] = useState(false)
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  }
  //******************************** Transfer Report Toggle********************************//
  const [transferReportModal, setTransferReportModal] = useState(false)
  const transferReportToggle = () => {
    setTransferReportModal(!transferReportModal)
  }
  //******************************** Delete Toggle********************************//
  const [AVSendQutationModal, setAVSendQutationModal] = useState(false)
  const AVSendQutationToggle = () => {
    setAVSendQutationModal(!AVSendQutationModal)
  }
  const initialFilteredValues = {
    quotationNo: [],
    referenceNo: [],
    users: [],
    startDate: "",
    endDate: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues", filteredValues)

  // Pagination
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const prevFilteredValues = usePrevious(filteredValues)

  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ReferenceNo: filteredValues.referenceNo.join(","),
      // Creators: filteredValues.users.join(","),
      Paid: filteredValues.Payment,
      QuotationID: filteredValues.quotationNo.join(","),
      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
      Page: page,
    }

    if (filteredValues.users) {
      formData.Creators = filteredValues.users.map(item => item.value).join(",")
    }
    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/AV/AVQuotationList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =========>", res.data)
        let array = Object.values(res.data)
        array.splice(-4)
        setTotalPages(res.data.TotalPages)
        // console.log("array", array)
        setQuotations(array)
      })
      .catch(err => console.log(err))
  }, [editing, filteredValues, page])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R8.P !== "1" &&
    userPermissions.R8.P !== "2" &&
    userPermissions.R8.P !== "3" &&
    userPermissions.R8.P !== "4" &&
    userPermissions.R8.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Quotation List"
              breadcrumbItem="Additional-Visit"
            />

            {/* ********************** Filter Section ***************************** */}

            <AddtionalVisitFilter
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
            />

            {/* ********************** Add New Quotaion Section ***************************** */}
            <div className="d-flex justify-content-end mt-3">
              {(userPermissions.R8.P === "2" ||
                userPermissions.R8.P === "3" ||
                userPermissions.R8.P === "4") && (
                <button
                  className="btn btn-primary"
                  onClick={newQuotationToggle}
                >
                  Add New Quotaion
                </button>
              )}
            </div>

            {/* ********************** Table Section ***************************** */}
            <Row className="mt-2">
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Quotation No</th>
                          <th scope="col">Project No</th>
                          <th scope="col">Quoted By</th>
                          {/* changing from RD7 => AV */}
                          <th scope="col">AV Cost</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Quotation Date</th>
                          <th scope="col">Payment Date</th>
                          {/* <th scope="col">TIS fees status</th> */}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      {/***********table body *****************/}
                      <tbody>
                        {quotations.map((item, key) => (
                          <tr key={key} style={{ position: "relative" }}>
                            <td>
                              <input
                                // value={item.Code}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                  // selectedTicketsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>
                            <td>{item.QuotationID}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{item.UserName}</td>
                            {/* changing from RD7Cost to AVCost  */}
                            <td>{item.AVCost}</td>
                            <td>
                              <span
                                className={
                                  item.Paid == "1" || item.Paid == "2"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {item.Paid === "1"
                                  ? "Paid"
                                  : item.Paid === "2"
                                  ? "Free"
                                  : "Not Paid"}
                              </span>
                            </td>
                            <td>{item.CreatedAt?.split(" ")[0]}</td>
                            <td>{item.PaymentDate?.split(" ")[0]}</td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    AVViewqoutationToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  {item.Paid === "0" && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        paymentToggle()
                                      }}
                                    >
                                      Change Payment Status
                                    </DropdownItem>
                                  )}
                                  {userPermissions.R8.P === "4" && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        deleteToggle()
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                  { (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        transferReportToggle()
                                      }}
                                    >
                                      Transfer To
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            }

            {newQuotationModal && (
              <AVAddNewQuotation
                newQuotationToggle={newQuotationToggle}
                setEditing={setEditing}
                editing={editing}
                AdditionVisit={true}
              />
            )}

            {AVViewqoutationModal && (
              <AVViewqoutation
                AVViewqoutationToggle={AVViewqoutationToggle}
                AVSendQutationToggle={AVSendQutationToggle}
                selectedItem={selectedItem}
                AdditionVisit={true}
              />
            )}

            {deleteModal && (
              <AVDeleteQuotstion
                deleteToggle={deleteToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                additionVisit={true}
              />
            )}

            {paymentModal && (
              <AVChangePayment
                paymentToggle={paymentToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                additionVisit={true}
              />
            )}

            {AVSendQutationModal && (
              <AVSendQutation
                AVSendQutationToggle={AVSendQutationToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
              />
            )}
            {/* ********************* Transfer Report *************************** */}
            {transferReportModal && (
              <TransferAV

                transferReportToggle={transferReportToggle}
                selectedItem={selectedItem}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AdditionalVisit
