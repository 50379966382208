import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const Delete = ({ toggledPopup, item, deletefunc }) => {
  const [Dmodal, setDmodal] = useState(true)

  const Dtoggle = () => {
    setDmodal(!Dmodal)
    toggledPopup()
  }

  return (
    /**************delete pop up***************/
    <Modal isOpen={Dmodal} toggle={Dtoggle}>
      <ModalBody>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to Delete " {item} "?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deletefunc()
                }}
              >
                Delete Now
              </button>
              <button
                onClick={() => {
                  Dtoggle()
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
            </div>
          </ModalBody>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Delete
