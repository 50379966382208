import React, { useEffect, useState } from "react"
import { Input, Col } from "reactstrap"
import CheckboxATextGroup from "./CheckboxATextGroup"
import Select, { components } from "react-select"

function UnitsList2({ props, answerId, groupedOptions }) {
  const [options, setOptions] = useState(groupedOptions)

  useEffect(() => {
    let el
    props.values["units" + answerId] &&
      ((el = groupedOptions.find(obj => {
        return obj["options"].find(inner => {
          return inner["value"] == props.values["units" + answerId][0]
        })
      })),
      props.values["units" + answerId][0] > 0
        ? setOptions([el])
        : setOptions(groupedOptions))
  }, [props.values["units" + answerId]])

  return (
    <>
      <Col sm={3} className="me-2">
        <Input
          type="textarea"
          id="Answer"
          name={`Answer` + answerId}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder="Enter your Answer"
          className=" d-flex form-control "
          style={{ height: "30px" }}
          value={props.values["Answer" + answerId]}
        />
      </Col>

      <Col sm={3} className="me-2">
        <Select
          // components={{ Option: CustomOption, Group: CollapsibleGroup }}
          name={"units" + answerId}
          onChange={e => {
            props.setFieldValue(
              "units" + answerId,
              e ? e.map(item => item.value) : []
            )
          }}
          options={options}
          isMulti
        />
        {props.errors["units" + answerId] ? (
          <div className="error mb-2">{props.errors["units" + answerId]}</div>
        ) : null}
      </Col>
      <Col sm={6}>
        <CheckboxATextGroup props={props} name={"unitsMissing"+answerId} label={false} />
      </Col>
    </>
  )
}

export default UnitsList2
