import React, { useEffect, useState } from "react"
import { Button, Form, FormGroup, Col, Input, Label, Row } from "reactstrap"
import { Formik } from "formik"
import Select, { components } from "react-select"
import Icons from "pages/Stages/QuestionList/Design/QuestionBuilder/Icons"
import axios from "axios"
import ImageDropzone from "common/ImageDropzone"
import { useHistory } from "react-router-dom"
import LoadingComponent from "common/LoadingComponent"

// import 'react-select/dist/react-select.css';
// window.onclick=function(event){
//   console.log("target",event.target);
// }

const CollapsibleGroup = ({ label, options, ...props }) => {
  // console.log("props",props);

  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = e => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      <div
        onClick={e => handleToggleCollapse(e)}
        style={{ cursor: "pointer" }}
        className="ps-2 p-1"
      >
        {isCollapsed ? (
          <i className="bx bxs-plus-square"></i>
        ) : (
          <i className="bx bxs-minus-square"></i>
        )}{" "}
        {label}
      </div>

      {!isCollapsed && <components.Option {...props} {...options} />}
    </>
  )
}

const CustomOption = props => (
  <div>
    {/* Custom styling or content for options */}
    <components.Option {...props} />
  </div>
)

function CCEmails({ formRef, MissingReportData, setErrors, toggle }) {
  let history = useHistory()
  const [selectedFiles, setselectedFiles] = useState([])
  const [otherEmail, setOtherEmail] = useState(false)
  const [emailIds, setEmailIds] = useState([1])
  const [groupedOptions, setGroupOptions] = useState([])
  const [response, setResponse] = useState(true)

  otherEmail === false && emailIds

  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/musers.php", formData)
      .then(res => {
        let array = Object.entries(res.data)
        // setTotalPages(res.data.TotalPages)
        array.splice(-4)
        let emails = []
        array.forEach(item => {
          setGroupOptions(res.data.filter(item => item !== false))
        })
      })
      .catch(err => console.log(err))
  }, [])

  const MAX_SIZE = 30000000
  const [totalSize, setTotalSize] = useState(0)

  let errors = {}

  return (
    <div>
      <Formik
        initialValues={{ CCEmails: [] }}
        validate={values => {
          otherEmail === false &&
            emailIds.forEach(emailId => {
              delete values["OtherEmail" + emailId]
            })

          let size = totalSize
          for (let i = 0; i < selectedFiles.length; i++) {
            size += selectedFiles[i].size
            if (size > MAX_SIZE) {
              errors["selectedFiles"] =
                "Total file size exceeds the limit of 30MB"
            }
            setTotalSize(size)
          }
          console.log("totalSize", totalSize)
          console.log("errors", errors)
          setErrors(errors)
          setTotalSize(0)
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false)

          setResponse(false)

          if (Object.keys(errors).length === 0) {
            emailIds.forEach(id => {
              values[`OtherEmail${id}`] != undefined &&
                values.CCEmails.push(values[`OtherEmail${id}`])
            })
            console.log("MissingReportData", MissingReportData)
            let formData = new FormData()
            formData.append("PerToken", localStorage.getItem("token"))
            formData.append("PerUserID", localStorage.getItem("id"))
            formData.append("PerRoleID", localStorage.getItem("userRoleID"))
            formData.append("CCEmails", values.CCEmails)
            formData.append(
              "MissingReports",
              JSON.stringify(MissingReportData.MissingReports)
            )
            formData.append("ProjectID", MissingReportData.ProjectID)
            formData.append("UserID", MissingReportData.UserID)

            for (let i = 0; i < selectedFiles.length; i++) {
              formData.append("File[]", selectedFiles[i])
            }

            axios
              .post(
                "https://test.rdapp.net/api/RD0Requirements",
                formData
              )
              .then(res => {
                if (
                  res.data.error === true &&
                  res.data.message === "Access denied!"
                ) {
                  setAuth(true)
                  setTimeout(() => {
                    history.push("/logout")
                    setTimeout(() => {
                      history.push("/login")
                    }, 1000)
                  }, 4000)
                }
                setResponse(true)
                localStorage.setItem("PStageID", res.data.PStageID)
                let path = `/`
                history.push(path)
                toggle()
              })
              .catch(err => {
                console.log(err)
              })
          }
          console.log("values", values)
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit} ref={formRef}>
            {/* Emails */}
            <Col sm={8} className="mb-3">
              <Label
                htmlFor={`other`}
                className="me-1 d-flex align-items-center"
              >
                <Col sm={2} className="position-relative">
                  CC Emails :
                </Col>
                <Col sm={8}>
                  <Select
                    components={{
                      Option: CustomOption,
                      Group: CollapsibleGroup,
                    }}
                    name={"CCEmails"}
                    onChange={e => {
                      props.setFieldValue(
                        "CCEmails",
                        e ? e.map(item => item.value) : []
                      )
                    }}
                    options={groupedOptions}
                    isMulti
                  />
                </Col>
                <Col sm={4}>
                  <div className="ms-5">
                    <label htmlFor="others">
                      <input
                        type="Checkbox"
                        id="others"
                        onClick={e => {
                          setOtherEmail(!otherEmail)
                        }}
                        // checked={!otherEmail}
                        className="me-1 "
                      />
                      other Emails
                    </label>
                  </div>
                </Col>
              </Label>
            </Col>
            {/* Other cc emails */}
            {otherEmail &&
              emailIds.map((id, index) => (
                <Col
                  sm={5}
                  key={index}
                  className="position-relative"
                  style={{ left: "60px", bottom: "20px" }}
                >
                  <Label
                   htmlFor ={`other${id}`}
                    className="me-1 d-flex align-items-center"
                  >
                    <Col sm={2}>Email {index + 1} :</Col>
                    <Input
                      onChange={props.handleChange}
                      type="email"
                      // value={props.values["other" + id]}
                      id={`other${id}`}
                      name={`OtherEmail${index + 1}`}
                      className="ms-2 "
                      style={{ height: "25px" }}
                    />

                    <Col
                      sm={4}
                      className="position-relative"
                      style={{ top: "5px" }}
                    >
                      <Icons
                        answerIds={emailIds}
                        props={props}
                        setAnswerIds={setEmailIds}
                        inputType={""}
                        defaultInput={false}
                        id={id}
                      />
                    </Col>
                  </Label>
                </Col>
              ))}

            <Col className="form-group d-flex">
              <Col sm={2}>Attachs : </Col>

              <ImageDropzone
                selectedFiles={selectedFiles}
                setselectedFiles={setselectedFiles}
              />
            </Col>
          </Form>
        )}
      </Formik>
    
        <LoadingComponent response={response} setResponse={setResponse} />
    
    </div>
  )
}

export default CCEmails
