import { Formik } from "formik"
import * as yup from "yup"
import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"

const UpdateInfoModal = ({
  infoToggle,
  userData,
  setSuccess,
  setError,
  setAuth,
}) => {
  const history = useHistory()

  const userID = localStorage.getItem("id")

  const [modal, setModal] = useState(true)
  const toggle = () => {
    infoToggle()
  }

  const onClose = () => {
    infoToggle()
  }

  const [signatureImage, setSignatureImage] = useState("")
  const [currentImg, setCurrentImg] = useState(null)

  useEffect(() => {
    if (signatureImage) {
      setCurrentImg(URL.createObjectURL(signatureImage))
    } else if (userData.Signature) {
      setCurrentImg(
        `https://test.rdapp.net/uploads/signatures/${userData.Signature}`
      )
    } else {
      setCurrentImg(null)
    }
  }, [signatureImage, userData.Signature])

  const userDataSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().required("required").email("invalid email"),
    mobile: yup.string().required("required"),
  })
  const submitHandler = (values, onSubmitProps) => {
    onSubmitProps.resetForm()
    // console.log("values==========>", values)

    const formData = new FormData()
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("FirstName", values.firstName)
    formData.append("LastName", values.lastName)
    formData.append("Email", values.email)
    formData.append("Mobile", values.mobile)
    formData.append("Signature", values.signature || null)
    formData.append("UserID", userID)

    axios
      .post("https://test.rdapp.net/api/EditUser", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        if (res.data.error === false) {
          setSuccess("Your Information updated successfully!!")
          setTimeout(() => {
            setSuccess("")
          }, 3000)
        } else {
          setError(`${res.data.message}, Kindly try again later!`)
          setTimeout(() => {
            setError("")
          }, 3000)
        }
        onClose()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Update Info</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              firstName: userData.FirstName || "",
              lastName: userData.LastName || "",
              email: userData.Email || "",
              mobile: userData.Mobile || "",
              signature: "",
            }}
            validationSchema={userDataSchema}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="sectionName">First Name</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="firstName"
                        type="text"
                        value={props.values["firstName"]}
                        className="form-control"
                        id="lastName"
                        placeholder="First Name..."
                      />

                      {props.touched.firstName && props.errors.firstName ? (
                        <p className="error">{props.errors.firstName}</p>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="sectionName">Last Name</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="lastName"
                        type="text"
                        value={props.values["lastName"]}
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name..."
                      />
                      {props.touched.lastName && props.errors.lastName ? (
                        <p className="error">{props.errors.lastName}</p>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="email"
                        type="email"
                        value={props.values["email"]}
                        className="form-control"
                        id="email"
                        placeholder="Your Email..."
                      />
                      {props.touched.email && props.errors.email ? (
                        <p className="error">{props.errors.email}</p>
                      ) : null}
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="mobile">Mobile</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="mobile"
                        type="tel"
                        value={props.values["mobile"]}
                        className="form-control"
                        id="mobile"
                        placeholder="Your Mobile Number..."
                      />
                      {props.touched.mobile && props.errors.mobile ? (
                        <p className="error">{props.errors.mobile}</p>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={8}>
                    <div className="mb-3">
                      <Label htmlFor="signature">signature</Label>
                      <Input
                        // style={{ width: "500px" }}
                        // value={values.Signature}
                        onChange={e => {
                          props.setFieldValue("signature", e.target.files[0])
                          setSignatureImage(e.target.files[0])
                        }}
                        type="file"
                        id="signature"
                        name="signature"
                        accept="image/png, image/jpeg"
                      />

                      {props.touched.signature && props.errors.signature ? (
                        <p className="error">{props.errors.signature}</p>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="d-flex justify-content-center">
                      {currentImg && (
                        <img src={currentImg} width={100} height={100} />
                      )}
                    </div>
                  </Col>
                </Row> */}

                <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="bg-primary">
                    Update
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default UpdateInfoModal
