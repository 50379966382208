import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'

function InspectionStages({inspectionStage,toggleInspectionStage,data}) {
  return (
    <Modal isOpen={inspectionStage} toggle={toggleInspectionStage} className="modal-lg">
    <ModalHeader>ProjectInfo</ModalHeader> 
    <ModalBody>
    <Table className="mb-0 text-center p-5">
            <thead>
              <tr>
                <th>Stage</th>
                <th>Inspector</th>
                <th>Date</th>
                <th>Result</th>                
              </tr>
            </thead>
            <tbody>
              {data.map((item,index)=>(
                <tr key={index}>
                  <td>{item.StageName}</td>
                  <td>{item.UserName}</td>
                  <td>{item.CreatedAt}</td>
                  <td>{item.Result}</td>
                </tr>
              ))}             
            </tbody>
          </Table>

    </ModalBody>
    <ModalFooter>

        <Button onClick={toggleInspectionStage} className='ms-auto'>
            close
        </Button>
        </ModalFooter> 
  </Modal>
  )
}

export default InspectionStages