import * as Yup from "yup"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const Input = props => {
  return (
    <input
      type={props.type}
      className={`form-control ${props.hasError ? "border-danger" : ""}`}
      id={props.id}
      value={props.value}
      onChange={props.handleChange}
    />
  )
}

const EditUser = ({
  editUserToggle,
  selectedUser,
  editing,
  setEditing,
  setAuth,
}) => {
  console.log("selectedUser", selectedUser)

  const history = useHistory()

  const [modal, setModal] = useState(true)
  const toggle = () => {
    editUserToggle()
  }

  const onClose = () => {
    editUserToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  ///////////////////////////////////////////////
  const [user, setUser] = useState({
    FirstName: selectedUser.FirstName,
    LastName: selectedUser.LastName,
    Mobile: selectedUser.Mobile,
    Email: selectedUser.Email,
    Password: "",
    Signature: selectedUser.Signature || null,
    // UserGroup: selectedUser.UserGroup,
    Role: { value: selectedUser.RoleID, label: selectedUser.RoleName },
    Department: {
      value: selectedUser.DepartmentID,
      label: selectedUser.DepartmentName,
    },
    Locked: selectedUser.Locked,
    Status: selectedUser.Status,
  })
  console.log("user=================", user)

  const [userErrs, setUserErr] = useState({
    FirstNameErr: "",
    LastNameErr: "",
    MobileErr: "",
    EmailErr: "",
    // UserGroupErr: "",
  })

  const [isSuccess, setIsSuccess] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const handleValidation = (field, value) => {
    switch (field) {
      case "FirstName":
        setUserErr({
          ...userErrs,
          FirstNameErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "LastName":
        setUserErr({
          ...userErrs,
          LastNameErr: value.length === 0 ? "This field is required" : "",
        })
        break
      case "Mobile":
        setUserErr({
          ...userErrs,
          MobileErr: value.length === 0 ? "This field is required" : "",
        })
        break

      case "Email":
        setUserErr({
          ...userErrs,
          EmailErr:
            value.length === 0
              ? "This field is required"
              : !value.match(
                  /[(https(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
                )
              ? "this field should be Url link"
              : "",
        })
        break
      // case "Password":
      //   setUserErr({
      //     ...userErrs,
      //     PasswordErr: value.length === 0 ? "This field is required" : "",
      //   })
      //   break

      // case "UserGroup":
      //   setUserErr({
      //     ...userErrs,
      //     UserGroupErr: value.length === 0 ? "This field is required" : "",
      //   })
      //   break

      default:
        setUserErr({
          ...userErrs,
        })
        break
    }
  }

  const handelUserOnchange = event => {
    console.log("event-----------", event)
    let value
    if (event.target.id === "Status") {
      value = event.target.value
    } else if (event.target.id === "Signature") {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setUser({
      ...user,
      [event.target.id]: value,
    })
    handleValidation(event.target.id, event.target.value)
  }

  const handelUserStatus = event => {
    if (event.target.name === "AccountstatusOption") {
      setUser({
        ...user,
        Status: event.target.value,
      })
    }
  }
  const handelUserLogging = event => {
    if (event.target.name === "AccountLoggingOption") {
      setUser({
        ...user,
        Locked: event.target.value,
      })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    let emptyValues = []
    for (const prop in user) {
      if (prop != "Password" && prop != "Signature" && !user[prop]) {
        emptyValues.push(prop)
      }
    }

    let errors = []
    for (const prop in userErrs) {
      if (userErrs[prop] != "") {
        errors.push(prop)
      }
    }

    if (emptyValues.length > 0 || errors.length > 0) {
      let msg = errors.length > 0 ? errors[0] : "Please check required fields"
      setErrorMsg(msg)
      setIsError(true)
      setIsSuccess(false)
    } else {
      setIsSuccess(true)
      setIsError(false)
    }

    Object.keys(user).map((item, index) => {
      handleValidation(item, Object.values(user)[index])
    })

    if (emptyValues.length === 0 && errors.length === 0) {
      const formData = new FormData()
      formData.append("PerToken", localStorage.getItem("token"))
      formData.append("PerUserID", localStorage.getItem("id"))
      formData.append("PerRoleID", localStorage.getItem("userRoleID"))
      formData.append("FirstName", user.FirstName)
      formData.append("LastName", user.LastName)
      formData.append("Email", user.Email)
      formData.append("Password", user.Password || null)
      formData.append("Mobile", user.Mobile)
      formData.append("RoleID", user.Role.value)
      formData.append("ChartID", user.Department.value)
      formData.append("Signature", user.Signature || null)
      formData.append("Locked", user.Locked)
      formData.append("Status", user.Status)
      formData.append("UserID", selectedUser.UserID)

      // for (var pair of formData.entries()) {
      //   console.log(pair[0], pair[1])
      // }

      axios
        .post("https://test.rdapp.net/api/EditUser", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          setEditing(!editing)
        })
        .catch(error => {
          console.log(error)
        })

      onClose()
    }
  }

  const [file, setFile] = useState(
    `https://test.rdapp.net/uploads/signatures/${selectedUser.Signature}`
  )
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]))
  }

  const [rolesOptions, setRolesOptions] = useState([])

  useEffect(() => {
    axios
      .post("https://test.rdapp.net/api/Roles/View", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        ShowAll: true,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res========>", res.data)
        let array = Object.entries(res.data)
        array.splice(-4)
        // console.log("array", array)

        setRolesOptions(
          array.map((item, i) => ({
            value: item[1].RoleID,
            label: item[1].RoleName,
          }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  const [departmentOptions, setdepartmentOptions] = useState([])
  useEffect(() => {
    axios
      .post("https://test.rdapp.net/api/OrgChartList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res========>", res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)

        setdepartmentOptions(
          array.map((item, i) => ({
            value: item.OCID,
            label: item.Name,
          }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          Edit User
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <form onSubmit={e => handleSubmit(e)}>
            <div className="row">
              {/****************Left colum********************/}
              <div className="col-6">
                <div className="mt-4 mt-lg-0">
                  <div className="form-group mb-4">
                    <label htmlFor="FirstName">first Name</label>
                    <Input
                      type="text"
                      id="FirstName"
                      hasError={userErrs.FirstNameErr}
                      value={user.FirstName}
                      handleChange={e => handelUserOnchange(e)}
                    />
                    <div id="firstNameHelp" className="form-text text-danger">
                      {userErrs.FirstNameErr}
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="Email">Email</label>
                    <Input
                      type="text"
                      id="Email"
                      hasError={userErrs.EmailErr}
                      value={user.Email}
                      handleChange={e => handelUserOnchange(e)}
                    />
                    <div id="emailHelp" className="form-text text-danger">
                      {userErrs.EmailErr}
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="Password">Password</label>
                    <Input
                      type="text"
                      id="Password"
                      hasError={userErrs.PasswordErr}
                      value={user.Password}
                      handleChange={e => handelUserOnchange(e)}
                    />
                    <div id="passwordHelp" className="form-text text-danger">
                      {userErrs.PasswordErr}
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-around align-items-center"
                    // style={{ backgroundColor: "red" }}
                  >
                    <div className="form-group ">
                      <label htmlFor="Accountstatus">Account Status</label>
                      <div
                        id="Accountstatus"
                        //   onChange={e => handelUserOnchange(e)}
                      >
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="AccountstatusOption"
                              //   name="flexRadioDefault"
                              id="flexRadioDefault1"
                              value="On"
                              checked={user.Status === "On" && true}
                              onClick={handelUserStatus}
                              onChange={handelUserStatus}
                            />
                            Active
                          </label>
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              //   name="flexRadioDefault"
                              name="AccountstatusOption"
                              id="flexRadioDefault2"
                              value="Off"
                              checked={user.Status === "Off" && true}
                              onClick={handelUserStatus}
                              onChange={handelUserStatus}
                            />
                            Block
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group ">
                      <label htmlFor="LoginLocked">Login Locked</label>
                      <div id="LoginLocked">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="Default1"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Default1"
                              name="AccountLoggingOption"
                              value="1"
                              checked={user.Locked === "0" && true}
                              onClick={handelUserLogging}
                              onChange={handelUserLogging}
                            />
                            Active
                          </label>
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="Default2"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Default2"
                              name="AccountLoggingOption"
                              value="1"
                              checked={user.Locked === "1" && true}
                              onClick={handelUserLogging}
                              onChange={handelUserLogging}
                            />
                            Block
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-5  ">
                    <h6 className="d-block" htmlFor="Signature">
                      User Signature
                    </h6>
                    <div className="d-flex ">
                      <div>
                        <input
                          type="file"
                          id="Signature"
                          style={{
                            // height: "230px",
                            // width: "30px",
                            // color: "transparent",
                            display: "none",
                          }}
                          onChange={e => {
                            handelUserOnchange(e)
                            handleChange(e)
                          }}
                        />
                        <button
                          className="btn btn-info mb-5"
                          onClick={e => {
                            e.preventDefault()
                            $('input[id="Signature"]').trigger("click")
                          }}
                        >
                          Edit your signature
                        </button>
                      </div>

                      <div
                        className=" align-items-center p-1 ms-5"
                        style={{
                          height: "80px",
                          width: "200px",
                        }}
                      >
                        <img
                          src={file}
                          style={{ width: "120px" }}
                          className="rounded bg-light"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/****************Right colum********************/}
              <div className="col-6">
                <div className="mt-4 mt-lg-0">
                  <div className="form-group mb-4">
                    <label htmlFor="LastName">Last Name</label>
                    <Input
                      type="text"
                      id="LastName"
                      hasError={userErrs.LastNameErr}
                      value={user.LastName}
                      handleChange={e => handelUserOnchange(e)}
                    />
                    <div id="descriptionHelp" className="form-text text-danger">
                      {userErrs.LastNameErr}
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="Mobile">Mobile</label>
                    <Input
                      type="number"
                      id="Mobile"
                      hasError={userErrs.MobileErr}
                      value={user.Mobile}
                      handleChange={e => handelUserOnchange(e)}
                    />
                    <div id="sitekeywordHelp" className="form-text text-danger">
                      {userErrs.MobileErr}
                    </div>
                  </div>

                  <div className=" mb-4">
                    <label htmlFor="Role">User Role</label>

                    <Select
                      options={rolesOptions}
                      id="Role"
                      name="Role"
                      className="react-select-container"
                      value={user.Role}
                      onChange={e => {
                        // console.log(e)
                        setUser({
                          ...user,
                          Role: e,
                        })
                      }}
                      classNamePrefix="select"
                      placeholder="select user role ..."
                    />
                  </div>

                  <div className=" mb-4">
                    <label htmlFor="Role">User Department</label>

                    <Select
                      options={departmentOptions}
                      id="Department"
                      name="Department"
                      className="react-select-container"
                      value={user.Department}
                      onChange={e => {
                        // console.log(e)
                        setUser({
                          ...user,
                          Department: e,
                        })
                      }}
                      classNamePrefix="select"
                      placeholder="select user Department ..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
              <Button
                onClick={() => {
                  onClose()
                }}
                className="bg-primary"
              >
                close
              </Button>
              <Button
                className="bg-primary"
                type="submit"
                disabled={
                  userPermissions.R9.P !== "3" && userPermissions.R9.P !== "4"
                }
              >
                Edit
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditUser
