import React from "react"
import ReactLoading from "react-loading"

const LoadingInsider = ({ type, color }) => {
  return (
    <div className="d-flex justify-content-center">
      <ReactLoading type={type} color={color} height={30} width={30} />
    </div>
  )
}

export default LoadingInsider
