import React, { useState } from "react"
import { Row, Col, Table, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import TicketComments from "common/TicketComments"
import ImageFullOptions from "common/ImageFullOptions"

const TicketHistory = ({ data, setAuth }) => {
  // Initialize collapsedRows state with all rows set to true
  const [collapsedRows, setCollapsedRows] = useState(
    data.reduce((acc, _, index) => {
      acc[index] = false
      return acc
    }, {})
  )

  const toggleCollapse = key => {
    setCollapsedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  // image
  const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }

  const [imageData,setImageData] = useState([])

  return (
    <Row className="mt-2">
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            {/******************* table section**************************/}
            <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col">Updater</th>
                  <th scope="col">Update ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Degree</th>
                  <th scope="col">Attached</th>
                </tr>
              </thead>

              {/***********table body *****************/}
              <tbody>
                {data.map((item, key) => (
                  <React.Fragment key={key}>
                    <tr style={{ position: "relative" }}>
                      <td>{item.UserName}</td>
                      <td>{item.UpdateID}</td>
                      <td>{item.CreatedAt}</td>
                      <td>{item.Action}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm "
                          onClick={()=>{toggleImageFullOptions(),setImageData(item.File)}}
                          
                        >
                          preview
                        </button>
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={
                            collapsedRows[key] ? faChevronDown : faChevronUp
                          }
                          onClick={() => toggleCollapse(key)}
                          className="dropdown-toggle"
                        />
                      </td>
                    </tr>
                    {collapsedRows[key] && (
                      <tr>
                        <td colSpan="5">
                          <TicketComments
                            disabled={"disabled"}
                            degree={item.Action}
                            CommentEN={item.CommentEN}
                            CommentAR={item.CommentAR}
                            CorrectiveEN={item.CorrectiveEN}
                            CorrectiveAR={item.CorrectiveAR}
                          />
                        </td>
                      </tr>
                    )}

                    {viewImageFullOptions && (
                      <ImageFullOptions
                        viewImageFullOptions={viewImageFullOptions}
                        toggleImageFullOptions={toggleImageFullOptions}
                        name={`Attached`}
                        target={"Image"}
                        setAuth={setAuth}
                        value={imageData}
                        StageID={10}
                        type={"view"}
                      />
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default TicketHistory
