import React, { useEffect, useState } from "react"
import { Form, Row, Table, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"

function LogApprovedModel({ type, toggleAddApproved, setAPID,APID }) {
  let [approved, setApproved] = useState([])
  // const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 2,
      Type: type,
      APID:APID
    }
    axios
      .post("https://test.rdapp.net/api/AutoApproveList.php", formData)
      .then(res => {
        let array = []
        let data = Object.entries(res.data)
        console.log("res.data", res.data)
        data.splice(-1)
        data.forEach(item =>item[1]["Name"]&& array.push(item[1]))
        setApproved(array)
        // setTotalPages(res.data.TotalPages)

        // setProjectListData(array)
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <div  className="table-responsive">
      <Table hover striped className="  text-center   border-bottom">
        <thead>
          <tr>
            <th scope="col">ReferenceNo</th>

            <th scope="col">CreatedAt</th>
           
          </tr>
        </thead>
        {/***********table body *****************/}
        <tbody>
      
          {approved.map((item, key) => (
            <tr key={key}>
              <td>
                <Link
                  href="#"
                  onClick={() => {
                    return toggleAddApproved(), setAPID(item.APID)
                  }}
                >
                  {item.ReferenceNo}
                </Link>
              </td>

              <td>{item.CreatedAt}</td>

             
              
            </tr>
          ))}
        </tbody>
      </Table>
      {/* ********************** Pagination ********************************* */}
      {/* {approved.length > 1 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )} */}
    </div>
  )
}

export default LogApprovedModel
